import React from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, Theme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Markdown from '../layout/Markdown'
import { makeStyles } from '@mui/styles'
import { rem } from '@app/theme/materialUITheme'
import { formatLegacyScheduleTime, formatScheduleTime } from '@app/utils/date'
import { CustomScroll } from '../ui/CustomScroll'

type Props = {
  round: number
  tournamentSite?: TournamentSite
}

const ScheduleAndAbout = ({ round, tournamentSite }: Props) => {
  const classes = useStyles()

  if (!tournamentSite) {
    return null
  }

  const { tournamentInformationText, tournamentSchedule, units } = tournamentSite
  const hasInfoText = tournamentInformationText && tournamentInformationText[round]
  const hasScheduleEntries = tournamentSchedule && tournamentSchedule[round].length > 0

  if (!hasInfoText && !hasScheduleEntries) {
    return
  }

  return (
    <Grid container rowSpacing={{ xs: 2 }} style={{ margin: 0 }}>
      {hasInfoText && (
        <Grid item xs={12} sm={7.8} className={classes.tournamentInformationContainer}>
          <Typography variant={'h2'}>
            <FormattedMessage id={'strings.tournamentInformation'} />
          </Typography>
          <CustomScroll forceVisible style={{ maxHeight: 450 }}>
            <div className={classes.markdownContainer}>
              <Markdown>{`${tournamentInformationText[round]}\n`}</Markdown>
            </div>
          </CustomScroll>
        </Grid>
      )}
      {hasScheduleEntries && (
        <Grid item xs={12} sm={4.2} className={hasInfoText ? classes.scheduleContainer : ''}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant={'h2'} style={{ marginBottom: 16 }}>
                <FormattedMessage id={'strings.schedule'} />
              </Typography>
            </Grid>
            <Grid item>{renderScheduleTable(tournamentSchedule[round], classes, units)}</Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const renderScheduleTable = (schedule: TournamentScheduleItem[], classes, units) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {schedule.map((item, idx: number) => (
      <div key={idx} className={classes.scheduleItem}>
        <div style={{ flex: '0 0 70px' }}>
          <Typography variant={'body1'} className={classes.time}>
            <strong>
              {formatScheduleTime(item.hours, item.minutes, units) ?? formatLegacyScheduleTime(item.time, units)}
            </strong>
          </Typography>
        </div>
        <div>
          <Typography variant={'body1'} className={classes.event}>
            {item.description}
          </Typography>
        </div>
      </div>
    ))}
  </div>
)

const useStyles = makeStyles((theme: Theme) => ({
  time: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    fontSize: 15,
  },
  tournamentInformationContainer: {
    '& > h2': {
      paddingBottom: 16,
    },
  },
  markdownContainer: {
    padding: `${rem(10)} ${rem(17)} ${rem(10)} ${rem(17)}`,
    maxWidth: '95%',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #D9D9D9',
      borderRadius: 6,
      paddingRight: 10,
    },
  },
  scheduleContainer: {
    borderLeft: `1px solid #E5E4E4`,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
  event: {
    fontSize: 15,
    wordBreak: 'break-word',
  },
  scheduleItem: {
    display: 'flex',
    marginLeft: 20,
  },
}))

export default connect((state: StoreState) => ({
  tournamentSite: state.tournamentReducer.tournamentSite,
}))(ScheduleAndAbout)
