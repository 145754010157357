import fi from './localizations/fi.json'
import en from './localizations/en.json'
import sv from './localizations/sv.json'
import es from './localizations/es_ES.json'
import { LOCAL_STORAGE_LANG_KEY } from './config'

export const DEFAULT_LANGUAGE_CODE: SupportedLanguageCode = 'en-US'

export const supportedLanguageCodes = ['fi-FI', 'fi-SV', 'en-US', 'es-ES'] as const

export type SupportedLanguageCode = (typeof supportedLanguageCodes)[number]

export type Localizations = Record<SupportedLanguageCode, any>

export const localizations: Localizations = {
  'fi-FI': fi,
  'fi-SV': sv,
  'en-US': en,
  'es-ES': es,
}

export const getNavigatorLanguageCode = () => {
  try {
    return window.navigator.language || window.navigator.languages[0]
  } catch {
    return DEFAULT_LANGUAGE_CODE
  }
}

export const getStoredLanguageCode = (): SupportedLanguageCode | null => {
  try {
    return window.localStorage.getItem(LOCAL_STORAGE_LANG_KEY) as SupportedLanguageCode
  } catch {
    return null
  }
}

export const storeLanguageCode = (languageCode: SupportedLanguageCode) => {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY, languageCode)
  } catch {
    console.error('Error while storing language code')
  }
}

export const resolveLanguageCode = (supportedLanguages: LanguageOption[], code: string): SupportedLanguageCode => {
  // if we have exact match..
  if (supportedLanguages.find((lang) => lang.code === code)) {
    return code as SupportedLanguageCode
  }

  // Not exact match, find best match
  const countryCode = code.split('-')[0]

  if (countryCode) {
    // Special case for swedish..
    if (countryCode.toLowerCase() === 'se') {
      return 'fi-SV'
    }
    const bestMatch = supportedLanguages.find((lang) => lang.code.includes(countryCode))
    if (bestMatch) {
      return bestMatch.code as SupportedLanguageCode
    }
  }

  return DEFAULT_LANGUAGE_CODE
}
