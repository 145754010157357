import React, { useState } from 'react'
import { Button, Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import AddPlayerDialog from '../dialogs/AddPlayerDialog'
import { editEntryTeamMember } from '@app/store/authentication/actions'
import { useDispatch, useSelector } from 'react-redux'
import TeamMemberCard from './TeamMemberCard'
import { formatHcpToDirtyHcp } from '@app/utils/playerUtils'

const useStyles = makeStyles((theme: Theme) => ({
  emptyCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.customPalette.mediumGray}`,
    borderRadius: 6,
    height: 90,
    padding: 15,
    marginBottom: 15,
  },
  missingPlayerError: {
    borderColor: '#FF001F',
  },
  addPlayerButton: {
    width: 140,
    height: 35,
    backgroundColor: '#4990E2',
    color: '#fff',
    border: 0,
    '&:hover': {
      border: 0,
      backgroundColor: '#4990E2',
      opacity: 0.95,
    },
  },
}))

interface Props {
  index: number
  teamMember?: TeamMember
  isPlayerRegistered?: boolean
  teamPlayerOrder?: number
  isValid: boolean
}

type TeamMemberRowProps = Props & WrappedComponentProps

function TeamMemberRow({
  index,
  teamMember,
  isPlayerRegistered = false,
  teamPlayerOrder,
  isValid,
}: TeamMemberRowProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const DEFAULT_HCP = '-54'
  const [showAddPlayerDialog, setShowAddPlayerDialog] = useState<boolean>(false)
  const authTeam = useSelector((store: StoreState) => store.authenticationReducer.team)
  const { partialSignupEnabled } = useSelector((store: StoreState) => store.tournamentReducer.tournamentSite)

  const editEntry = (fieldName: string, value: string | number | boolean) => {
    let idx = index

    if (isPlayerRegistered && !authTeam[index]) {
      idx = authTeam.length
    }

    dispatch(
      editEntryTeamMember({
        index: idx,
        fieldName,
        value,
      }),
    )
  }

  const openAddPlayerDialog = () => {
    setShowAddPlayerDialog(true)
  }

  const handlePlayerRemove = () => {
    handleCancel()
    editEntry('removed', true)
  }

  const handleCancel = () => {
    setShowAddPlayerDialog(false)
    editEntry('id', undefined)
    editEntry('providerPlayerIdentifier', undefined)
    editEntry('firstName', '')
    editEntry('lastName', '')
    editEntry('hcp', '')
    editEntry('dirtyHcp', undefined)
    editEntry('gender', '')
    editEntry('email', '')
    editEntry('profileImageUrl', '')
    editEntry('clubName', '')
    editEntry('federations', undefined)
  }

  const setFieldProps = (searchedPlayer: RegistryPlayerSearchResult) => {
    const hcp = searchedPlayer.handicap ? String(searchedPlayer.handicap) : DEFAULT_HCP
    const dirtyHcp = formatHcpToDirtyHcp(Number(hcp))
    editEntry('providerPlayerIdentifier', searchedPlayer.providerPlayerIdentifier)
    editEntry('firstName', searchedPlayer.firstName)
    editEntry('lastName', searchedPlayer.lastName)
    editEntry('hcp', hcp)
    editEntry('dirtyHcp', dirtyHcp)
    editEntry('profileImageUrl', searchedPlayer.profileImageUrl)
    editEntry('clubName', searchedPlayer.clubName)
    editEntry('removed', false)
  }

  const addSearchedPlayer = (searchedPlayer: RegistryPlayerSearchResult) => {
    setShowAddPlayerDialog(false)
    setFieldProps(searchedPlayer)
  }

  const addManualPlayer = () => {
    setShowAddPlayerDialog(false)
    editEntry('removed', false)
  }

  const shouldRenderTeamMemberCard = () => {
    const isAuthTeamMemberNotRemoved = !authTeam[index] || (authTeam[index] && !authTeam[index].removed)
    const isTeamMemberPresent =
      Boolean(teamMember.id) ||
      Boolean(teamMember.dirtyHcp) ||
      Boolean(teamMember.firstName) ||
      Boolean(teamMember.lastName) ||
      Boolean(teamMember.gender)

    return isAuthTeamMemberNotRemoved && isTeamMemberPresent
  }

  const getEmptyCardClasses = () => {
    const baseClass = classes.emptyCard
    if (!isValid && !partialSignupEnabled) {
      return `${baseClass} ${classes.missingPlayerError}`
    }
    return baseClass
  }

  const emptyCard = () => {
    return (
      <>
        <Grid item xs={11.5} sm={5.5} className={getEmptyCardClasses()}>
          <Button
            variant="outlined"
            className={classes.addPlayerButton}
            onClick={openAddPlayerDialog}
            disabled={teamPlayerOrder > 1}
          >
            <FormattedMessage id="buttons.addPlayer" />
          </Button>
        </Grid>
        <Grid item xs={0.5} />
      </>
    )
  }

  if (showAddPlayerDialog) {
    return (
      <>
        {emptyCard()}
        <AddPlayerDialog
          index={index}
          addSearchedPlayer={addSearchedPlayer}
          addManualPlayer={addManualPlayer}
          handleCancel={handleCancel}
        />
      </>
    )
  }

  if (shouldRenderTeamMemberCard()) {
    return (
      <TeamMemberCard
        index={index}
        teamMember={teamMember}
        loggedInUserIsTeamCreator={teamPlayerOrder === 1 || teamPlayerOrder === undefined}
        handleRemove={handlePlayerRemove}
        handleDelete={isPlayerRegistered ? handlePlayerRemove : undefined}
      />
    )
  }

  return emptyCard()
}

export default injectIntl(TeamMemberRow)
