import { useEffect, useState } from 'react'
import AppleSignin from 'react-apple-signin-auth'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getAppleLoginRedirectUrl } from '@app/config'
import { loginApple } from '@app/store/authentication/actions'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Cancel } from '@mui/icons-material'
import CustomTooltip from './CustomTooltip'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 380,
    borderRadius: 10,
    padding: '10px 20px',
    margin: '20px 0 40px',
  },
  buttonContainer: {
    margin: '20px 0 40px',
  },
  button: {
    width: '100%',
    maxWidth: 350,
    height: 50,
    borderRadius: '6px !important',
    padding: '15px 0',
    fontFamily: 'Roboto',
    fontSize: '18px !important',
    fontWeight: 500,
    lineHeight: 21,

    '& > svg': {
      marginRight: 10,
    },
  },
  dialogContainer: {
    '& .MuiPaper-root': {
      minWidth: 600,
      maxWidth: 600,
      borderRadius: 8,
      padding: 20,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '95%',
        maxWidth: '95%',
        minWidth: '95%',
        margin: 0,
      },
    },
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: 700,
    fontStyle: 'normal',
    backgroundColor: 'transparent',
    color: '#032615',
    marginBottom: 0,
    padding: 20,
    paddingBottom: 10,
  },
  dialogContent: {
    width: '90%',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.5,
    padding: 20,
    '& > p': {
      color: '##373D40',
      marginTop: 0,
      marginBottom: 0,
      '&:first-of-type': {
        color: '#8A8A8E',
      },
    },
  },
  closeDialog: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20,
    '&:hover': {
      opacity: 0.7,
    },
  },
}))

export const AppleSignInButton = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [error, setError] = useState<boolean>(false)
  const { appleLoading, appleError } = useSelector((store: StoreState) => store.authenticationReducer)
  const { appleClientId } = useSelector((store: StoreState) => store.tournamentReducer.tournamentSite)

  useEffect(() => {
    if (appleError) {
      setError(true)
    }
  }, [appleError])

  const handleSuccess = (response: AppleLoginSuccessResponse) => {
    dispatch(loginApple({ appleAuthorizationCode: response.authorization.code }))
  }

  const getButtonText = () => {
    if (appleLoading) {
      return <CircularProgress color="inherit" size={24} />
    }
    return intl.formatMessage({ id: 'buttons.continueWithApple' })
  }

  const renderErrorDialog = () => {
    return (
      <Dialog open className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          {intl.formatMessage({ id: 'strings.playerNotFoundWithAppleId' })}
          <Cancel onClick={() => setError(false)} className={classes.closeDialog} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <p>{intl.formatMessage({ id: 'strings.noGgbProfileFoundWithAppleId' })}</p>
          <p>{intl.formatMessage({ id: 'strings.useEmailAndPasswordToLogin' })}</p>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
      {error && renderErrorDialog()}
      <CustomTooltip
        title={intl.formatMessage({ id: 'strings.continueWithAppleTooltip' })}
        arrow
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.buttonContainer}>
          <AppleSignin
            authOptions={{
              clientId: appleClientId,
              scope: 'email',
              redirectURI: getAppleLoginRedirectUrl(),
              usePopup: true,
            }}
            uiType={'dark'}
            className={classes.button}
            buttonExtraChildren={getButtonText()}
            onSuccess={handleSuccess}
            onError={() => setError(true)}
          />
        </div>
      </CustomTooltip>
    </>
  )
}
