import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Cancel } from '@mui/icons-material'
import { Grid, Theme } from '@mui/material'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { makeStyles } from '@mui/styles'
import TeamMemberManual from '../ui/TeamMemberManual'

import TourButton from '../ui/TourButton'
import TextButton from '../ui/TextButton'

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    padding: '30px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > button': {
      width: '50%',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '30px 45px',
    },
  },
}))

interface Props {
  index: number
  handleSave: () => void
  handleCancel: () => void
}

export default function EditPlayerDialog({ index, handleSave, handleCancel }: Props) {
  const intl = useIntl()
  const classes = useStyles()
  const { team } = useSelector((store: StoreState) => store.authenticationReducer)
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
  const teamMember = team?.[index]

  const isTempPlayer: boolean =
    (teamMember?.id === undefined && teamMember?.providerPlayerIdentifier === undefined) ||
    teamMember?.temporary === true

  const isSaveDisabled = (): boolean => {
    if (!isTempPlayer) {
      return !teamMember?.hcp && !teamMember?.dirtyHcp
    }
    return (
      !teamMember?.firstName ||
      !teamMember?.lastName ||
      !teamMember?.gender ||
      (!teamMember?.hcp && !teamMember?.dirtyHcp) ||
      emailInvalid
    )
  }

  const emailInvalidCallback = (disabled: boolean): void => {
    setEmailInvalid(disabled)
  }

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingRight: 50 }}>
        <span>{intl.formatMessage({ id: 'strings.editPlayer' })}</span>
        <Cancel
          onClick={handleCancel}
          className="linkIcon"
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TeamMemberManual index={index} temporary={isTempPlayer} emailInvalidCallback={emailInvalidCallback} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <TextButton onClick={handleCancel}>{intl.formatMessage({ id: 'buttons.cancel' })}</TextButton>
        <TourButton
          loading={false}
          color={'primary'}
          buttonProps={{
            onClick: () => handleSave(),
          }}
          disabled={isSaveDisabled()}
        >
          {intl.formatMessage({ id: 'buttons.save' })}
        </TourButton>
      </DialogActions>
    </Dialog>
  )
}
