import axios from 'axios'
import { APIRoute } from '../../config'
import { pickBy } from 'lodash'

export const fetchCountries = async () => {
  const res = await axios.get(APIRoute.GET_COUNTRIES)

  return res.data.countries
}

export const fetchCountryStates = async (countryId: number) => {
  const res = await axios.get(APIRoute.GET_COUNTRY_STATES(countryId))

  return res.data.states
}

export const searchClubs = async (searchTerm: string, countryId?: number) => {
  const res = await axios.get(APIRoute.GET_SEARCH_CLUBS, {
    params: pickBy({
      searchTerm,
      countryId,
    }),
  })

  return res.data.clubs
}

export const getUHSClubs = async () => {
  const res = await axios.get(APIRoute.GET_CLUBS_UHS)

  return res.data
}
