import React, { CSSProperties } from 'react'
import { FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '../../theme/materialUITheme'

export type SelectBoxMenuItem = {
  id: string
  value: string | number
  label: string
}

export type SelectBoxProps = {
  name: string
  items: SelectBoxMenuItem[]
  onChange: (value: SelectBoxMenuItem['value']) => void
  value: SelectBoxMenuItem['value']
  disabled?: boolean
  style?: CSSProperties
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    borderRadius: '10px',
    boxShadow: '0px 1px 3px 1px rgba(0,0,0,0.15)',
    textAlign: 'center',
    fontFamily: ['Exo', 'sans-serif'].join(','),
    height: rem(40),
  },
}))

export const SelectBox = (props: SelectBoxProps) => {
  const classes = useStyles()

  const renderMenuItems = (items: SelectBoxMenuItem[]) => {
    return items.map((item) => (
      <MenuItem key={item.id} value={item.value}>
        {item.label}
      </MenuItem>
    ))
  }

  const onChange = (event) => {
    const { value } = event.target
    props.onChange(value)
  }

  return (
    <FormControl>
      <Select
        {...props}
        onChange={onChange}
        value={props.value}
        input={<OutlinedInput name={props.name} sx={{ width: rem(200) }} />}
        className={classes.inputRoot}
      >
        {renderMenuItems(props.items)}
      </Select>
    </FormControl>
  )
}
