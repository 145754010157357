import Typography from '@mui/material/Typography'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Link, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { InfoOutlined } from '@mui/icons-material'
import { formTournamentSiteUrl } from '../../config'
import { rem } from '../../theme/materialUITheme'
import { fetchCountries } from '../../store/clubsAndCountries/actions'
import { TournamentTypes } from '../../utils/enums'
import { CustomScroll } from '../ui/CustomScroll'

type StateProps = {
  tour: Tour
  countries: Country[]
}

interface DispatchProps {
  fetchCountries(): void
}

type Props = DispatchProps & StateProps

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.customPalette.lightGray2}`,
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
      '& p': {
        paddingBottom: 30,
      },
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
  eventsList: {
    padding: '25px 50px',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: rem(32),
    textAlign: 'center',
    marginBottom: 40,
  },
  text: {
    fontSize: rem(18),
    fontWeight: 500,
    paddingBottom: '0 !important',
  },
  subText: {
    fontSize: rem(18),
    paddingBottom: '0 !important',
    color: '#747474',
  },
  date: {
    display: 'flex',
    paddingBottom: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  rowContainer: {
    paddingLeft: 40,
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    display: 'flex',
    paddingTop: '0 !important',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: theme.customPalette.mediumGray2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      paddingTop: '18px !important',
    },
  },
  club: {
    display: 'flex',
    paddingTop: '0 !important',
    alignItems: 'center',
    fontFamily: 'Roboto',
    '& > p': {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      marginLeft: 10,
      '& > p': {
        maxWidth: '100%',
      },
    },
  },
  city: {
    display: 'flex',
    paddingTop: '0 !important',
    alignItems: 'center',
    fontFamily: 'Roboto',
    color: '#747474 !important',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: `${rem(48)} !important`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      marginLeft: 10,
    },
  },
  site: {
    display: 'flex',
    paddingTop: '0 !important',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18px !important',
      marginLeft: 10,
    },
  },
  eventTitle: {
    fontSize: rem(20),
    lineHeight: 1.375,
    fontWeight: 900,
    fontFamily: ['Exo', 'sans-serif'].join(','),
    color: theme.palette.mode === 'light' ? '#555555' : '#FFFFFF',
    textTransform: 'uppercase',
  },
  signUpResultsButton: {
    margin: '0 20px 0 0',
    width: 105,
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderRadius: 6,
    '&:hover': {
      borderWidth: 2,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px 0 0',
    },
  },
  infoCircle: {
    fontSize: rem(28),
    marginTop: 5,
  },
}))

const TourEvents = ({ tour, fetchCountries, countries }: Props) => {
  const classes = useStyles()

  useEffect(() => {
    if (!countries) {
      fetchCountries()
    }
  }, [fetchCountries, countries])

  useEffect(() => {
    const firstUpcomingOrOngoingEvent = document.querySelector('.upcoming') as HTMLElement
    if (firstUpcomingOrOngoingEvent) {
      const originalOffset = window.scrollY
      firstUpcomingOrOngoingEvent.scrollIntoView()
      window.scrollTo({ top: originalOffset })
    }
  }, [])

  if (!tour) {
    return null
  }

  const { tournaments } = tour

  const getDate = (date: string) => {
    return (
      <>
        <FormattedMessage id={`month.${moment(date).format('M')}`} /> {moment(date).format('D')}
      </>
    )
  }

  const getDateRange = (startDate: string, endDate: string) => {
    return (
      <>
        <FormattedMessage id={`month.${moment(startDate).format('M')}`} />
        &nbsp;{moment(startDate).format('D')}&nbsp;-&nbsp;
        <FormattedMessage id={`month.${moment(endDate).format('M')}`} />
        &nbsp;{moment(endDate).format('D')}
      </>
    )
  }

  const tournamentList = (): JSX.Element[] => {
    return tournaments.map((tournament, i) => {
      // TODO: TODO:PHASE:MULTIROUND
      const firstRound = tournament.rounds[0]
      const club = firstRound && firstRound.club
      const startTime = firstRound && firstRound.startTime
      const endTime = firstRound && firstRound.endTime
      const country = club && countries.find((country) => country.id === club.countryId)

      const siteSettings = tournament.siteSettings

      let dateClasses = classes.date
      const now = moment()
      const isEndingNext = endTime && moment(endTime).isSameOrAfter(now)
      const isStartingNext = !endTime && moment(startTime).isSameOrAfter(now)
      if (isEndingNext || isStartingNext) {
        dateClasses += ' upcoming'
      }

      return (
        <Fragment key={i}>
          <Grid item xs={12} className={dateClasses}>
            <span className={classes.eventTitle}>
              {endTime &&
              moment(startTime).isBefore(moment(endTime)) &&
              moment(startTime).format('MMMM D') !== moment(endTime).format('MMMM D') &&
              tournament.tournamentType === TournamentTypes.weekly
                ? getDateRange(startTime, endTime)
                : getDate(startTime)}
            </span>
          </Grid>
          <Grid container spacing={1} className={classes.rowContainer}>
            <Grid item xs={12} sm={3} className={classes.name}>
              <Typography variant="body1" className={classes.text}>
                {tournament.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.club}>
              <Typography variant="body1" className={classes.subText}>
                {club && club.name}
                {club && ', ' + club.city}
                {country && `, ${country.name}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.site}>
              {tournament.tournamentSiteEnabled && tournament.resultsPublished ? (
                <Link href={`${formTournamentSiteUrl(tournament.id)}`}>
                  <Button variant="outlined" color="primary" className={classes.signUpResultsButton}>
                    <strong>
                      <FormattedMessage id="buttons.results" />
                    </strong>
                  </Button>
                </Link>
              ) : tournament.tournamentSiteEnabled && siteSettings.onlineEntriesEnabled ? (
                <Link
                  href={`${formTournamentSiteUrl(tournament.id)}${
                    siteSettings.tournamentInvitationText.length > 0 ? '/invitation' : ''
                  }`}
                >
                  <Button variant="outlined" color="primary" className={classes.signUpResultsButton}>
                    <strong>
                      <FormattedMessage id="buttons.tourSignUp" />
                    </strong>
                  </Button>
                </Link>
              ) : (
                <></>
              )}
              {tournament.tournamentSiteEnabled && (
                <Link href={`${formTournamentSiteUrl(tournament.id)}`}>
                  <InfoOutlined color="primary" className={classes.infoCircle} />
                </Link>
              )}
            </Grid>
          </Grid>
        </Fragment>
      )
    })
  }

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        <FormattedMessage id="strings.events" />
      </Typography>
      <div className={classes.root}>
        <CustomScroll forceVisible style={{ maxHeight: 600 }}>
          <Grid container spacing={5} className={classes.eventsList}>
            {tournamentList()}
          </Grid>
        </CustomScroll>
      </div>
    </>
  )
}

export default connect(
  (state: StoreState) => ({
    tour: state.tourAndRankingReducer.tour,
    countries: state.clubsAndCountriesReducer.countries,
  }),
  {
    fetchCountries,
  },
)(TourEvents)
