/* eslint-disable max-len */
import React from 'react'

export const CourseIcon = ({ style = {}, width = '100%', className = '', height = '100%', viewBox = '0 0 55 55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <path d="M27.5,28a52.64,52.64,0,0,0-6.39.38V17.52l15.71-5.76a.81.81,0,0,0,.53-.76.82.82,0,0,0-.53-.77L20.58,4.28a.82.82,0,0,0-1,.48.73.73,0,0,0,0,.41.78.78,0,0,0,0,.14V28.64c-9.88,1.52-17,5.76-17,10.76,0,6.28,11.19,11.37,25,11.37s25-5.09,25-11.37S41.31,28,27.5,28ZM22.2,43.46c-3.44,0-6.23-2.3-6.23-5.14a5.16,5.16,0,0,1,3.52-4.62v4.89a.81.81,0,1,0,1.62,0V33.26a7.88,7.88,0,0,1,1.09-.08c3.43,0,6.22,2.3,6.22,5.14S25.63,43.46,22.2,43.46ZM39,45.09a3.52,3.52,0,1,1,3.52-3.52A3.53,3.53,0,0,1,39,45.09Z" />
  </svg>
)
