import { useMemo, useState } from 'react'
import { formatDefaultTournamentSeo } from '@app/components/seo/utils'
import OverlayLoader from '@app/components/ui/OverlayLoader'
import { ConstructedHead } from '@app/components/seo/ConstructedHead'
import { Theme, Grid, Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import ScreenMenu from '@app/components/screen/ScreenMenu'
import { rem } from '@app/theme/materialUITheme'
import { LeaderboardBg, PoweredByGGBlight } from '../../assets/images'
import LeaderboardScreen from '@app/components/screen/LeaderboardScreen'
import ContestsDataScreen from '@app/components/screen/ContestsDataScreen'
import { useAppState } from '@app/hooks'
import { LeaderboardHiddenHolesInfo } from '@app/components/tournament/ui/LeaderboardHiddenHolesInfo'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100vh',
    overflow: 'hidden',
    padding: '30px 0',
    display: 'grid',
    gridTemplateRows: 'min-content min-content minmax(auto, min-content) min-content',
    gridTemplateColumns: '1fr',
  },
  headerSection: {},
  messageSection: {},
  leaderboardSection: {
    overflow: 'auto',
    scrollbarWidth: 'none',
  },
  poweredBySection: {
    paddingTop: '3px',
  },
  gridContainer: {
    width: '90%',
    justifyContent: 'center',
    margin: '0 auto',
    '@media only screen and (min-width: 1300px)': {
      width: '65%',
    },
  },
  leaderboardHeaderContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
  },
  leaderboardHeader: {
    backgroundColor: theme.palette.primary.main,
    background: `
        linear-gradient(90deg,
        ${theme.customPalette.ggbGreen} 20%,
        ${theme.customPalette.ggbDarkGreen} 100%)
      `,
    borderRadius: '3px',
    padding: 15,
    boxShadow: `0 0 2px 0 #999`,
    '& h2': {
      color: theme.palette.primary.contrastText,
      fontSize: rem(28),
      fontStyle: 'normal',
    },
  },
  leaderboardHeaderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: rem(14),
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  leaderboardHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: rem(18),
    },
  },
  messagesContainer: {
    paddingLeft: '0 !important',
  },
  leaderboardContainer: {
    paddingLeft: '0 !important',
  },
  contestsWrapper: {
    padding: '3px 0 0 5px !important',
  },
  contestsContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    height: '100%',
    position: 'relative',
  },
  menu: {
    color: theme.palette.primary.contrastText,
  },
  logoContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
    padding: `0 ${rem(10)} 0 0 !important`,
    '@media only screen and (max-width: 959px)': {
      padding: `0 0 ${rem(10)} 0 !important`,
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.customPalette.background,
    height: '100%',
    boxShadow: `0 0 2px 0 #999`,
    borderRadius: '3px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: 90,
  },
  ggbLogo: {
    display: 'block',
    marginLeft: 'auto',
    width: 200,
    marginTop: 10,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  columnDivider: {
    padding: `0 ${rem(20)}`,
  },
  headerTable: {
    marginTop: rem(10),
    marginLeft: rem(1),
    width: 'auto',
  },
  headerTableRow: {
    '& td': {
      fontFamily: ['Exo', 'sans-serif'].join(','),
      color: theme.palette.common.white,
      fontSize: rem(14),
      fontWeight: 500,
      border: 0,
      padding: 0,
    },
  },
  tableCellDivider: {
    padding: `0 ${rem(15)} !important`,
  },
  gameModeText: {
    fontWeight: 600,
    fontSize: `${rem(22)} !important`,
  },
  poweredByContainer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'end',
  },
  poweredBy: {
    width: '292px',
    position: 'relative',

    '& svg': {
      width: '100%',
    },

    '& img': {
      position: 'absolute',
      top: 12,
      right: 33,
      width: '173px',
    },
  },
}))

export const TournamentScreen = () => {
  const [gameType, setGameType] = useState<'primary' | 'side' | 'contests'>('primary')
  const [isAutoRolling, setAutoRolling] = useState(false)
  const [rollingSpeed, setRollingSpeed] = useState<number>(0)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const {
    tournamentSiteState,
    leaderboardState,
    tournament,
    tournamentId,
    tournamentState: { isLoading },
  } = useAppState({ autoloadResources: ['tournament', 'leaderboard'] })
  const classes = useStyles()

  const logoUrl = tournamentSiteState?.images?.logoImage?.url

  const contestsEnabled = tournamentSiteState?.tournament.rounds.some((r) => r.contestsEnabled)

  const firstRound = tournament?.rounds[0]

  // TODO:PHASE:MULTIROUND Number of hidden holes should be taken from the last round,
  // alternatively this parameter should be moved outside of the rounds array
  const numHolesHidden = tournament?.rounds[0]?.hideResultsFromLastHoles

  const gameModeName = useMemo(() => {
    if (!firstRound) {
      return ''
    }
    switch (gameType) {
      case 'contests':
        return <FormattedMessage id="gameFormatTypes.contests" />
      case 'side':
        return firstRound.sideGame.name
      default:
        return firstRound.primaryGame.name
    }
  }, [firstRound, gameType])

  const sideGameEnabled = firstRound?.sideGameEnabled

  const gameModeNames = {
    primary: firstRound?.primaryGame && firstRound.primaryGame.name,
    side: firstRound?.sideGame && firstRound.sideGame.name,
  }

  const renderLeaderboardHeaderDetailRow = (round: TournamentRound, index: number) => {
    if (!leaderboardState.data) {
      return
    }
    const { rounds } = leaderboardState.data
    const { club, course, startTime } = round

    return (
      <TableRow className={classes.headerTableRow} key={round.id}>
        {rounds && rounds.length > 1 && <TableCell style={{ paddingRight: '10px' }}>R{index + 1}: </TableCell>}
        {rounds && rounds.length === 1 && <TableCell>{club.name}</TableCell>}
        {rounds && rounds.length === 1 && <TableCell className={classes.tableCellDivider}>|</TableCell>}
        <TableCell>{course.courseName}</TableCell>
        <TableCell className={classes.tableCellDivider}>|</TableCell>
        <TableCell>
          <FormattedMessage id="leaderboard.par" /> {course.par}
        </TableCell>
        <TableCell className={classes.tableCellDivider}>|</TableCell>
        <TableCell>{moment(startTime).format('D.M.YYYY')}</TableCell>
      </TableRow>
    )
  }

  const renderLeaderboardHeaderDetails = (rounds: TournamentRound[]) => {
    return (
      <TableContainer style={{ overflow: 'hidden' }}>
        <Table className={classes.headerTable} size="small">
          <TableBody>{rounds.map(renderLeaderboardHeaderDetailRow)}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderContestsData = () => {
    return <ContestsDataScreen tournamentId={tournamentId} />
  }

  const onPageClick = () => {
    if (isAutoRolling && !isMenuOpen) {
      setAutoRolling(false)
    }
  }

  if (!tournament || isLoading) {
    return <OverlayLoader />
  }

  return (
    <>
      <ConstructedHead {...formatDefaultTournamentSeo({ pageName: 'Tournament Screen' })} noIndex />
      <main className={classes.root} style={{ backgroundImage: `url(${LeaderboardBg})` }} onClick={onPageClick}>
        <section className={classes.headerSection}>
          <Grid container spacing={2} className={classes.gridContainer}>
            {logoUrl && (
              <Grid item xs={12} md={2} className={classes.logoContainer}>
                <div className={classes.logoWrapper}>
                  <img src={logoUrl} className={classes.logo} alt="" />
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={logoUrl ? 10 : 12} className={classes.leaderboardHeaderContainer}>
              <Grid container spacing={2} className={classes.leaderboardHeader}>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h2">{tournament.name}</Typography>
                  {renderLeaderboardHeaderDetails(tournament.rounds)}
                </Grid>

                <Grid item xs={12} sm={5} className={classes.leaderboardHeaderActions}>
                  <div className={classes.menuContainer}>
                    <Typography variant="h2" className={classes.gameModeText}>
                      {gameModeName}
                    </Typography>
                    <ScreenMenu
                      gameModeNames={gameModeNames}
                      selected={gameType}
                      setSelected={(selected) => setGameType(selected as any)}
                      rolling={isAutoRolling}
                      setRolling={setAutoRolling}
                      rollingSpeed={rollingSpeed}
                      setRollingSpeed={setRollingSpeed}
                      sideGameEnabled={sideGameEnabled}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>

        <section className={classes.messageSection}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.messagesContainer}>
              {numHolesHidden > 0 && <LeaderboardHiddenHolesInfo numHiddenHoles={numHolesHidden} />}
            </Grid>
          </Grid>
        </section>

        <section className={classes.leaderboardSection} id="scrollable-container">
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={contestsEnabled ? 9 : 12} className={classes.leaderboardContainer}>
              <LeaderboardScreen
                tournamentId={tournamentId}
                gameType={gameType}
                type={'leaderboard'}
                scrolling={isAutoRolling}
                rollingSpeed={rollingSpeed}
              />
            </Grid>

            {contestsEnabled && (
              <Grid item xs={12} md={3} className={classes.contestsWrapper}>
                <div className={classes.contestsContainer}>
                  <div style={{ position: 'sticky', top: 0 }}>{renderContestsData()}</div>
                </div>
              </Grid>
            )}
          </Grid>
        </section>

        <section className={classes.poweredBySection}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={contestsEnabled ? 9 : 12}>
              <div className={classes.poweredByContainer}>
                <div className={classes.poweredBy}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="11 10 384.9 79" fill="none">
                    <g>
                      <path
                        // eslint-disable-next-line max-len
                        d="M396 13C396 11.3431 394.657 10 393 10H14.0994C11.3154 10 10.0329 13.4626 12.1451 15.2761L96.004 87.2761C96.548 87.7432 97.2412 88 97.9583 88H393C394.657 88 396 86.6569 396 85V13Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <img src={PoweredByGGBlight} alt="Powered by Golf Gamebook" />
                </div>
              </div>
            </Grid>
            {contestsEnabled && <Grid item xs={12} md={3}></Grid>}
          </Grid>
        </section>
      </main>
    </>
  )
}
