import { Action } from 'redux'
import { TOURNAMENT_LOAD_LEADERBOARD } from './constants'

//TODO:PHASE:LEADERBOARD Remove unused actions.

/*
 * TOURNAMENT LOAD LEADERBOARD
 */
export interface LoadTournamentLeaderboardAction extends Action {
  type: 'TOURNAMENT_LOAD_LEADERBOARD'
  id: number
  divisionId?: number
  onSuccess?: () => void
}
export interface LoadTournamentLeaderboardSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_LEADERBOARD_SUCCESS'
  data: TournamentLeaderboard
  loading: boolean
}
export interface LoadTournamentLeaderboardFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_LEADERBOARD_FAILURE'
  error: ErrorPayload
}
export const loadTournamentLeaderboard = (id: number, divisionId?: number, onSuccess?: () => void) => ({
  type: TOURNAMENT_LOAD_LEADERBOARD,
  id,
  divisionId,
  onSuccess,
})
