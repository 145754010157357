import React, { CSSProperties } from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'
import TourLogo from './TourLogo'

const styles = createStyles((theme: Theme) => ({
  root: {
    padding: '50px 20px 20px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0 20px',
    },
  },
  title: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}))

interface Props extends WithStyles<typeof styles> {
  style?: CSSProperties
  tour: Tour
  classes: {
    root: string
    title: string
    text: string
  }
}

class TournamentTitleBlock extends React.Component<Props> {
  render() {
    const { classes, style, tour } = this.props

    return (
      <div className={classes.root} style={style}>
        <Typography variant={'h1'} className={classes.title}>
          {tour.name}
        </Typography>

        <TourLogo />
      </div>
    )
  }
}

export default withStyles(styles)(
  connect((state: StoreState) => ({
    tour: state.tourAndRankingReducer.tour,
  }))(TournamentTitleBlock),
)
