import React from 'react'
import { Typography, Grid, TextField, Checkbox, Theme, Modal, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import TourButton from '../ui/TourButton'
import { connect } from 'react-redux'
import { editEntryTeamMember, EditEntryTeamMemberPayload, updateUserField } from '@app/store/authentication/actions'
import { areRequiredAnswersValid, isMandatoryDivisionSelected } from '@app/utils/customQuestionUtils'
import TeamMemberRow from '../ui/TeamMemberRow'
import { LineDivider } from '../dialogs/LineDivider'
import { maxEntriesAmountExceeded } from '@app/utils/tournamentUtils'
import { clearSearch, searchPlayers } from '@app/store/tournament/actions'
import isEmail from 'validator/lib/isEmail'
import createStyles from '@mui/styles/createStyles'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { CancelEntryConfirmation } from './CancelEntryConfirmation'
import { isTeamCreator } from '@app/utils/playerUtils'

const styles = (theme: Theme) =>
  createStyles({
    actionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    button: {
      width: 295,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    teamName: {
      maxWidth: 400,
      margin: '20px 0',
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    teamPlayersContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '15px 0',
    },
    teamPlayersTitle: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    teamPlayersSubtitle: {
      color: theme.customPalette.siteNavigationText,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      marginTop: 16,

      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    terms: {
      marginBottom: 12,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 48,
      },
    },
    partialSignUpModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 335,
      height: 240,
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 12,
      padding: '50px 20px 35px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      '& > h2': {
        color: theme.customPalette.siteNavigationText,
        fontSize: 18,
        fontStyle: 'italic',
        fontWeight: 700,
      },
      '& > button': {
        width: '100%',
      },
    },
  })

interface OwnProps extends WithStyles<typeof styles> {
  loading?: boolean
  entryBtnLoading?: boolean
  questionErrors: { [property: string]: boolean }
  disableSignUp?: boolean
  isRegistered?: boolean
  handleJoinTournament?(): void
  cancelEntryFn?(): void
  handleContinueToPayment?(): void
  handleStep?(): void
  handleEditRegistration?(closeOnComplete?: boolean): void
  clearErrors(): void
  setQuestionErrors(errors: { [property: string]: boolean | undefined }): void
  setMandatoryDivisionError(): void
}

interface StateProps {
  auth: AuthenticationState
  tournamentSite: TournamentSite
  termsAndConditions: string | null
  registryPlayerSearchResult: RegistryPlayerSearchResult[]
  searchLoading: boolean
  tournamentPlayers: TournamentPlayersState
  registration?: UserRegistration
}

interface DispatchProps {
  updateUserField(payload: FieldUpdatePayload): void
  editEntryTeamMember(payload: EditEntryTeamMemberPayload): void
  searchPlayers(searchText: string, onComplete?: () => void): void
  clearSearch(): void
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  valid: boolean
  acceptTerms: boolean
  registeredPlayerAmount: number
  partialTeamWarningDisplayed: boolean
  cancelConfirmation: boolean
}

class TeamTournamentEntryForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      valid: true,
      acceptTerms: false,
      registeredPlayerAmount: 0,
      partialTeamWarningDisplayed: false,
      cancelConfirmation: false,
    }
  }

  render() {
    const { tournamentSite, tournamentPlayers, auth, classes } = this.props

    const team = auth?.team || []
    const teamMembersCount = team.filter((member) => member.firstName).length
    const { defaultTeamSize, partialSignupEnabled } = tournamentSite
    const shouldShowPartialSignUpWarning = partialSignupEnabled && teamMembersCount < defaultTeamSize
    const shouldShowQuestionsValidationError = Object.keys(this.props.questionErrors).length > 0
    const shouldShowPartialSignUpValidationError =
      shouldShowPartialSignUpWarning && !this.state.partialTeamWarningDisplayed

    const useReserveList = tournamentSite.reserveListEnabled
      ? maxEntriesAmountExceeded(tournamentSite, tournamentPlayers)
      : !tournamentSite.reserveListEnabled

    const teamName =
      this.props.auth?.user?.teamName || this.props.registration?.team?.name || `Team ${this.props.auth.user.lastName}`

    return (
      <>
        {this.props.tournamentSite.customQuestions.length > 0 && <LineDivider style={{ marginTop: 15 }} />}

        <Grid container>
          <Grid item xs={12} sm={5.5}>
            <TextField
              className={classes.teamName}
              label={<FormattedMessage id={'strings.teamName'} />}
              value={teamName}
              onChange={({ target: { value } }) => {
                this.props.updateUserField({
                  fieldName: 'teamName',
                  value: value,
                })
              }}
              fullWidth
              disabled={this._isTeamCreator() === false}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant={'h4'} className={classes.teamPlayersTitle}>
              <FormattedMessage id="strings.addTeamPlayers" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant={'subtitle1'} className={classes.teamPlayersSubtitle}>
              <FormattedMessage id="strings.players" /> {teamMembersCount}/{defaultTeamSize}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.teamPlayersContainer}>
          {this._renderTeamMembers()}
        </Grid>

        {shouldShowQuestionsValidationError && (
          <Typography variant="body1" color="error" style={{ marginBottom: 20, marginLeft: 15 }}>
            <FormattedMessage id="strings.invalidEntry" />
          </Typography>
        )}

        {!this.state.valid && shouldShowPartialSignUpValidationError && (
          <Modal open={true}>
            <Box className={classes.partialSignUpModal}>
              <h2>
                <FormattedMessage
                  id="strings.partialTeamWarning"
                  values={{ amount: defaultTeamSize - teamMembersCount }}
                />
              </h2>
              <TourButton
                loading={false}
                color={'primary'}
                buttonProps={{
                  onClick: () => this.setState({ partialTeamWarningDisplayed: true, valid: true }),
                }}
              >
                <FormattedMessage id="strings.okGotIt" />
              </TourButton>
            </Box>
          </Modal>
        )}

        <div className={classes.actionContainer}>
          {this.props.termsAndConditions && (
            <div className={classes.terms}>
              <Typography variant="body1">
                <Checkbox
                  id="acceptTerms"
                  name="acceptTerms"
                  color="primary"
                  required
                  checked={this.state.acceptTerms}
                  onChange={(e) => this.setState({ acceptTerms: e.currentTarget.checked })}
                />
                <FormattedMessage
                  id="strings.acceptCustomTermsAndConditions"
                  values={{
                    terms: (
                      <a
                        href={this.props.termsAndConditions}
                        target="_blank"
                        style={{ color: 'inherit' }}
                        rel="noreferrer"
                      >
                        <FormattedMessage id="strings.acceptTerms2" />
                      </a>
                    ),
                  }}
                />
              </Typography>
            </div>
          )}
          <div className={classes.buttonContainer}>
            {this._findTeamPlayerOrder() > 1 && (
              <Typography variant="body2" color="black">
                <FormattedMessage id="strings.saveButtonInfo" />
              </Typography>
            )}
            <TourButton
              loading={this.props.loading || false}
              color={'primary'}
              className={classes.button}
              buttonProps={{
                onClick: this.validateAndContinue,
              }}
              disabled={this.isButtonDisabled()}
            >
              {tournamentSite.paymentEnabled && useReserveList ? (
                <FormattedMessage id={'buttons.continue'} />
              ) : this._isPlayerRegistered() ? (
                <FormattedMessage id={'buttons.save'} />
              ) : (
                <FormattedMessage id={'buttons.signUp'} />
              )}
            </TourButton>
            {this._isPlayerRegistered() && (
              <TourButton
                loading={this.props.entryBtnLoading || false}
                className={classes.button}
                color={'error'}
                buttonProps={{
                  onClick: this._handleCancelEntry,
                }}
                disabled={this._findTeamPlayerOrder() !== 1}
              >
                <FormattedMessage id={'buttons.cancelEntry'} />
              </TourButton>
            )}
          </div>
        </div>
        <CancelEntryConfirmation
          open={this.state.cancelConfirmation}
          okAction={this.props.cancelEntryFn}
          cancelAction={() => this.setState({ cancelConfirmation: false })}
        />
      </>
    )
  }

  private _handleCancelEntry = () => {
    this.setState({ cancelConfirmation: true })
  }

  private isButtonDisabled = () => {
    const { tournamentSite, tournamentPlayers } = this.props
    const useReserveList = tournamentSite.reserveListEnabled
      ? maxEntriesAmountExceeded(tournamentSite, tournamentPlayers)
      : !tournamentSite.reserveListEnabled

    if (this._isPlayerRegistered()) {
      if (this._findTeamPlayerOrder() > 1) {
        return true
      }

      return false
    }

    if (tournamentSite.paymentEnabled && useReserveList) {
      return false
    }

    return this.props.disableSignUp || (!this.state.acceptTerms && !!this.props.termsAndConditions)
  }

  private validateAndContinue = () => {
    let valid = true
    this.props.clearErrors()
    const { auth, tournamentSite, tournamentPlayers } = this.props
    const { partialTeamWarningDisplayed } = this.state
    let team = auth.team
    const teamSize = this._teamSize
    let amountOfEmptyPlayerFields = 0 //Amount of empty fields on player row

    team = team.slice(0, teamSize)

    const { valid: isValid, errors } = areRequiredAnswersValid(tournamentSite.customQuestions, auth.questionAnswers)
    const mandatoryDivisionSelected = isMandatoryDivisionSelected(tournamentSite, auth.user?.divisionId)

    const shouldExitEarly = !valid || !mandatoryDivisionSelected
    if (!isValid) {
      valid = false
      this.props.setQuestionErrors(errors)
    }

    if (!mandatoryDivisionSelected) {
      this.props.setMandatoryDivisionError()
    }

    if (shouldExitEarly) {
      return
    }

    // Check if players missing
    if (!tournamentSite.partialSignupEnabled && team.length < teamSize) {
      this.setState({ valid: false })
      return
    }

    // Check if some of the player fields are not empty
    for (let index = 1; index < team.length; index++) {
      const data = team[index]
      const requiredTempFields = data?.providerPlayerIdentifier
        ? [data.firstName, data.lastName, data.hcp]
        : [data.firstName, data.lastName, data.gender, data.hcp]
      if (requiredTempFields.every((f) => f !== '')) {
        amountOfEmptyPlayerFields++
      }
    }

    for (let index = 1; index < team.length; index++) {
      const data = team[index]
      const requiredTempFields = data?.providerPlayerIdentifier
        ? [data.firstName, data.lastName, data.hcp]
        : [data.firstName, data.lastName, data.gender, data.dirtyHcp]

      if (tournamentSite.partialSignupEnabled) {
        if (!!data.email && !isEmail(data.email)) {
          valid = false
          return
        }

        if (
          requiredTempFields.some((f) => f === '') &&
          amountOfEmptyPlayerFields < team.length - 1 &&
          !partialTeamWarningDisplayed
        ) {
          valid = false
        }
      } else {
        if (requiredTempFields.some((f) => f === '')) {
          valid = false
        }
      }
    }

    /**
     * Check if fields in team are empty, if true, show warning dialog
     */
    const isNotValidOrMissingPlayers = !valid || team.length < teamSize
    if (tournamentSite.partialSignupEnabled && isNotValidOrMissingPlayers && !partialTeamWarningDisplayed) {
      this.setState({ valid: false })
      return
    }

    if (Object.keys(this.props.questionErrors).length > 0) {
      valid = false
    }

    const useReserveList = tournamentSite.reserveListEnabled
      ? maxEntriesAmountExceeded(tournamentSite, tournamentPlayers)
      : !tournamentSite.reserveListEnabled

    this.setState({ valid })

    if (valid && this._isPlayerRegistered()) {
      if (tournamentSite.paymentEnabled) {
        this.props.handleEditRegistration(false)
        this.props.handleContinueToPayment()
      } else {
        this.props.handleEditRegistration()
      }
      return
    }

    if (valid) {
      if (tournamentSite.paymentEnabled && useReserveList) {
        this.props.handleContinueToPayment()
      } else {
        this.props.handleJoinTournament()
      }
    }
  }

  get _user() {
    return this.props.auth.user
  }

  private get _teamSize() {
    const defaultTeamSize = this.props.tournamentSite.defaultTeamSize ? this.props.tournamentSite.defaultTeamSize : 5
    return this.props.tournamentSite.tournament.isTwoManTeamFormat ? 2 : defaultTeamSize
  }

  private _renderTeamMembers = () => {
    const { tournamentSite, auth } = this.props
    const { team } = auth
    const maxTeamSize = this._teamSize
    let updatedTeam = team

    if (team.length < tournamentSite.defaultTeamSize) {
      const emptyFields = []

      for (let i = 0; i < tournamentSite.defaultTeamSize - team.length; i++) {
        emptyFields.push({ firstName: '', lastName: '', hcp: '', email: '' })
      }
      updatedTeam = team.concat(emptyFields)
    }

    if (this._isPlayerRegistered()) {
      if (tournamentSite.partialSignupEnabled && updatedTeam.length !== 0) {
        const teamPlayers = updatedTeam.map((player: TeamMember, index) => {
          if (player.firstName === '' || team[index]?.removed) {
            return team[index] || player
          }
          return player
        })
        return teamPlayers.map(this._renderTeamMember)
      }
    }

    return updatedTeam.slice(0, maxTeamSize).map(this._renderTeamMember)
  }

  private _findTeamPlayerOrder = (): number => {
    const { userId } = this.props.auth.user
    const { players, reserveList, teams } = this.props.tournamentPlayers
    const currentPlayerTeamId =
      players.find((player) => player.userId === Number(userId))?.team?.id ||
      reserveList.find((player) => player.userId === Number(userId))?.team?.id ||
      false

    return teams
      .find((team) => team.id === currentPlayerTeamId)
      ?.players.find((player) => player.userId === Number(userId)).teamPlayerOrder
  }

  private _isTeamCreator = (): boolean => {
    return isTeamCreator(this.props.tournamentPlayers.teams, this.props.auth.user)
  }

  private _renderTeamMember = (teamMember: TeamMember, memberIndex: number) => {
    return (
      <TeamMemberRow
        key={`TeamMemberRow_${memberIndex}`}
        index={memberIndex}
        teamMember={teamMember}
        isPlayerRegistered={this._isPlayerRegistered()}
        teamPlayerOrder={this._findTeamPlayerOrder()}
        isValid={this.state.valid}
      />
    )
  }

  private _isPlayerRegistered = (): boolean => {
    const { players, reserveList } = this.props.tournamentPlayers
    const { userId } = this.props.auth.user

    const isPlayerFound =
      // Search players list
      !!players.find((player) => player.userId === Number(userId)) ||
      // Search reserve list
      !!reserveList.find((player) => player.userId === Number(userId)) ||
      false

    return isPlayerFound
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (store) => ({
      auth: store.authenticationReducer,
      searchLoading: store.tournamentReducer.searchLoading,
      tournamentSite: store.tournamentReducer.tournamentSite,
      termsAndConditions: store.tournamentReducer.tournamentSite.termsAndConditionsUrl,
      registryPlayerSearchResult: store.tournamentReducer.registryPlayerSearchResult,
      tournamentPlayers: store.tournamentPlayersReducer,
      registration: store.registrationsReducer.registration,
    }),
    {
      updateUserField,
      editEntryTeamMember,
      searchPlayers,
      clearSearch,
    },
  )(TeamTournamentEntryForm),
)
