import { TournamentTypes } from './enums'

export const signUpEnabled = (tournamentSite: TournamentSite): boolean => {
  if (!tournamentSite.onlineEntriesEnabled) {
    return false
  }
  /* round.status.isScoringDisabled !== undefined check is because weekly doesn't have
    isScoringDisabled status */
  const isScoringEnabled = tournamentSite.tournament.rounds.some(
    (round) => round.status.isScoringDisabled !== undefined && round.status.isScoringDisabled === false,
  )
  const isRoundCompleted = tournamentSite.tournament.rounds.some((round) => round.status.isCompleted === true)

  return (!isScoringEnabled && !isRoundCompleted) || tournamentSite.tournament.tournamentType === TournamentTypes.weekly
}
