import React from 'react'
import { CircularProgress } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { CircularProgressProps } from '@mui/material/CircularProgress'
import { Stack } from '@mui/system'

const styles = () =>
  createStyles({
    root: {
      position: 'fixed',
      zIndex: 2000,
      backgroundColor: 'rgba(255, 255, 255, 0.96)',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  progressProps?: CircularProgressProps
}

const OverlayLoader = (props: Props) => {
  const { children, progressProps = {}, classes } = props

  return (
    <div className={classes.root}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
        <img src="/images/GGB-Landscape.png" alt="GGB" />
        <CircularProgress size={64} thickness={2} {...progressProps} />
      </Stack>
      {children}
    </div>
  )
}

export default withStyles(styles)(OverlayLoader)
