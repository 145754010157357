import moment from 'moment'

const metricDefaultDateFormat = 'D.M.YYYY'
const metricDefaultTimeFormat = 'HH:mm'
const usDefaultDateFormat = 'MM/DD/YYYY'
const usDefaultTimeFormat = 'hh:mm A'
const defaultDateFormat = (unit: 'metric' | 'us') => (unit === 'metric' ? metricDefaultDateFormat : usDefaultDateFormat)
const defaultTimeFormat = (unit: 'metric' | 'us') => (unit === 'metric' ? metricDefaultTimeFormat : usDefaultTimeFormat)
const defaultDateTimeFormat = (unit: 'metric' | 'us') => `${defaultDateFormat(unit)} ${defaultTimeFormat(unit)}`

export const defaultFormat = (part: 'date' | 'time' | 'both', unit: 'metric' | 'us') => {
  switch (part) {
    case 'date':
      return defaultDateFormat(unit)
    case 'time':
      return defaultTimeFormat(unit)
    case 'both':
      return defaultDateTimeFormat(unit)
  }
}

export const formatScheduleTime = (hours: number, minutes: number, units: 'metric' | 'us'): string | undefined => {
  if (hours >= 0 && minutes >= 0) {
    if (units === 'us') {
      return moment({ hours, minutes }).format('hh:mm A')
    }

    return moment({ hours, minutes }).format('HH:mm')
  }
  return undefined
}

export const formatLegacyScheduleTime = (time: string, units: 'metric' | 'us'): string => {
  return moment(time).format(defaultFormat('time', units))
}
