export const intervalTime = 500
export const timeoutTime = 10000

export enum PaymentQueryParams {
  TRANSACTION_ID = 'transactionId',
  RESPONSE_CODE = 'responseCode',
}

export enum PaymentResponseCode {
  OK = 'OK',
}

export const getPaymentReturnUrl = (id: number) => '/tournament/' + id

/**
 * Converts currency minor units to major units as a string
 */
export const currencyFractionsToMains = (value: number, minorDivider: number): string => {
  const convertedValue = Number(value / minorDivider)
  return minorDivider === 1 ? String(convertedValue) : convertedValue.toFixed(2)
}
