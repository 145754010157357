import React from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Theme, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { loadTournamentLeaderboard } from '../../store/tournamentLeaderboard/actions'
import { FormattedMessage } from 'react-intl'
import { isTeamFormatId } from '../../utils/tournamentUtils'
import TournamentTableCell from '../table/TournamentTableCell'
import TournamentTableRow from '../table/TournamentTableRow'
import classNames from 'classnames'
import { rem } from '@app/theme/materialUITheme'
import { PlayerStatus } from '@app/utils/enums'

const styles = (theme: Theme) =>
  createStyles({
    flag: {
      display: 'block',
      margin: '0 auto',
      width: 24,
    },
    bold: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    negative: {
      color: theme.customPalette.error,
    },
    thead: {
      background: theme.customPalette.mediumGray2,
      boxShadow: `0 0 2px 0 #999`,
    },
    th: {
      color: theme.palette.primary.contrastText,
      fontWeight: 900,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: theme.typography.pxToRem(18),
      background: theme.customPalette.mediumGray2,
      border: 0,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    td: {
      textAlign: 'center',
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    tdName: {
      textAlign: 'left',
      paddingLeft: '16px',
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 3px',
      '& > thead': {
        position: 'sticky',
        top: 0,
        zIndex: 11,
      },
      '& > tbody > tr:first-of-type': {
        position: 'sticky',
        top: 55,
        zIndex: 11,
      },
    },
    borderRadiusFirstCell: {
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
    },
    borderRadiusLastCell: {
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
    },
    cutBreakRow: {
      color: theme.palette.common.white,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: `${rem(20)} !important`,
      fontWeight: 800,
      textAlign: 'center',
      padding: rem(8),
      borderRadius: '3px',
      boxShadow: `0 0 2px 0 #999`,
      border: 0,
    },
    cutBreakTable: {
      backgroundColor: theme.customPalette.ggbDarkGreen,
    },
  })

interface OwnProps extends WithStyles<typeof styles> {
  tournamentId?: number
  gameType: string
  type: string
  scrolling?: boolean
  rollingSpeed: number
}

interface StateProps {
  leaderboard: TournamentLeaderboardState
  tournamentRounds: TournamentRound[]
  showFlags?: boolean
}

interface DispatchProps {
  loadTournamentLeaderboard(id: number): void
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  scoreExpanded?: string
}

class LeaderboardScreen extends React.Component<Props, State> {
  timer = null
  doScroll = true

  constructor(props) {
    super(props)

    this.state = {
      scoreExpanded: undefined,
    }
  }

  public componentDidMount(): void {
    const { tournamentId } = this.props

    // Refresh leaderboard every 30 seconds
    this.timer = setInterval(
      () => this.props.type === 'leaderboard' && this.props.loadTournamentLeaderboard(tournamentId),
      30000,
    )
  }

  public componentWillUnmount(): void {
    clearInterval(this.timer)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { scrolling } = this.props
    const { data } = this.props.leaderboard

    if (!data) {
      return
    }

    if (scrolling && prevProps.scrolling === false) {
      this._startScrolling()
    } else {
      this.doScroll = scrolling
    }
  }

  render() {
    const { gameType, leaderboard, type, classes, tournamentRounds } = this.props
    const { data } = leaderboard

    if (!data || (type === 'results' && tournamentRounds.some((round) => !round.status.isCompleted))) {
      return null
    }

    const { rounds } = data

    const game = gameType === 'side' ? data.secondary : data.primary

    const cutBreakPlayer = game.players.find((player) => player.status && player.status === PlayerStatus.CUT)

    // TODO:PHASE:MULTIROUND Number of hidden holes should be taken from the last round,
    // alternatively this parameter should be moved outside of the rounds array
    const numHolesHidden = tournamentRounds[0]?.hideResultsFromLastHoles

    return (
      <Table aria-label="sticky table" className={classes.table} id="scrollable-target-container">
        <colgroup>
          {/* Position */}
          <col style={{ width: '5%' }} />
          {/* Flag */}
          {this.props.showFlags && !this._isTeamFormat && <col style={{ width: '5%' }} />}
          {/* Name */}
          <col style={{ width: 'auto' }} />
          {/* Today */}
          {rounds.length > 1 && <col style={{ width: '5%' }} />}
          {/* Thru */}
          <col style={{ width: '5%' }} />
          {/* ToPar */}
          <col style={{ width: '5%' }} />
          {/* DIVIDER */}
          <col style={{ width: '1%' }} />
          {/* Rounds */}
          {rounds.length > 1 && rounds.map((_, i) => <col key={i} style={{ width: '5%' }} />)}
          {/* DIVIDER */}
          {rounds.length > 1 && <col style={{ width: '1%' }} />}
          {/* Score */}
          <col style={{ width: '5%' }} />
          {/* DIVIDER */}
          <col style={{ width: '1%' }} />
        </colgroup>
        <TableHead className={classes.thead} id="table-head">
          <TableRow>
            <TableCell className={classNames([classes.th, classes.borderRadiusFirstCell])}>
              <FormattedMessage id="leaderboard.pos" />
            </TableCell>
            {this.props.showFlags && !this._isTeamFormat && (
              <TableCell className={classes.th}>
                <FormattedMessage id="leaderboard.country" />
              </TableCell>
            )}
            <TableCell className={classes.th} style={{ textAlign: 'left' }}>
              <FormattedMessage id="leaderboard.name" />
            </TableCell>
            <TableCell className={classes.th}>
              <FormattedMessage id="leaderboard.toPar" />
            </TableCell>
            <TableCell className={classes.th}>
              <FormattedMessage id="leaderboard.thru" />
            </TableCell>
            {rounds.length > 1 && (
              <TableCell className={classes.th}>
                <FormattedMessage id="leaderboard.today" />
              </TableCell>
            )}
            <TableCell className={classes.th} />
            {rounds.length > 1 &&
              rounds.map((_, index) => (
                <TableCell className={classes.th} key={index}>
                  <FormattedMessage id="leaderboard.round" values={{ round: index + 1 }} />
                </TableCell>
              ))}
            {rounds.length > 1 && <TableCell className={classes.th} style={{ paddingLeft: 0 }} />}
            <TableCell className={classes.th}>
              <FormattedMessage id="leaderboard.score" />
            </TableCell>
            <TableCell className={classNames([classes.th, classes.borderRadiusLastCell])} />
          </TableRow>
        </TableHead>
        <TableBody>
          {game.players.map((player, index) => this._renderRow(player, index, numHolesHidden, cutBreakPlayer))}
        </TableBody>
      </Table>
    )
  }

  private get _isTeamFormat() {
    const { gameType, leaderboard } = this.props
    const { data } = leaderboard
    if (!data) {
      return false
    }

    const gameFormatId = gameType && gameType === 'side' ? data.secondary.id : data.primary.id
    return isTeamFormatId(gameFormatId)
  }

  private _renderRow = (
    player: TournamentLeaderboardPlayer,
    index: number,
    numHolesHidden: number,
    cutBreakPlayer?: TournamentLeaderboardPlayer,
  ) => {
    const { showFlags, classes, leaderboard } = this.props
    const { rounds } = leaderboard.data

    const id = player.userId || player.teamId
    const STATIC_COL_COUNT = 7
    const flagCol = showFlags && !this._isTeamFormat ? 1 : 0
    const multiroundCols = rounds.length > 1 ? 2 : 0
    const multiroundRounds = rounds.length > 1 ? rounds.length : 0
    const numberOfCols = STATIC_COL_COUNT + flagCol + multiroundCols + multiroundRounds
    const applyCutIndicator = cutBreakPlayer && id === cutBreakPlayer.userId

    return (
      <React.Fragment key={id}>
        {applyCutIndicator && (
          <TableRow className={classes.cutBreakTable}>
            <TableCell className={classes.cutBreakRow} colSpan={numberOfCols}>
              <FormattedMessage id="leaderboard.missedCut" />
            </TableCell>
          </TableRow>
        )}
        <TournamentTableRow
          withBg={index % 2 !== 0}
          style={{ boxShadow: `0 0 2px 0 #999` }}
          id={index === 0 ? 'leader' : undefined}
        >
          <TournamentTableCell screen className={classNames([classes.td, classes.borderRadiusFirstCell])}>
            {player.position.toUpperCase()}
          </TournamentTableCell>
          {showFlags && !this._isTeamFormat && (
            <TournamentTableCell screen className={classes.td}>
              {player.countryCode && (
                <img
                  src={`//static.golfgamebook.com/flags/${player.countryCode}.png`}
                  alt={player.countryCode}
                  className={classes.flag}
                />
              )}
            </TournamentTableCell>
          )}
          <TournamentTableCell screen className={classNames([classes.td, classes.tdName])}>
            {player.name}
          </TournamentTableCell>
          <TournamentTableCell screen className={classes.td}>
            {this._formatTextColor(player.toPar)}
          </TournamentTableCell>
          <TournamentTableCell screen className={classes.td} style={{ fontWeight: 'normal' }}>
            {player.thruToday}
          </TournamentTableCell>
          {rounds.length > 1 && (
            <TournamentTableCell screen className={classes.td}>
              {this._formatTextColor(player.toParToday)}
            </TournamentTableCell>
          )}
          <TournamentTableCell screen className={classes.td} />
          {rounds.length > 1 &&
            player.rounds.map((round) => (
              <TournamentTableCell
                className={classes.td}
                style={{ fontWeight: 'normal' }}
                key={`player-${id}-round-${round.id}`}
                screen
              >
                {round.scoreTotal}
              </TournamentTableCell>
            ))}
          {rounds.length > 1 && <TournamentTableCell screen className={classes.td} />}
          <TournamentTableCell screen className={classes.td}>
            {numHolesHidden === 0 ? player.score : <VisibilityOffIcon />}
          </TournamentTableCell>
          <TournamentTableCell screen className={classNames([classes.td, classes.borderRadiusLastCell])} />
        </TournamentTableRow>
      </React.Fragment>
    )
  }

  private _formatTextColor = (text: string) => {
    const coloredTextStyle = Number(text) < 0 ? { color: '#ff0a0a' } : {}
    return <span style={coloredTextStyle}>{text}</span>
  }

  private _startScrolling = () => {
    this.doScroll = true
    const container = document.getElementById('scrollable-container')
    const maxOffset = container.scrollHeight - container.clientHeight

    container.scrollTo(0, 0)

    let heightOffset = 0
    let scrollStarted = false
    let reachedBottom = false
    let intervalBase = 1000
    let behavior: ScrollBehavior = 'smooth'

    // Map: (fps, pixels to scroll per frame)
    const scrollingSpeedHeightMap = new Map<number, number>()
    scrollingSpeedHeightMap.set(20, 1)
    scrollingSpeedHeightMap.set(40, 1)
    scrollingSpeedHeightMap.set(60, 1.5)

    const animate = () => {
      if (heightOffset < maxOffset) {
        // Keep the scroll stopped for two seconds when we are at the top of the leaderboard
        const twoSeconds = 2000
        intervalBase = heightOffset === 0 && !scrollStarted ? this.props.rollingSpeed * twoSeconds : 1000
        heightOffset += scrollingSpeedHeightMap.get(this.props.rollingSpeed)
        scrollStarted = true
      }

      if (heightOffset >= maxOffset) {
        if (!reachedBottom) {
          reachedBottom = true
          // Keep the scroll stopped for one second when we reached the end of the leaderboard
          const oneSecond = 1000
          intervalBase = this.props.rollingSpeed * oneSecond
        } else {
          heightOffset = 0
          behavior = 'auto'
          reachedBottom = false
        }
      }

      container.scrollTo({ top: heightOffset, behavior })

      if (this.doScroll) {
        setTimeout(() => {
          requestAnimationFrame(animate)
        }, intervalBase / this.props.rollingSpeed)
      }
    }

    if (maxOffset > 0) {
      animate()
    }
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (state) => ({
      leaderboard: state.tournamentLeaderboardReducer,
      tournamentRounds: state.tournamentReducer.tournamentSite?.tournament.rounds,
      showFlags: state.tournamentReducer.tournamentSite?.countryFlagForPlayersEnabled,
    }),
    { loadTournamentLeaderboard },
  )(LeaderboardScreen),
)
