import { Select, MenuItem } from '@mui/material'
import { FiFlag, EnFlag, SvFlag, EsFlag } from '../../assets/images'
import { useLanguage } from '@app/hooks/useLanguage'
import { SupportedLanguageCode } from '@app/localization'

export const LanguageSwitcher = () => {
  const { languageCode: language, setLanguageCode } = useLanguage()

  const onLanguageCodeChange = (event: { target: { value: string } }) => {
    setLanguageCode(event.target.value)
  }

  const options: { value: SupportedLanguageCode; flagImg: any }[] = [
    {
      value: 'en-US',
      flagImg: EnFlag,
    },
    {
      value: 'fi-FI',
      flagImg: FiFlag,
    },
    {
      value: 'fi-SV',
      flagImg: SvFlag,
    },
    {
      value: 'es-ES',
      flagImg: EsFlag,
    },
  ]

  return (
    <Select
      id="language-switcher"
      value={language}
      sx={{ '& fieldset': { border: 0 }, '& .MuiSvgIcon-root': { color: 'white' } }}
      onChange={onLanguageCodeChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <img src={option.flagImg} style={{ width: 30, padding: '5px 5px 0 5px' }} alt={option.value} />
        </MenuItem>
      ))}
    </Select>
  )
}
