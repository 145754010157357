import { connect } from 'react-redux'
import { useEffect } from 'react'
import { Theme, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import makeStyles from '@mui/styles/makeStyles'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '50px 0',
    width: '100%',

    '& .pswp-gallery': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
      },

      '& a': {
        display: 'block',
        height: '120px',
        position: 'relative',
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
          height: '170px',
        },

        '& img': {
          display: 'block',
          transition: 'transform 1s',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },

        '&:hover img': {
          transform: 'scale(1.05)',
        },
      },
    },
  },
}))

type Props = {
  tournamentSite?: TournamentSite
}

const SummaryPictures = ({ tournamentSite }: Props) => {
  const classes = useStyles()
  if (!tournamentSite) {
    return null
  }

  const { images } = tournamentSite

  if (!images.summaryPictures || !images.summaryPictures.length) {
    return null
  }

  const sortedImages = images.summaryPictures.slice().sort((prev, next) => {
    if (!prev.order) {
      return 1
    }
    if (prev.order < next.order) {
      return -1
    }
    return 0
  })

  useEffect(() => {
    // Since we don't get image dimensions from the API, we wait for them to load and set the dimensions afterwards.
    // This is necessary for PhotoSwipe to work correctly.
    // TODO:PHASE:TM-4497: Add srcset support when we get responsive images from the API
    const galleryLinks = document.querySelectorAll('.pswp-gallery > a')
    galleryLinks.forEach((element) => {
      let image = new Image()
      image.src = element.getAttribute('href')
      image.onload = () => {
        // Wait for image to load so we know the dimensions
        element.setAttribute('data-pswp-width', `${image.naturalWidth}`)
        element.setAttribute('data-pswp-height', `${image.naturalHeight}`)
        image = null // Cleanup
      }
    })

    // PhotoSwipe JS starts loading only after the user clicks on a thumbnail
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#summaryPictures',
      children: 'a',
      pswpModule: () => import('photoswipe'),
    })
    lightbox.init()

    return () => {
      lightbox.destroy()
      lightbox = null
    }
  }, [])

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h2" style={{ marginBottom: 22 }}>
          <FormattedMessage id="strings.gallery" />
        </Typography>
      </div>

      <div className="pswp-gallery" id="summaryPictures">
        {sortedImages.map((image, index) => (
          <a href={image.url} key={'galleryImage-' + index} target="_blank" rel="noreferrer">
            <img src={image.previewUrl || image.url} alt="" />
          </a>
        ))}
      </div>
    </div>
  )
}

export default connect((state: StoreState) => ({
  tournamentSite: state.tournamentReducer.tournamentSite,
}))(SummaryPictures)
