import React, { CSSProperties } from 'react'
import { FormControl, MenuItem, Select, TextField, Theme, Typography, Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { updateQuestionAnswer, UpdateQuestionAnswerPayload } from '../../store/authentication/actions'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { AnswerTypes } from '../../utils/enums'
import { hasMustAnswerQuestions } from '../../utils/customQuestionUtils'
import { isTeamCreator } from '@app/utils/playerUtils'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    questionRow: {
      marginBottom: theme.spacing(2),
    },
    questionTextArea: {
      'MuiInputBase-root': {
        padding: '7.5px 14px',
      },
    },
    questionTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
    },
    requiredWrapper: {
      marginBottom: theme.spacing(2),
    },
    baseSelect: {
      minWidth: 100,
      height: 35,
    },
    selectEmpty: {
      '& > div:first-of-type': {
        opacity: 0.5,
      },
    },
  })

interface OwnProps {
  style?: CSSProperties
  errors?: { [property: string]: boolean }
  questionAnswers?: RegistrationQuestion[]
  handleUpdateAnswer?(payload: UpdateQuestionAnswerPayload): void
}

interface StateProps {
  auth: AuthenticationState
  customQuestions: CustomQuestion[]
  tournamentCustomQuestions: Question[]
  tournamentPlayers: TournamentPlayersState
}

interface DispatchProps {
  updateQuestionAnswer(payload: UpdateQuestionAnswerPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps<'intl'>

class CustomQuestions extends React.Component<Props> {
  render() {
    const { classes, style } = this.props

    if (this._answers.length === 0 && this.props.tournamentCustomQuestions.length === 0) {
      return null
    }

    return (
      <div className={classes.root} style={style}>
        <Typography variant="h2" style={{ marginBottom: 10 }}>
          <FormattedMessage id="strings.questions" />
        </Typography>

        {this._questions}
      </div>
    )
  }

  get _answers() {
    return this.props.questionAnswers
      ? this.props.questionAnswers
      : this.props?.customQuestions || this.props.tournamentCustomQuestions
  }

  get _errors() {
    const { errors = {} } = this.props
    return errors
  }

  get _hasRequiredQuestions() {
    return hasMustAnswerQuestions(this._answers)
  }

  get _questions() {
    const { tournamentCustomQuestions } = this.props

    if (this._answers.length > 0) {
      return this._answers.map(this._renderQuestion)
    }

    if (tournamentCustomQuestions.length > 0) {
      return tournamentCustomQuestions.map(this._renderQuestion)
    }

    return null
  }

  private _renderQuestion = (customQuestion: RegistrationQuestion | Question, idx: number) => {
    const { id, question, mustAnswer } = customQuestion
    const questionsAnswered = this.props.questionAnswers

    const answerType = customQuestion['questionType'] ? customQuestion['questionType'] : customQuestion['answerType']
    const answer = questionsAnswered[id]?.answer || null
    return (
      <Grid key={`${id}-${idx}`} container className={this.props.classes.questionRow}>
        <Grid item xs={12} sm={5.5}>
          <Typography variant={'body2'} className={this.props.classes.questionTitle}>
            {question} {mustAnswer && '*'}
          </Typography>

          {this._renderQuestionResponse(id, answerType, answer)}
        </Grid>
      </Grid>
    )
  }

  private _renderQuestionResponse = (questionId: number, answerType: AnswerTypes, answer?: string) => {
    const updateAnswer = this.props.handleUpdateAnswer ? this.props.handleUpdateAnswer : this.props.updateQuestionAnswer
    const value = answer || ''

    if (answerType === AnswerTypes.YES_NO) {
      return (
        <FormControl error={this._errors[`${questionId}`]}>
          <Select
            key={questionId}
            variant={'outlined'}
            margin={'dense'}
            value={value || ''}
            style={{ marginTop: 8 }}
            onChange={(e) => {
              const { value } = e.target
              updateAnswer({ questionId, answer: value as string })
            }}
            displayEmpty
            className={
              value
                ? this.props.classes.baseSelect
                : `${this.props.classes.baseSelect} ${this.props.classes.selectEmpty}`
            }
            disabled={!isTeamCreator(this.props.tournamentPlayers.teams, this.props.auth.user)}
          >
            <MenuItem value={''}>
              <FormattedMessage id="strings.select" />
            </MenuItem>
            <MenuItem value={'yes'}>
              <FormattedMessage id="strings.yes" />
            </MenuItem>
            <MenuItem value={'no'}>
              <FormattedMessage id="strings.no" />
            </MenuItem>
          </Select>
        </FormControl>
      )
    } else if (answerType === AnswerTypes.FREE_TEXT) {
      return (
        <TextField
          error={this._errors[`${questionId}`]}
          variant={'outlined'}
          margin={'dense'}
          multiline={true}
          maxRows={8}
          className={this.props.classes.questionTextArea}
          fullWidth={true}
          value={value}
          onChange={(e) => {
            const { value } = e.target
            updateAnswer({ questionId, answer: value })
          }}
          placeholder={this.props.intl.formatMessage({ id: 'strings.typeHere' })}
          disabled={!isTeamCreator(this.props.tournamentPlayers.teams, this.props.auth.user)}
        />
      )
    }
  }
}

export default connect<StateProps, DispatchProps, {}, StoreState>(
  (store) => ({
    auth: store.authenticationReducer,
    customQuestions: store.registrationsReducer.registration?.customQuestions,
    tournamentCustomQuestions: store.tournamentReducer.tournamentSite.customQuestions,
    questionAnswers: store.authenticationReducer.questionAnswers,
    tournamentPlayers: store.tournamentPlayersReducer,
  }),
  {
    updateQuestionAnswer,
  },
)(withStyles(styles)(injectIntl(CustomQuestions)))
