import {
  FETCH_TOURNAMENT_PRODUCTS,
  FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
  FETCH_TOURNAMENT_PRODUCTS_FAILURE,
  START_PAYMENT_PROCESS,
  START_PAYMENT_PROCESS_SUCCESS,
  START_PAYMENT_PROCESS_FAILURE,
  FETCH_BOUGHT_PRODUCTS,
  FETCH_BOUGHT_PRODUCTS_SUCCESS,
  FETCH_BOUGHT_PRODUCTS_FAILURE,
  VERIFY_NETS_PAYMENT,
  VERIFY_NETS_PAYMENT_SUCCESS,
  VERIFY_NETS_PAYMENT_FAILURE,
  FETCH_CURRENCIES,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_SUCCESS,
} from './constants'
import {
  FetchTournamentProductsAction,
  FetchTournamentProductsSuccessAction,
  FetchTournamentProductsFailureAction,
  StartPaymentProcessAction,
  StartPaymentProcessSuccessAction,
  StartPaymentProcessFailureAction,
  FetchBoughtAction,
  FetchBoughtSuccessAction,
  FetchBoughtFailureAction,
  VerifyNetsPaymentAction,
  VerifyNetsPaymentSuccessAction,
  VerifyNetsPaymentFailureAction,
  FetchCurrenciesAction,
  FetchCurrenciesSuccessAction,
  FetchCurrenciesFailureAction,
} from './actions'

export const initialState: PaymentState = {
  loading: false,
  error: undefined,
  tournamentProducts: [],
  boughtProducts: [],
  currencies: [],
}

type PaymentAction =
  | FetchTournamentProductsAction
  | FetchTournamentProductsSuccessAction
  | FetchTournamentProductsFailureAction
  | StartPaymentProcessAction
  | StartPaymentProcessSuccessAction
  | StartPaymentProcessFailureAction
  | FetchBoughtAction
  | FetchBoughtSuccessAction
  | FetchBoughtFailureAction
  | VerifyNetsPaymentAction
  | VerifyNetsPaymentSuccessAction
  | VerifyNetsPaymentFailureAction
  | FetchCurrenciesAction
  | FetchCurrenciesSuccessAction
  | FetchCurrenciesFailureAction

export const paymentReducer = (state: PaymentState = initialState, action: PaymentAction): PaymentState => {
  switch (action.type) {
    case FETCH_BOUGHT_PRODUCTS:
    case START_PAYMENT_PROCESS:
    case FETCH_TOURNAMENT_PRODUCTS:
    case VERIFY_NETS_PAYMENT:
    case FETCH_CURRENCIES:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case START_PAYMENT_PROCESS_SUCCESS:
    case VERIFY_NETS_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      }

    case FETCH_TOURNAMENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        tournamentProducts: action.tournamentProducts,
        loading: false,
        productsFetched: action.productsFetched,
      }

    case FETCH_BOUGHT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        boughtProducts: action.purchasedTournamentProductIds,
      }

    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.currencies,
      }

    case START_PAYMENT_PROCESS_FAILURE:
    case FETCH_TOURNAMENT_PRODUCTS_FAILURE:
    case FETCH_BOUGHT_PRODUCTS_FAILURE:
    case VERIFY_NETS_PAYMENT_FAILURE:
    case FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}
