import React from 'react'
import { connect } from 'react-redux'
import RegisterDialog from '../dialogs/RegisterDialog'
import { PaymentProcessDialog } from '../dialogs/PaymentProcessDialog'
import Header from './Header'

interface Props {
  renderNav: boolean
  lang: string
  children: React.ReactNode
}

class Container extends React.Component<Props> {
  render() {
    const { children } = this.props
    return (
      <>
        {this.props.renderNav && <Header />}
        {children}
        <div id={'date-picker-container'} />
        <RegisterDialog />
        <PaymentProcessDialog />
      </>
    )
  }
}

export default connect((state: StoreState) => ({
  lang: state.localeReducer.appLanguage.code,
}))(Container)
