/* eslint-disable max-len */

export const GameIcon = ({ style = {}, width = '100%', className = '', height = '100%', viewBox = '0 0 55 55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <path d="M43,14.6,40.7,9.17c-.39.91-.72,1.68-1.06,2.45-.18.42-.27,1-.59,1.21s-.85,0-1.29,0l-2.45-.2c.27-.52.49-1,.71-1.4.63-1.21,1.24-2.43,1.9-3.63a1.58,1.58,0,0,1,1.62-1,3.92,3.92,0,0,1,.87.34c.72-.89,1.16-1,2.3-.55a1,1,0,0,0,.51,0l3-.51L46.38,7l-2.89.55L45.23,11q1.22,2.47,2.42,5a3.82,3.82,0,0,1,.26.74,1.5,1.5,0,0,1-1.49,2c-2.22,0-4.44-.05-6.66-.08-.27,0-.53,0-.85,0,.18,1.14.37,2.25.55,3.36.37,2.27.81,4.54,1.07,6.82a12.92,12.92,0,0,1-.2,3.22c-.24,1.72-.67,3.42-.84,5.14a12.78,12.78,0,0,0,.21,3.26c.5,3.08,1.08,6.14,1.62,9.21a5,5,0,0,1,.06,1.16,1.64,1.64,0,0,1-1.19,1.6,1.76,1.76,0,0,1-2-.55,3.54,3.54,0,0,1-.67-1.36c-.92-3.78-1.84-7.57-2.66-11.37a25.34,25.34,0,0,1-.3-3.6c0-.58-.07-1.16-.1-1.82-.1.09-.16.12-.18.17-.67,1.27-1.36,2.53-2,3.82a2,2,0,0,0-.21,1.06q.56,5.55,1.16,11.1c.19,1.71-.74,2.74-2.41,2.58A1.79,1.79,0,0,1,29.18,51c-.36-2.11-.71-4.22-1-6.34s-.58-4.21-.77-6.33a4.8,4.8,0,0,1,.3-2.12c.85-2.13,1.78-4.24,2.74-6.33a2.73,2.73,0,0,0,.07-2.22c-1.17-3.36-2.27-6.74-3.41-10.11a3,3,0,0,1,.1-2.23,2.1,2.1,0,0,1,1.88-1.37c2.17,0,4.34-.05,6.5.06C38,14.1,40.41,14.38,43,14.6Z" />
    <path d="M36.22,7.57a6.19,6.19,0,0,1-1.82,4.15,3.16,3.16,0,0,1-4.39.36,6.21,6.21,0,0,1-2.58-5.95A4.27,4.27,0,0,1,32.5,2.56C34.9,2.94,36.23,4.58,36.22,7.57Z" />
    <path d="M25.74,9.49l.28,1-3.76.64L10.72,13c-.33.05-.49.18-.52.53-.09,1-.22,2-.33,3.06-.08.71-.3,1-.84,1.15a1.86,1.86,0,0,1-1.84-.53,1.6,1.6,0,0,1-.14-.81c.07-.83.19-1.66.28-2.48.11-1.06.45-1.46,1.48-1.63L19.09,10.6l6-1Z" />
    <path d="M22.45,47.86a1.68,1.68,0,1,1-1.65-1.67A1.66,1.66,0,0,1,22.45,47.86Z" />
    <path d="M20.67,52.47c0-.43-.09-.87-.11-1.3s0-.75-.51-.85c-.08,0-.13-.15-.24-.29h1.84c0,.09-.09.25-.15.26-.53.12-.5.52-.52.91s-.08.85-.11,1.28Z" />
  </svg>
)
