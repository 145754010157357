import React, { CSSProperties, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import LeaderboardTitle from './ui/LeaderboardTitle'
import { LeaderboardPlayers } from './LeaderboardPlayers'
import { LeaderboardStartlists } from './LeaderboardStartlists'
import { formTournamentSiteUrl } from '@app/config'
import { FormattedMessage, useIntl } from 'react-intl'
import { OpenInNew } from '@mui/icons-material'
import { rem, shadeColor } from '@app/theme/materialUITheme'
import { RoundSelect } from './ui/RoundSelect'
import { PlayersSelect } from './ui/PlayersSelect'
import { LeaderboardTabs } from '@app/store/tournamentContestLeaderboard/enums'
import { GameLeaderboard, GameContests } from '@golfgamebook/web-leaderboard'
import '@golfgamebook/web-leaderboard/dist/css/style.css'
import { APIRoute } from '@app/config'
import { getStoredLanguageCode } from '@app/localization'
import { LeaderboardDivisions } from '@app/components/tournament/ui/LeaderboardDivisions'
import { getActiveRoundNumber } from '@app/utils/tournamentUtils'
import { LeaderboardInfo } from './LeaderboardInfo'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.customPalette.background,
  },
  wrapper: {
    overflowX: 'auto',
    boxShadow: '0px 1px 3px 1px rgb(0 0 0 / 15%)',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.customPalette.textColor,
    justifyContent: 'flex-end',
  },
  actionsContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 0 ${rem(20)} 0`,
  },
}))

interface Props {
  style?: CSSProperties
  showOptions?: boolean
  selectedTab?: LeaderboardTabs
}

export const Leaderboards: React.FC<Props> = ({ style, showOptions = false, selectedTab }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'))
  const intl = useIntl()

  const [playersList, updatePlayersList] = useState<string>('entries')

  const { tournamentSite, activeDivisionEndpoint, selectedRoundIndex } = useSelector(
    (store: StoreState) => store.tournamentReducer,
  )
  const { tournament } = tournamentSite
  const { id: tournamentId, resultsPublished } = tournament

  const getSiteColors = () => {
    return { primary: theme.palette.primary.main, secondary: shadeColor(theme.palette.primary.main, -40) }
  }

  const _renderTable = () => {
    let leaderboardUrl = ''
    if (
      activeDivisionEndpoint === '' ||
      selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_PLAYERS ||
      selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_START_TIMES ||
      selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_CONTESTS
    ) {
      leaderboardUrl = APIRoute.GET_LEADERBOARD(tournamentId)
    } else {
      leaderboardUrl = activeDivisionEndpoint
    }

    return (
      <>
        <div className={classes.root}>
          {selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_PLAYERS && (
            <div className={classes.wrapper}>
              <LeaderboardTitle tournamentSite={tournamentSite} />
              <LeaderboardPlayers tournamentId={tournamentId} type={playersList} />
            </div>
          )}
          {selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_START_TIMES && (
            <div className={classes.wrapper}>
              <LeaderboardTitle tournamentSite={tournamentSite} selectedRoundIndex={selectedRoundIndex} />
              <LeaderboardStartlists tournamentId={tournamentId} />
            </div>
          )}
          {(selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_LEADERBOARD ||
            selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_RESULTS) && (
            <GameLeaderboard
              endpoint={leaderboardUrl}
              theme={tournamentSite.colorTheme.toLowerCase()}
              renderFilters={() => <LeaderboardDivisions intl={intl} />}
              showCountryFlags={tournamentSite.countryFlagForPlayersEnabled}
              language={getStoredLanguageCode()}
              refreshInterval={30000}
              hideResults={selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_RESULTS && !resultsPublished}
              colors={getSiteColors()}
              activeRound={getActiveRoundNumber(tournament.rounds)}
            />
          )}
          {selectedTab === LeaderboardTabs.LEADERBOARD_TYPE_CONTESTS && (
            <GameContests
              endpoint={APIRoute.GET_CONTEST_LEADERBOARD(tournamentId)}
              theme={tournamentSite.colorTheme.toLowerCase()}
              language={getStoredLanguageCode()}
              measurementUnit={tournamentSite.units}
              refreshInterval={30000}
              colors={getSiteColors()}
            />
          )}
        </div>
      </>
    )
  }

  const renderViewOptions = () => {
    const { rounds } = tournament
    // TODO:PHASE:MULTIROUND Number of hidden holes should be taken from the last round,
    // alternatively this parameter should be moved outside of the rounds array
    switch (selectedTab) {
      case LeaderboardTabs.LEADERBOARD_TYPE_PLAYERS:
        return <PlayersSelect value={playersList} onChange={(val) => updatePlayersList(val)} />
      case LeaderboardTabs.LEADERBOARD_TYPE_START_TIMES:
        return rounds.length > 1 ? <RoundSelect /> : null
      default:
        return null
    }
  }

  return (
    <>
      <LeaderboardInfo />
      <Grid container className={classes.actionsContainer} style={{ padding: !showOptions && 0 }}>
        {showOptions && (
          <Grid item xs={9}>
            {renderViewOptions()}
          </Grid>
        )}
        {!mediaQuery && (
          <Grid item style={{ paddingBottom: 10 }}>
            <Typography variant={'body1'}>
              <a
                href={`${formTournamentSiteUrl(tournament.id)}/screen`}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="leaderboard.openTVLeaderboard" />
                <OpenInNew color="primary" fontSize={'small'} style={{ marginLeft: 10 }} />
              </a>
            </Typography>
          </Grid>
        )}
      </Grid>

      <div className={classes.root} style={style}>
        {_renderTable()}
      </div>
    </>
  )
}
