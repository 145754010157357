import React from 'react'
import { useLocation } from 'react-router-dom'
import { PaymentQueryParams } from '@app/utils/paymentUtils'

export const usePaymentState = () => {
  const { search } = useLocation()
  const urlParams = React.useMemo(() => new URLSearchParams(search), [search])

  return {
    transactionId: urlParams.get(PaymentQueryParams.TRANSACTION_ID),
    responseCode: urlParams.get(PaymentQueryParams.RESPONSE_CODE)?.toUpperCase(),
  }
}
