import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography, Checkbox, Tooltip, Theme, SelectChangeEvent, MenuItem, Autocomplete } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import TourButton from './TourButton'
import { registerAndLogin } from '../../store/authentication/actions'
import CountryPicker from './CountryPicker'
import CountryStatePicker from './CountryStatePicker'
import { localFetchClubs } from '../../store/clubsAndCountries/actions'
import { FormattedMessageWrapper } from './FormattedMessageWrapper'
import TMTextInput from './inputs/TMTextInput'
import TMSelect from './inputs/TMSelect'
import classNames from 'classnames'
import { sanitizeHcpInputValue } from '@app/utils/playerUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSection: {
      padding: 25,
      margin: '0 0 10px 0',
      borderRadius: 16,
      position: 'relative',
      backgroundColor: '#fff',
      boxShadow:
        // eslint-disable-next-line max-len
        '0px 12px 17px 0px rgba(2, 3, 3, 0.03), 0px 5px 22px 0px rgba(2, 3, 3, 0.02), 0px 7px 8px 0px rgba(2, 3, 3, 0.04)',
    },
    mainTitle: {
      marginBottom: 10,
    },
    flexWrapper: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        gap: '14px',
      },
    },
    autocomplete: {
      display: 'inline-flex',
      width: '100%',
      marginBottom: 25,

      '& .MuiAutocomplete-inputRoot': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },

      '& .MuiAutocomplete-input': {
        padding: '10px 12px !important',
      },
    },
    acceptTerms: {
      display: 'flex',
      alignItems: 'center',
      padding: '25px 18px',

      '@global': {
        'a, a:link': {
          color: theme.palette.primary.main,
        },
      },
    },
    buttonsWrapper: {
      padding: '0 25px 50px',

      '& .MuiButtonBase-root': {
        marginBottom: 10,
      },

      [theme.breakpoints.up('md')]: {
        padding: '0 30px',
        flexDirection: 'row-reverse',
      },
    },
  }),
)

interface OwnProps {
  onComplete: () => void
  onCancel: () => void
}

interface StateProps {
  auth: AuthenticationState
  clubs: Club[]
}

interface DispatchProps {
  registerAndLogin(args: RegisterPayload): void
  localFetchClubs: (
    searchTerm: string,
    onComplete: (result: ClubsAndCountriesAPICallResult) => void,
    countryId?: number,
  ) => void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

function RegisterForm({ onComplete, onCancel, auth, clubs, intl, localFetchClubs, registerAndLogin }: Props) {
  const classes = useStyles({})

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [countryId, setCountryId] = useState<number>(undefined)
  const [stateId, setStateId] = useState<number>(undefined)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [club, setClub] = useState<{ label: string; id: number }>({ label: '', id: 0 })
  const [clubSearchTerm, setClubSearchTerm] = useState<string>('')
  const [handicap, setHandicap] = useState<string>('')
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [emailError, setEmailError] = useState(false)

  const genders = ['male', 'female']

  useEffect(() => {
    if (clubSearchTerm && clubSearchTerm.length > 0) {
      localFetchClubs(clubSearchTerm, clubSearchCompleted, countryId)
    }
  }, [clubSearchTerm, localFetchClubs, countryId])

  const clubSearchCompleted = (result: ClubsAndCountriesAPICallResult) => {
    if (result.error) {
      setError(result.error.message)
    }
  }

  const doRegisterAndLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(undefined)

    if (emailError) {
      setError(intl.formatMessage({ id: 'errors.invalidEmailError' }))
      return
    }

    if (!acceptTerms) {
      setError(intl.formatMessage({ id: 'strings.acceptTermsError' }))
      return
    }

    if (password !== passwordConfirm) {
      setError(intl.formatMessage({ id: 'strings.passwordConfirmError' }))
      return
    }

    if (firstName.length > 0 && lastName.length > 0 && countryId && email && password && gender && handicap) {
      registerAndLogin({
        firstName,
        lastName,
        countryId,
        stateId,
        email,
        password,
        gender,
        clubId: club ? club.id : null,
        handicap,
        onComplete,
      })
    } else {
      setError(intl.formatMessage({ id: 'strings.fillRequiredFieldsError' }))
    }
  }

  const clubItems = () => {
    if (!clubs) return []
    return clubs.map((club) => ({
      id: club.id,
      label: club.name,
    }))
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
    return re.test(email)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    const valid = event.target.value.length ? validateEmail(event.target.value) : true
    setEmailError(!valid)
  }

  return (
    <form name={'ggb-register'} onSubmit={doRegisterAndLogin}>
      <div className={classes.formSection}>
        <Typography variant="h4" className={classes.mainTitle}>
          <FormattedMessage id="strings.playerDetails" />
        </Typography>

        <div className={classes.flexWrapper}>
          <TMTextInput
            style={{ marginBottom: 25 }}
            label={<FormattedMessage id={'strings.firstName'} />}
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />

          <TMTextInput
            style={{ marginBottom: 25 }}
            label={<FormattedMessage id={'strings.lastName'} />}
            name={'lastName'}
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        <div className={classes.flexWrapper}>
          <CountryPicker
            style={{ marginBottom: 25 }}
            required={true}
            countryId={countryId}
            onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
              setCountryId(parseInt(e.target.value))
            }
            handleStatesFetch={true}
          />
          <CountryStatePicker
            style={{ marginBottom: 25 }}
            hideIfEmpty={true}
            required={false}
            stateId={stateId}
            onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
              setStateId(parseInt(e.target.value))
            }
          />

          <TMSelect
            style={{ marginBottom: 25 }}
            label={<FormattedMessage id={'strings.selectYourGender'} />}
            name="gender"
            id="gender"
            onChange={(e: SelectChangeEvent) => setGender(e.target.value)}
            defaultValue=""
            displayEmpty
            required
          >
            {genders.map((g: string, i: number) => (
              <MenuItem value={g} key={i}>
                <FormattedMessage id={`strings.${g}`} />
              </MenuItem>
            ))}
          </TMSelect>
        </div>

        <TMTextInput
          style={{ marginBottom: 25 }}
          label={<FormattedMessage id={'strings.email'} />}
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
          error={emailError}
        />

        <div className={classes.flexWrapper}>
          <TMTextInput
            style={{ marginBottom: 25 }}
            label={<FormattedMessage id={'strings.password'} />}
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <TMTextInput
            label={<FormattedMessage id={'strings.reEnterPassword'} />}
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            required
          />
        </div>
      </div>

      <div className={classes.formSection}>
        <Typography variant="h4" className={classes.mainTitle}>
          <FormattedMessage id="strings.yourGolfDetails" />
        </Typography>

        <div className={classes.flexWrapper}>
          <Tooltip
            disableTouchListener={!!countryId}
            disableHoverListener={!!countryId}
            disableFocusListener={!!countryId}
            title={<FormattedMessage id="strings.chooseCountryFirst" />}
          >
            <Autocomplete
              className={classes.autocomplete}
              disablePortal
              options={clubItems()}
              value={club}
              renderInput={(params) => <TMTextInput {...params} label={<FormattedMessage id="strings.homeClub" />} />}
              renderOption={(props, option) => (
                <span {...props} key={option.id}>
                  {option.label}
                </span>
              )}
              onChange={(e, val) => setClub(val)}
              onInputChange={(e, val) => setClubSearchTerm(val)}
              disabled={!countryId}
              noOptionsText={<FormattedMessage id="strings.enterSearchTerm" />}
            />
          </Tooltip>

          <TMTextInput
            label={<FormattedMessage id={'strings.handicap'} />}
            id="handicap"
            name="handicap"
            value={handicap}
            inputProps={{ inputMode: 'numeric' }}
            onChange={(e) => {
              setHandicap(sanitizeHcpInputValue(e.target.value))
            }}
            required
          />
        </div>
      </div>

      <Typography variant={'body1'} className={classes.acceptTerms}>
        <Checkbox
          id="acceptTerms"
          name="acceptTerms"
          color="primary"
          required
          checked={acceptTerms}
          onChange={() => setAcceptTerms(!acceptTerms)}
        />
        <FormattedMessageWrapper id={'strings.registerTerms'} hasLink />
      </Typography>

      {(error || auth.error) && (
        <Typography variant={'body1'} color={'error'} style={{ marginBottom: 30, textAlign: 'center' }}>
          {error && error}
          {auth.error && auth.error}
        </Typography>
      )}

      <div className={classNames([classes.flexWrapper, classes.buttonsWrapper])}>
        <TourButton
          loading={auth.loading}
          buttonProps={{
            type: 'submit',
            style: {
              width: '100%',
            },
          }}
        >
          <FormattedMessage id={'buttons.register'} />
        </TourButton>

        <TourButton
          color="secondary"
          buttonProps={{
            type: 'button',
            onClick: onCancel,
            style: {
              width: '100%',
            },
          }}
        >
          <FormattedMessage id={'buttons.cancel'} />
        </TourButton>
      </div>
    </form>
  )
}

export default injectIntl(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (state) => ({
      auth: state.authenticationReducer,
      clubs: state.clubsAndCountriesReducer.clubs,
    }),
    {
      registerAndLogin,
      localFetchClubs,
    },
  )(RegisterForm),
)
