import axios from 'axios'
import { APIRoute } from '../../../config'
import { put } from 'redux-saga/effects'
import { LoadTournamentPlayersAction } from '../actions'
import { TOURNAMENT_SET_PLAYER_POOL, TOURNAMENT_SET_RESERVE_LIST, TOURNAMENT_LOAD_PLAYERS_FAILURE } from '../constants'

function* doFetchPlayers({ id }: LoadTournamentPlayersAction) {
  try {
    const res = yield axios.get(APIRoute.GET_PLAYERS(id))

    if (res && !res.error) {
      yield put({
        type: TOURNAMENT_SET_PLAYER_POOL,
        players: res.data.players,
      })

      yield put({
        type: TOURNAMENT_SET_RESERVE_LIST,
        reserveList: res.data.reserveList,
      })
    } else {
      yield put({
        type: TOURNAMENT_LOAD_PLAYERS_FAILURE,
        error: res.error,
      })
    }
  } catch (e) {
    console.log(`Player ${id} requested - ${e}`)
    yield put({
      type: TOURNAMENT_LOAD_PLAYERS_FAILURE,
      error: e,
    })
  }
}

export default doFetchPlayers
