import React from 'react'
import { Typography, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { shadeColor } from '@app/theme/materialUITheme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    padding: '20px',
    borderRadius: '3px 3px 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundImage: `linear-gradient(to right,  ${theme.palette.primary.main} 0%,${shadeColor(
      theme.palette.primary.main,
      -40,
    )} 100%)`, // eslint-disable-line no-console
    filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${
      theme.palette.primary.main
    }', endColorstr='${shadeColor(theme.palette.primary.main, -40)}',GradientType=1 )`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginBottom: 0,
  },
  info: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  logo: {
    maxHeight: 70,
  },
}))

function RankingLeaderboardTitle(props: { ranking: Ranking }) {
  const classes = useStyles()
  const { ranking } = props

  return (
    <div className={classes.root}>
      {ranking.logo && ranking.logo.url && <img src={ranking.logo.url} className={classes.logo} alt="" />}
      <Typography variant="h2" className={classes.title}>
        {ranking.name}
      </Typography>
    </div>
  )
}

export default RankingLeaderboardTitle
