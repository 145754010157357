import { TextField, TextFieldProps, Theme } from '@mui/material'
import FormControl from '@mui/material/FormControl/FormControl'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

/**
 * A wrapper for our modified MUI Input component.
 * Has a label on top of an outlined text field, and an optional helper text.
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      transform: 'none',
      padding: '0',
      position: 'relative',
      color: '#373D40',
      letterSpacing: '0.28px',
      marginBottom: '6px',

      [theme.breakpoints.up('sm')]: {},
    },
    input: {
      '& .MuiInputBase-input': {
        padding: '10px 12px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#373D40',
      },
    },
    helperText: {
      margin: '-20px 0 0',
    },
  }),
)

type Props = TextFieldProps & { label: string | React.ReactNode; helperText?: string | React.ReactNode }

const TMTextInput = (props: Props) => {
  const { label, required, helperText, ...TextFieldProps } = props
  const classes = useStyles()
  return (
    <FormControl fullWidth required={required}>
      <InputLabel className={classes.label} disableAnimation htmlFor={props.id} shrink>
        {label}
      </InputLabel>
      <TextField {...TextFieldProps} className={classes.input} variant="outlined" />
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default TMTextInput
