import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { SelectBox, SelectBoxMenuItem } from '@app/components/ui/SelectBox'

type PlayersSelectProps = {
  value: string
  onChange: (value: string) => void
}

export const PlayersSelect = ({ value, onChange }: PlayersSelectProps) => {
  const intl = useIntl()
  const reserveListEnabled = useSelector(
    (state: StoreState) => state.tournamentReducer.tournamentSite.reserveListEnabled,
  )
  const { players, reserveList } = useSelector((store: StoreState) => store.tournamentPlayersReducer)

  const items = useMemo(() => {
    const entriesText = intl.formatMessage({ id: 'leaderboard.entries' })
    const entriesLabel = players.length > 0 ? `${entriesText} (${players.length})` : entriesText
    const items: SelectBoxMenuItem[] = [
      {
        id: 'entries',
        label: entriesLabel,
        value: 'entries',
      },
    ]
    if (reserveListEnabled) {
      const reserveListText = intl.formatMessage({ id: 'leaderboard.reserveList' })
      const reserveListLabel = reserveList.length > 0 ? `${reserveListText} (${reserveList.length})` : reserveListText
      items.push({
        id: 'reserveList',
        label: reserveListLabel,
        value: 'reserveList',
      })
    }
    return items
  }, [intl, reserveListEnabled, players.length, reserveList.length])

  return <SelectBox name="playersSelect" items={items} value={value} onChange={onChange} />
}
