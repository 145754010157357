import { all, takeEvery } from 'redux-saga/effects'
import { LEAVE_TOURNAMENT_ACTION, PLAYERS_SEARCH, TOURNAMENT_REQUESTING } from './constants'
import getTournamentFlow from './flows/get-tournament-flow'
import { JOIN_TOURNAMENT_ACTION, EDIT_REGISTRATION_ACTION } from '../authentication/constants'
import joinTournamentFlow from './flows/join-tournament-flow'
import leaveTournamentFlow from './flows/leave-tournament-flow'
import editRegistrationFlow from './flows/edit-registration-flow'
import searchPlayersFlow from './flows/search-players-flow'

export function* watchTournament() {
  yield all([
    takeEvery(TOURNAMENT_REQUESTING, getTournamentFlow),
    takeEvery(JOIN_TOURNAMENT_ACTION, joinTournamentFlow),
    takeEvery(EDIT_REGISTRATION_ACTION, editRegistrationFlow),
    takeEvery(LEAVE_TOURNAMENT_ACTION, leaveTournamentFlow),
    takeEvery(PLAYERS_SEARCH, searchPlayersFlow),
  ])
}
