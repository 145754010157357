import React from 'react'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Theme } from '@mui/material'
import classNames from 'classnames'
import { BackgroundDefault } from '../../assets/images'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      position: 'absolute',
      height: '70vh',
      minHeight: 182,
      width: '100%',
      top: 0,
      [theme.breakpoints.down('sm')]: {
        height: 182,
      },
    },
    background: {
      position: 'absolute',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    backgroundDefault: {
      [theme.retina()]: {
        display: 'none',
      },
    },
    backgroundRetina: {
      display: 'none',
      [theme.retina()]: {
        display: 'block',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  image: Image
}

const Background = (props: Props) => {
  const { classes, image } = props

  let imgUri = BackgroundDefault

  if (image && image.url) {
    imgUri = image.url
  }

  return (
    <div className={classes.root}>
      <div
        className={classNames([classes.background, classes.backgroundDefault])}
        style={{ backgroundImage: `url(${imgUri})` }}
      />
      <div
        className={classNames([classes.background, classes.backgroundRetina])}
        style={{ backgroundImage: `url(${imgUri})` }}
      />
    </div>
  )
}

export default withStyles(styles)(Background)
