import React from 'react'
import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '@app/theme/materialUITheme'
import InfoIcon from '@mui/icons-material/Info'

interface OwnProps {
  numHiddenHoles: number
}

const useStyles = makeStyles((theme: Theme) => ({
  hiddenHolesNoticeContainer: {
    display: 'inline-flex',
    background: 'white',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.customPalette.ggbGreen,
    borderRadius: 9,
    padding: `${rem(7)} ${rem(55)} ${rem(7)} ${rem(12)}`,
    margin: `${rem(-5)} 0 ${rem(5)} 0`,
    alignItems: 'center',
  },
  hiddenHolesInfo: {
    color: theme.customPalette.ggbGreen,
    vertialAlign: 'bottom',
  },
  hiddenHolesText: {
    color: theme.customPalette.ggbGreen,
  },
}))

export const LeaderboardHiddenHolesInfo: React.FC<OwnProps> = ({ numHiddenHoles }) => {
  const classes = useStyles()

  return (
    <div className={classes.hiddenHolesNoticeContainer}>
      <InfoIcon className={classes.hiddenHolesInfo} />
      &nbsp;
      <Typography variant="subtitle2" className={classes.hiddenHolesText}>
        <FormattedMessageWrapper
          id="leaderboard.hiddenHoles"
          values={{
            numHiddenHoles: numHiddenHoles,
          }}
        />
      </Typography>
    </div>
  )
}
