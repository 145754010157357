import {
  CLEAR_SEARCH,
  CLOSE_REGISTER_MODAL,
  LEAVE_TOURNAMENT_ACTION,
  OPEN_REGISTER_MODAL,
  PLAYERS_SEARCH,
  SET_ACTIVE_DIVISION,
  SET_ACTIVE_DIVISION_ENDPOINT,
  TOURNAMENT_ERROR,
  TOURNAMENT_REQUESTING,
  TOURNAMENT_SET_SELECTED_ROUND,
  TOURNAMENT_SUCCESS,
} from './constants'
import { JOIN_TOURNAMENT_ACTION, EDIT_REGISTRATION_ACTION } from '../authentication/constants'

export interface GetTournamentAction extends Action {
  type: 'TOURNAMENT_REQUESTING'
  tournamentId: number
  screen?: boolean
}
export const getTournament = (tournamentId: number, screen?: boolean): GetTournamentAction => ({
  type: TOURNAMENT_REQUESTING,
  tournamentId,
  screen,
})

export interface GetTournamentErrorAction extends Action {
  type: 'TOURNAMENT_ERROR'
  error?: any
}
export const getTournamentError = (error?: any): GetTournamentErrorAction => ({
  type: TOURNAMENT_ERROR,
  error,
})

export interface GetTournamentSuccessAction extends Action {
  type: 'TOURNAMENT_SUCCESS'
  payload: any
}
export const getTournamentSuccess = (payload: any): GetTournamentSuccessAction => ({
  type: TOURNAMENT_SUCCESS,
  payload,
})

export interface OpenRegisterModalAction extends Action {
  type: 'OPEN_REGISTER_MODAL'
}
export const openRegisterModal = (): OpenRegisterModalAction => ({
  type: OPEN_REGISTER_MODAL,
})

export interface CloseRegisterModalAction extends Action {
  type: 'CLOSE_REGISTER_MODAL'
}
export const closeRegisterModal = (): CloseRegisterModalAction => ({
  type: CLOSE_REGISTER_MODAL,
})

export interface JoinTournamentPayload {
  tournamentId: number
  userId: string
  authToken: string
  clientUid: number
  hcp: number
  customQuestions?: CustomQuestionResponse[]
  teamName?: string
  team?: TeamMember[]
  gender?: string
  teebox?: number
  divisionId?: number
  lang?: string
  onComplete(success: boolean, reserveList?: boolean, error?: any): void
}
export interface JoinTournamentAction extends Action, JoinTournamentPayload {
  type: 'JOIN_TOURNAMENT_ACTION'
}
export const joinTournament = (args: JoinTournamentPayload): JoinTournamentAction => ({
  type: JOIN_TOURNAMENT_ACTION,
  ...args,
})

export interface EditRegistrationAction extends Action, JoinTournamentPayload {
  type: 'EDIT_REGISTRATION_ACTION'
}
export const editRegistration = (args: JoinTournamentPayload): EditRegistrationAction => ({
  type: EDIT_REGISTRATION_ACTION,
  ...args,
})

export interface LeaveTournamentPayload {
  tournamentId: number
  userId: string
  authToken: string
  clientUid: number
  lang: string
}
export interface LeaveTournamentAction extends Action, LeaveTournamentPayload {
  type: 'LEAVE_TOURNAMENT_ACTION'
  onComplete(success: boolean, error?: any): void
}

export interface LeaveTournamentSuccessAction extends Action {
  type: 'LEAVE_TOURNAMENT_ACTION_SUCCESS'
}
export const leaveTournament = (args: LeaveTournamentPayload, onComplete): LeaveTournamentAction => ({
  type: LEAVE_TOURNAMENT_ACTION,
  onComplete,
  ...args,
})

/*
 * SET ACTIVE DIVISION
 */

export interface SetActiveDivisionAction extends Action {
  type: 'SET_ACTIVE_DIVISION'
  divisionId: number
}

export const setActiveDivision = (divisionId: number) => {
  return {
    type: SET_ACTIVE_DIVISION,
    divisionId,
  }
}

/*
 * SET ACTIVE DIVISION ENDPOINT
 */

export interface SetActiveDivisionEndpointAction extends Action {
  type: 'SET_ACTIVE_DIVISION_ENDPOINT'
  divisionEndpoint: string
}

export const setActiveDivisionEndpoint = (divisionEndpoint: string) => {
  return {
    type: SET_ACTIVE_DIVISION_ENDPOINT,
    divisionEndpoint,
  }
}

/**
 * Set currently selected round
 */

export interface TournamentSetSelectedRoundAction extends Action {
  type: 'TOURNAMENT_SET_SELECTED_ROUND'
  roundIndex: number
}

export const setSelectedRound = (roundIndex: number): TournamentSetSelectedRoundAction => {
  return {
    type: TOURNAMENT_SET_SELECTED_ROUND,
    roundIndex,
  }
}

/**
 * Search players from registry
 */

export interface SearchPlayersAction extends Action {
  type: 'PLAYERS_SEARCH'
  searchText: string
  onComplete?: () => void
}

export interface SearchPlayersSuccessAction extends Action {
  type: 'PLAYERS_SEARCH_SUCCESS'
  players: RegistryPlayerSearchResult[]
}

export interface SearchPlayersFailureAction extends Action {
  type: 'PLAYERS_SEARCH_FAILURE'
}

export const searchPlayers = (searchText: string, onComplete?: () => void) => ({
  type: PLAYERS_SEARCH,
  searchText,
  onComplete,
})

/**
 * Clear searched players
 */

export interface ClearSearchAction extends Action {
  type: 'CLEAR_SEARCH'
  onComplete?: () => void
}

export const clearSearch = (onComplete?: () => void) => ({
  type: CLEAR_SEARCH,
  onComplete,
})
