import { Select, SelectProps, Theme } from '@mui/material'
import FormControl from '@mui/material/FormControl/FormControl'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

/**
 * A wrapper for our modified MUI Select component.
 * Has a label on top of an outlined select field, and an optional helper text.
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      transform: 'none',
      padding: '0',
      position: 'relative',
      color: '#373D40',
      letterSpacing: '0.28px',
      marginBottom: '6px',

      [theme.breakpoints.up('sm')]: {},
    },
    select: {
      '& .MuiSelect-select': {
        padding: '10px 12px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#373D40',
      },
      '& .MuiSelect-icon': {
        color: '#373D40',
      },
    },
  }),
)

type Props = SelectProps & { label: string | React.ReactNode; helperText?: string | React.ReactNode }

const TMSelect = (props: Props) => {
  const { label, required, helperText, ...SelectProps } = props
  const classes = useStyles()
  return (
    <FormControl fullWidth required={required} variant="outlined">
      <InputLabel className={classes.label} disableAnimation htmlFor={props.id} shrink>
        {label}
      </InputLabel>
      <Select {...SelectProps} className={classes.select} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default TMSelect
