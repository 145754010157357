import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { setSelectedRound } from '../../../store/tournament/actions'
import { SelectBox, SelectBoxMenuItem } from '@app/components/ui/SelectBox'

export const RoundSelect = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedRoundIndex = useSelector((state: StoreState) => state.tournamentReducer.selectedRoundIndex)
  const rounds = useSelector((state: StoreState) => state.tournamentReducer.tournamentSite.tournament.rounds)

  const onChange = (value: number) => {
    dispatch(setSelectedRound(value))
  }

  const items = useMemo(() => {
    return rounds.map<SelectBoxMenuItem>((round, idx) => ({
      label: `${intl.formatMessage({ id: 'strings.round' })} ${round.roundNumber}`,
      id: String(round.id),
      value: idx,
    }))
  }, [intl, rounds])

  return <SelectBox name="roundSelect" items={items} value={selectedRoundIndex} onChange={onChange} />
}
