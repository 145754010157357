import { takeEvery, put, delay, all, select, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { omit } from 'lodash'
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_REGISTER_AND_LOGIN,
  LOGIN_MAP_SUCCESS,
  LOGIN_MAP_FAILURE,
  LOGIN_MAP,
  EDIT_ENTRY_TEAM,
  AUTH_LOGIN_APPLE,
  AUTH_LOGIN_APPLE_FAILURE,
} from './constants'
import { APIRoute } from '../../config'
import { LoginMapAction } from './actions'
import { FETCH_CURRENCIES } from '../payments/constants'
import { FETCH_REGISTRATIONS_SUCCESS } from '../registrations/constants'
import { FetchRegistrationSuccessAction } from '../registrations/actions'
import { getTeam } from './selectors'

const getOrganizationId = (state: StoreState) =>
  state.tournamentReducer.tournamentSite.tournament.tournamentOrganization.id
const getTournamentTeams = (state: StoreState) => state.tournamentPlayersReducer.teams
const getTournament = (state: StoreState) => state.tournamentReducer.tournamentSite.tournament

function* maybeLoadCurrencies() {
  const tournamentSite: TournamentSite = yield select((state: StoreState) => state.tournamentReducer.tournamentSite)
  const currencies = yield select((state: StoreState) => state.paymentReducer.currencies)
  if (tournamentSite.paymentEnabled && currencies.length === 0) {
    yield put({ type: FETCH_CURRENCIES })
  }
}

function* updateTeamPlayers(user: GGBUser) {
  const teams = yield select(getTournamentTeams)
  const team = teams
    ? teams.find((team) => team.players.find((player) => player.userId.toString() === user.userId))
    : null
  const tournament: Tournament = yield select(getTournament)

  if (team) {
    const newTeam = team.players
      .slice()
      .sort((a, b) => a.teamPlayerOrder - b.teamPlayerOrder)
      .map(({ id, firstName, lastName, hcp, email, gender, federations, temporary, club }) => ({
        id,
        firstName,
        lastName,
        hcp,
        email,
        gender,
        federations,
        temporary,
        clubName: club?.name,
      }))

    yield put({
      type: EDIT_ENTRY_TEAM,
      payload: {
        team: newTeam,
      },
    })
    return
  }

  // No registered team yet, add logged in user as first team member
  if (tournament.isTeamFormat) {
    const teamMember: TeamMember = {
      id: Number(user.userId),
      firstName: user.firstName,
      lastName: user.lastName,
      hcp: user.hcp,
      email: user.email,
      gender: user.gender,
      clubName: user.club,
      profileImageUrl: user.avatarUrl,
    }

    yield put({
      type: EDIT_ENTRY_TEAM,
      payload: {
        team: [teamMember],
      },
    })
  }
}

function* updateTeamPlayersEmails({ registration }: FetchRegistrationSuccessAction) {
  const team = yield select(getTeam)

  if (!team) return

  const teamMembersWithEmail = team.map((member: TeamMember) => {
    const registrationMember = registration?.team?.players?.find((player) => player.id === member.id)
    return registrationMember ? { ...member, email: registrationMember.email } : member
  })

  yield put({
    type: EDIT_ENTRY_TEAM,
    payload: {
      team: teamMembersWithEmail,
    },
  })
}

function* doRegisterAndLogin({ payload }: RegisterAndLoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = { ...payload, organizationId: organizationId }

    const res = yield axios.post(APIRoute.POST_CORE_REGISTER_AND_LOGIN(), payloadToSend)

    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
    yield maybeLoadCurrencies()
    payload.onComplete()
  } catch (error) {
    console.log(`Register and login ${payload.email} requested - ${error}`)
    let errorMessage = error.toString()
    if (error.response && error.response.data.error.message) {
      errorMessage = error.response.data.error.message
    }
    yield put({
      type: AUTH_LOGIN_FAILURE,
      error: errorMessage,
    })
  }
}

function* doLogin(action: LoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = {
      username: action.payload.username,
      password: action.payload.password,
      organizationId: organizationId,
      hcpRound: action.payload.hcpRound,
    }

    const res = yield axios.post(APIRoute.POST_CORE_LOGIN(), payloadToSend)

    yield updateTeamPlayers(res.data)
    yield maybeLoadCurrencies()
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
  } catch (error) {
    console.log(`Login ${action.payload.username} requested - ${error}`)
    if (action.payload.onComplete) {
      action.payload.onComplete(error)
    }
    yield put({
      type: AUTH_LOGIN_FAILURE,
      error: error.toString(),
    })
  }
}

function* doAppleLogin(action: AppleLoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = { ...action.payload, organizationId }

    const res = yield axios.post(APIRoute.POST_CORE_LOGIN_APPLE(), payloadToSend)

    yield updateTeamPlayers(res.data)
    yield maybeLoadCurrencies()
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
  } catch (error) {
    console.log(`Apple login failed - ${error}`)

    yield put({
      type: AUTH_LOGIN_APPLE_FAILURE,
      error: error.toString(),
    })
  }
}

function* doLogout() {
  yield delay(1500)
  yield put({
    type: AUTH_LOGOUT_SUCCESS,
  })
}

function* doLoginMap({ ...args }: LoginMapAction) {
  try {
    const res = yield axios.post(APIRoute.POST_LOGIN_MAP(), {
      ...omit(args, ['type']),
    })

    yield put({
      type: LOGIN_MAP_SUCCESS,
      payload: {
        ...res.data,
      },
    })
  } catch (error) {
    console.log(`Login map with user ${args.userId} requested - ${error}`)
    const errorMessage = error.toString()
    yield put({
      type: LOGIN_MAP_FAILURE,
      error: errorMessage,
    })
  }
}

export function* watchAuthentication() {
  yield all([
    takeEvery(AUTH_LOGIN, doLogin),
    takeEvery(AUTH_LOGIN_APPLE, doAppleLogin),
    takeEvery(AUTH_LOGOUT, doLogout),
    takeEvery(AUTH_REGISTER_AND_LOGIN, doRegisterAndLogin),
    takeEvery(LOGIN_MAP, doLoginMap),
    takeLatest(FETCH_REGISTRATIONS_SUCCESS, updateTeamPlayersEmails),
  ])
}
