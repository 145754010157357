import React, { useState } from 'react'
import { connect } from 'react-redux'
import { leaveTournament, LeaveTournamentPayload } from '../../store/tournament/actions'
import { Typography, Paper, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { isCancelRegistrationOpen } from '../../store/tournament/selectors'
import { FormattedMessage } from 'react-intl'
import TourButton from '../ui/TourButton'
import { CancelEntryConfirmation } from './CancelEntryConfirmation'

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

interface OwnProps {
  onSuccess?(): void
}

interface StateProps {
  tournamentId?: number
  auth: AuthenticationState
  registration?: UserRegistration
  isCancelRegistrationOpen: boolean
  locale: LocaleState
}

interface DispatchProps {
  leaveTournament(args: LeaveTournamentPayload, onComplete: (success: boolean) => void): void
}

type Props = OwnProps & StateProps & DispatchProps

function UnregisterFromTournamentAction({
  auth,
  registration,
  tournamentId,
  leaveTournament,
  onSuccess,
  isCancelRegistrationOpen,
  locale,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmation, setConfirmation] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const classes = useStyles()

  if (!registration) return null

  if (!isCancelRegistrationOpen) {
    return null
  }

  const onClick = async () => {
    setConfirmation(true)
  }

  const closeConfirmation = async () => {
    setConfirmation(false)
  }

  const cancelEntry = async () => {
    const {
      user: { authToken, clientUid, userId },
    } = auth

    setLoading(true)
    setConfirmation(false)

    const payload = {
      tournamentId,
      authToken,
      clientUid,
      userId,
      lang: locale.appLanguage.code,
    }

    leaveTournament(payload, (success) => {
      setLoading(false)

      if (!success) {
        setError(true)
      } else if (success) {
        if (onSuccess) onSuccess()
        setError(false)
      }
    })
  }

  return (
    <Paper style={{ padding: 15, margin: '0 0 20px 0' }}>
      <Typography variant="h2" style={{ marginBottom: 10 }}>
        <FormattedMessage id="strings.cancelYourEntry" />
      </Typography>
      <Typography variant={'body1'}>
        <FormattedMessage id={'strings.cancelEntryInfo'} />
      </Typography>
      <TourButton
        loading={loading || false}
        color={'error'}
        buttonProps={{
          style: {
            marginTop: 20,
          },
          onClick: onClick,
          className: classes.cancelButton,
        }}
      >
        <FormattedMessage id={'buttons.cancelEntry'} />
      </TourButton>

      {error && (
        <Typography variant="body1" color="secondary">
          <FormattedMessage id="strings.cancelError" />
        </Typography>
      )}

      <CancelEntryConfirmation open={confirmation} okAction={cancelEntry} cancelAction={closeConfirmation} />
    </Paper>
  )
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  (state) => ({
    auth: state.authenticationReducer,
    tournamentId: get(state, 'tournamentReducer.tournamentSite.tournament.id'),
    isCancelRegistrationOpen: isCancelRegistrationOpen(state),
    registration: state.registrationsReducer.registration,
    locale: state.localeReducer,
  }),
  {
    leaveTournament,
  },
)(UnregisterFromTournamentAction)
