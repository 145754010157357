import React from 'react'
import { Typography, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { shadeColor } from '../../../theme/materialUITheme'
import { defaultFormat } from '../../../utils/date'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    padding: 20,
    borderRadius: '3px 3px 0 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `linear-gradient(to right,  ${theme.palette.primary.main} 0%,${shadeColor(
      theme.palette.primary.main,
      -40,
    )} 100%)`, // eslint-disable-line no-console
    filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${
      theme.palette.primary.main
    }', endColorstr='${shadeColor(theme.palette.primary.main, -40)}',GradientType=1 )`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginBottom: 0,
    fontStyle: 'normal',
  },
  info: {
    textAlign: 'right',
    fontFamily: 'Roboto',
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginTop: 10,
    },
  },
}))

interface Props {
  tournamentSite: TournamentSite
  selectedRoundIndex?: number
}

function LeaderboardTitle({ tournamentSite, selectedRoundIndex }: Props) {
  const classes = useStyles()
  const tournament = tournamentSite.tournament
  const roundIndex = selectedRoundIndex || 0
  const selectedRound = tournament.rounds[roundIndex]
  const par = selectedRound?.course?.par

  const getTournamentDate = (defaultDateFormat: string) => {
    let date = moment(tournament.startTime).format(defaultDateFormat)
    if (tournament.noStartTimes) {
      date = `${moment(tournament.startTime).format(defaultDateFormat)} - ${moment(tournament.endTime).format(
        defaultDateFormat,
      )}`
    }
    return date
  }

  const getRoundDate = (defaultDateFormat: string) => {
    return moment(selectedRound?.startTime).format(defaultDateFormat)
  }

  const getDate = () => {
    const defaultDateFormat = defaultFormat('date', tournamentSite.units)
    const date =
      selectedRoundIndex !== undefined ? getRoundDate(defaultDateFormat) : getTournamentDate(defaultDateFormat)
    return date
  }

  //TODO: Refactor for multiround
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {tournament.name}
      </Typography>
      <Typography variant="subtitle2" className={classes.info}>
        {selectedRound?.club?.name}
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        {selectedRound?.course?.courseName}
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <FormattedMessage id="leaderboard.par" /> {par}
        <br />
        {getDate()}
      </Typography>
    </div>
  )
}

export default LeaderboardTitle
