import {
  FETCH_REGISTRATIONS_FAILURE,
  FETCH_REGISTRATIONS_SUCCESS,
  EDIT_TEAM_NAME,
  ADD_PLAYER_TO_TEAM,
  REMOVE_PLAYER_FROM_TEAM,
  EDIT_TEAM_PLAYER,
} from './constants'
import {
  AddPlayerToTeamAction,
  EditTeamNameAction,
  EditTeamPlayerAction,
  FetchRegistrationFailureAction,
  FetchRegistrationSuccessAction,
  RemovePlayerFromTeamAction,
} from './actions'
import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT } from '../authentication/constants'

export const emptyTeamMember: TeamMember = {
  firstName: '',
  lastName: '',
  hcp: -54,
  email: '',
  gender: '',
}

export const initialState: RegistrationsState = {
  loading: false,
  error: undefined,
  registration: undefined,
  registrations: [],
}

type Actions =
  | LoginSuccessAction
  | LogOutAction
  | FetchRegistrationSuccessAction
  | FetchRegistrationFailureAction
  | EditTeamNameAction
  | EditTeamPlayerAction
  | AddPlayerToTeamAction
  | RemovePlayerFromTeamAction

export const registrationsReducer = (state: RegistrationsState = initialState, action: Actions) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return {
        ...initialState,
      }
    case AUTH_LOGIN_SUCCESS: // Successful login triggers registration fetch
      return {
        ...state,
        loading: true,
      }
    case FETCH_REGISTRATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case FETCH_REGISTRATIONS_SUCCESS:
      const { registration, registrations } = action
      return {
        ...state,
        loading: false,
        registration,
        registrations,
      }
    case EDIT_TEAM_NAME:
      return {
        ...state,
        registration: {
          ...state.registration,
          team: {
            ...state.registration.team,
            name: action.name,
          },
        },
      }
    case ADD_PLAYER_TO_TEAM:
      return {
        ...state,
        registration: {
          ...state.registration,
          team: {
            ...state.registration.team,
          },
        },
      }
    case REMOVE_PLAYER_FROM_TEAM: {
      const pool = [...state.registration.team[action.pool]]
      pool.splice(action.index, 1)

      return {
        ...state,
        registration: {
          ...state.registration,
          team: {
            ...state.registration.team,
            [action.pool]: pool,
          },
        },
      }
    }
    case EDIT_TEAM_PLAYER: {
      const poolItem = { ...state.registration.team[action.pool][action.index] }
      const pool = [
        ...state.registration.team[action.pool].slice(0, action.index),
        {
          ...poolItem,
          [action.fieldName]: action.value,
        },
        ...state.registration.team[action.pool].slice(action.index + 1),
      ]

      return {
        ...state,
        registration: {
          ...state.registration,
          team: {
            ...state.registration.team,
            [action.pool]: pool,
          },
        },
      }
    }
    default:
      return state
  }
}
