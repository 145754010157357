import axios from 'axios'
import { put, select } from 'redux-saga/effects'
import { APIRoute } from '@app/config'
import { SV_COUNTRY_ID } from '@app/utils/tournamentUtils'
import { SearchPlayersAction } from '../actions'
import { PLAYERS_SEARCH_FAILURE, PLAYERS_SEARCH_SUCCESS } from '../constants'

const getTournamentId = (state: StoreState) => state.tournamentReducer.tournamentSite.tournament.id
const getAuth = (state: StoreState) => state.authenticationReducer.user
const getOrganizationCountryId = (state: StoreState) =>
  state.tournamentReducer.tournamentSite?.tournament.tournamentOrganization?.club?.countryId

function* searchPlayersFlow({ searchText, onComplete }: SearchPlayersAction) {
  try {
    const tournamentId: number = yield select(getTournamentId)
    const auth: GGBUser = yield select(getAuth)
    const countryId: number = yield select(getOrganizationCountryId)

    const { authToken, userId, clientUid } = auth

    let searchMethod: PlayerSearch = { searchTerm: searchText }
    if (countryId === SV_COUNTRY_ID) {
      const isGolferId = /^\d{6}-\d{3}$/.test(searchText)
      if (isGolferId) {
        searchMethod = { golferId: searchText }
      } else {
        const [firstName, ...lastNameArray] = searchText.trim().split(' ') as [string, ...string[]]
        const lastName = lastNameArray.join(' ') || ''
        searchMethod = { firstName, lastName }
      }
    }

    const res = yield axios.get(APIRoute.GET_PLAYER_SEARCH_REGISTRY(tournamentId), {
      params: {
        ...searchMethod,
        authToken,
        userId,
        clientUid,
        limit: 8,
      },
    })

    onComplete && onComplete()
    yield put({
      type: PLAYERS_SEARCH_SUCCESS,
      players: res.data?.users || [],
    })
  } catch (error: any) {
    yield put({
      type: PLAYERS_SEARCH_FAILURE,
    })
  }
}

export default searchPlayersFlow
