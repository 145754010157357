import {
  GetTourAction,
  GetTourSuccessAction,
  GetTourFailureAction,
  GetRankingAction,
  GetRankingSuccessAction,
  GetRankingFailureAction,
} from './actions'
import {
  GET_TOUR,
  GET_TOUR_SUCCESS,
  GET_TOUR_FAILURE,
  GET_RANKING,
  GET_RANKING_SUCCESS,
  GET_RANKING_FAILURE,
} from './constants'

export const initialState: TourAndRankingState = {
  isTourLoading: false,
  isRankingLoading: false,
  error: undefined,
  tour: undefined,
  rankings: [],
}

type TourAndRankingAction =
  | GetTourAction
  | GetTourSuccessAction
  | GetTourFailureAction
  | GetRankingAction
  | GetRankingSuccessAction
  | GetRankingFailureAction

export const tourAndRankingReducer = (
  state: TourAndRankingState = initialState,
  action: TourAndRankingAction,
): TourAndRankingState => {
  switch (action.type) {
    case GET_TOUR:
      return {
        ...state,
        isTourLoading: true,
        error: undefined,
      }
    case GET_RANKING:
      return {
        ...state,
        isRankingLoading: true,
        error: undefined,
      }
    case GET_TOUR_SUCCESS:
      return {
        ...state,
        isTourLoading: false,
        error: undefined,
        tour: action.tour,
      }
    case GET_RANKING_SUCCESS:
      return {
        ...state,
        isRankingLoading: false,
        error: undefined,
        rankings: [...state.rankings.filter((r) => r.id !== action.ranking.id), action.ranking],
      }
    case GET_TOUR_FAILURE:
      return {
        ...state,
        isTourLoading: false,
        error: action.error,
      }
    case GET_RANKING_FAILURE:
      return {
        ...state,
        isRankingLoading: false,
        error: action.error,
      }
    default:
      return state
  }
}
