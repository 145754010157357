import axios from 'axios'
import { put } from '@redux-saga/core/effects'
import { APIRoute } from '../../../config'
import { LeaveTournamentAction } from '../actions'
import { LEAVE_TOURNAMENT_ACTION_SUCCESS } from '../constants'

function* leaveTournamentFlow({ tournamentId, userId, authToken, clientUid, lang, onComplete }: LeaveTournamentAction) {
  try {
    yield axios.delete(APIRoute.DELETE_LEAVE_TOURNAMENT(tournamentId), {
      params: {
        userId,
        authToken,
        clientUid,
        lang,
      },
    })
    yield put({ type: LEAVE_TOURNAMENT_ACTION_SUCCESS })

    onComplete(true)
  } catch (e) {
    onComplete(false, e)
  }
}

export default leaveTournamentFlow
