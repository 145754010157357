import Typography from '@mui/material/Typography'
import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import TextButton from '../ui/TextButton'
import Markdown from '../layout/Markdown'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

type Props = {
  tournamentSite?: TournamentSite
}

const SummaryText = ({ tournamentSite }: Props) => {
  const classes = useStyles()

  if (!tournamentSite) {
    return null
  }

  const { tournamentSummaryText, summaryFile } = tournamentSite

  if (!tournamentSummaryText) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.thankYou}>
        <FormattedMessage id="strings.thankYou" />
      </Typography>
      {tournamentSummaryText && <Markdown>{tournamentSummaryText}</Markdown>}

      {summaryFile && (
        <Typography variant="body1" className={classes.summaryFile}>
          <TextButton>
            <a href={summaryFile.url} target="_blank" rel="noreferrer" className={classes.pdfSummaryLink}>
              <FormattedMessage id="strings.seePDFSummary" />
            </a>
          </TextButton>
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    maxWidth: 800,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  thankYou: {
    marginBottom: 15,
  },
  summaryFile: {
    marginTop: 20,
  },
  pdfSummaryLink: {
    color: 'inherit',
  },
}))

export default connect((state: StoreState) => ({
  tournamentSite: state.tournamentReducer.tournamentSite,
}))(SummaryText)
