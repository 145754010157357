import React, { useState } from 'react'
import { Page } from '@app/components/layout/Page'
import { formatDefaultTournamentSeo } from '@app/components/seo/utils'
import { InnerContentWrap } from '@app/components/ui/ContentWrap'
import TournamentTitleBlock from '@app/components/tournament/TournamentTitleBlock'
import OverlayLoader from '@app/components/ui/OverlayLoader'
import Alert from '@app/components/dialogs/Alert'
import { FormattedMessage } from 'react-intl'
import { useAppState } from '@app/hooks'
import { Navigation } from '@app/components/layout/Navigation'
import { TabsEnum } from '@app/utils/enums'

export const TournamentSite = () => {
  const {
    tournamentId,
    tournamentSiteState,
    tournamentState: { isLoading },
  } = useAppState({ autoloadResources: ['tournament'] })

  const [alert, setAlert] = useState<{ open: boolean; title?: string; message?: React.ReactNode }>({
    open: false,
  })
  const [heroImage, setHeroImage] = useState<TournamentFile | undefined>(undefined)

  const closeAlert = () => {
    setAlert({
      open: false,
      message: undefined,
    })

    if (tournamentSiteState && tournamentSiteState.tournament) {
      window.location.href = `/tournament/${tournamentId}`
    }
  }

  if (!tournamentSiteState || isLoading) {
    return <OverlayLoader />
  }

  const { images } = tournamentSiteState

  const syncCurrentTab = (tab: TabsEnum): void => {
    if (tab === TabsEnum.SUMMARY) {
      setHeroImage(images.summaryHeroImage)
    } else if (heroImage) {
      setHeroImage(undefined)
    }
  }

  const getHeroImage = (): TournamentFile => {
    return heroImage ? heroImage : images.heroImage
  }

  return (
    <Page seo={formatDefaultTournamentSeo({ tournamentSite: tournamentSiteState })} hero={getHeroImage()}>
      <InnerContentWrap>
        <TournamentTitleBlock />
      </InnerContentWrap>

      <InnerContentWrap>
        <Navigation isTour={false} syncCurrentTab={syncCurrentTab} />
      </InnerContentWrap>

      <Alert
        open={alert.open}
        title={alert.title || <FormattedMessage id="strings.error" />}
        text={alert.message}
        showCancel={false}
        okAction={closeAlert}
      />
    </Page>
  )
}
