import Typography from '@mui/material/Typography'
import React from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Markdown from '../layout/Markdown'
import { rem } from '../../theme/materialUITheme'

type Props = {
  tour?: Tour
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.customPalette.lightGray2}`,
    padding: '25px 50px',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
      '& p': {
        paddingBottom: 30,
      },
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
}))

const TourInfo = ({ tour }: Props) => {
  const classes = useStyles()

  if (!tour) {
    return null
  }

  const { info, infoTitle } = tour

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} style={{ fontSize: rem(30), marginBottom: 15 }}>
        {infoTitle}
      </Typography>

      {Boolean(info) && <Markdown>{info}</Markdown>}
    </div>
  )
}

export default connect((state: StoreState) => ({
  tour: state.tourAndRankingReducer.tour,
}))(TourInfo)
