import React from 'react'
import { FormControl, MenuItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { fetchCountryStates } from '../../store/clubsAndCountries/actions'
import TMSelect from './inputs/TMSelect'

interface OwnProps {
  error?: boolean
  required?: boolean
  countryId?: number
  stateId?: number
  hideIfEmpty?: boolean
  onChange?: (event: any) => void
  style?: React.CSSProperties
}

interface StateProps {
  states: CountryState[]
  statesLoading: boolean
}

interface DispatchProps {
  fetchCountryStates(countryId: number): void
}

type Props = OwnProps & StateProps & DispatchProps

class CountryStatePicker extends React.Component<Props> {
  render() {
    const { error, stateId = '', required, onChange, states = [], hideIfEmpty = false } = this.props

    if (hideIfEmpty && !states.length) {
      return null
    }

    return (
      <FormControl
        fullWidth={true}
        required={required}
        error={error}
        disabled={!states.length}
        style={this.props.style}
      >
        <TMSelect
          label={<FormattedMessage id="strings.countryState" />}
          disabled={!states.length}
          error={error}
          required={required}
          value={stateId}
          onChange={onChange}
          name="country-state-picker"
          id="country-state-picker"
        >
          {this._renderCountryStateItems()}
        </TMSelect>
      </FormControl>
    )
  }

  private _renderCountryStateItems = () => {
    const { states = [] } = this.props

    return states.map((state) => (
      <MenuItem key={state.id} value={state.id}>
        {state.stateName}
      </MenuItem>
    ))
  }
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  (state) => ({
    states: state.clubsAndCountriesReducer.countryStates,
    statesLoading: state.clubsAndCountriesReducer.loading,
  }),
  {
    fetchCountryStates,
  },
)(CountryStatePicker)
