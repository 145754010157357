import { ConstructedHeadProps } from './ConstructedHead'
import get from 'lodash/get'

type FormatDefaultTournamentSeo = {
  pageName?: string
  tournamentSite?: TournamentSite
}

export const formatDefaultTournamentSeo = ({
  pageName,
  tournamentSite,
}: FormatDefaultTournamentSeo): ConstructedHeadProps => {
  if (!tournamentSite) {
    return {
      title: pageName,
    }
  }

  const {
    tournament: { name },
    images,
  } = tournamentSite
  const heroImage = get(images, 'heroImage')

  return {
    title: pageName ? `${pageName} ${name}` : name,
    image: heroImage && heroImage.url ? heroImage : undefined,
  }
}

type FormatDefaultTourSeo = {
  pageName?: string
  tour?: Tour
}

export const formatDefaultTourSeo = ({ pageName, tour }: FormatDefaultTourSeo): ConstructedHeadProps => {
  if (!tour) {
    return {
      title: pageName,
    }
  }

  const { name, hero } = tour

  return {
    title: pageName ? `${pageName} ${name}` : name,
    image: hero && hero.url ? hero : undefined,
  }
}
