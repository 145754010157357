export const formatAddressGMapsUrl = (address: string): string => {
  return `https://www.google.com/maps/search/?api=1&query=${address}`
}

export const formatGMapsStaticImageURL = (apiKey: string, address: string, size = '640x640'): string => {
  return (
    `https://maps.googleapis.com/maps/api/staticmap?` +
    `key=${apiKey}&center=${address}&zoom=13&scale=1&` +
    `size=${size}&maptype=roadmap&format=png&visual_refresh=true&` +
    `markers=color:0x268d51%7C${address}`
  )
}
