import React from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { Theme, Table, TableHead, TableRow, TableCell, Grid, TableBody, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { CourseIcon, GameIcon } from '@app/assets/images/icons'
import { ArrowForward } from '@mui/icons-material'
import { loadTournamentContestLeaderboard } from '@app/store/tournamentContestLeaderboard/actions'
import { ContestResultType } from '@app/store/tournamentContestLeaderboard/enums'
import { formatMeasurement } from '@app/utils/measurementsUtils'
import { cloneDeep } from 'lodash'

const styles = (theme: Theme) =>
  createStyles({
    contestTypeHeader: {
      backgroundColor: theme.customPalette.ggbGreen,
      padding: '10px !important',
      fontFamily: ['Exo', 'sans-serif'].join(','),
      marginBottom: 2,
    },
    contestTypeHeaderSeparator: {
      marginTop: 17,
    },
    contestTypeIcon: {
      height: 25,
      width: 25,
      marginRight: 10,
      fill: '#fff',
      float: 'left',
    },
    contestTypeTitle: {
      color: '#fff',
      fontSize: theme.typography.pxToRem(16),
      marginTop: 2,
    },
    contestTable: {
      '& thead': {
        backgroundColor: theme.customPalette.mediumGray2,
      },
      '& th, & td': {
        fontFamily: ['Exo', 'sans-serif'].join(','),
        border: 0,
      },
      '& th': {
        padding: '1px 0 0 10px !important',
      },
      '& td': {
        border: 0,
        padding: '10px !important',
      },
      '& tbody tr:nth-child(odd)': {
        backgroundColor: theme.customPalette.tableAltLightBg,
      },
    },
    hole: {
      color: '#fff',
      fontWeight: 700,
      fontSize: 13,
    },
    position: {
      width: 35,
      paddingRight: 0,
    },
    measurement: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textAlign: 'right',
    },
  })

interface OwnProps extends WithStyles<typeof styles> {
  tournamentId?: number
}

interface StateProps {
  contests?: ContestResult[]
  tournamentSite: TournamentSite
}

interface DispatchProps {
  loadTournamentContestLeaderboard(id: number): void
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  scoreExpanded?: string
}

class ContestsDataScreen extends React.Component<Props, State> {
  timer = null

  public componentDidMount(): void {
    const { tournamentId, loadTournamentContestLeaderboard } = this.props

    if (tournamentId) {
      this.props.loadTournamentContestLeaderboard(tournamentId)
    }

    // Refresh leaderboard every 30 seconds
    this.timer = setInterval(() => loadTournamentContestLeaderboard(tournamentId), 30000)
  }

  public componentWillUnmount(): void {
    clearInterval(this.timer)
  }

  render() {
    const contests = this.props.contests ? cloneDeep(this.props.contests) : null
    let prevType = undefined

    if (contests) {
      return (
        <Grid container>
          {contests
            .sort(
              (a, b) =>
                Object.values(ContestResultType).indexOf(a.type) - Object.values(ContestResultType).indexOf(b.type) ||
                a.holeNumber - b.holeNumber,
            )
            .map((c) => {
              const renderTypeHeader = prevType !== c.type
              prevType = c.type
              return this._renderHole(c.type, c.holeNumber.toString(), c.entries, c.measured, renderTypeHeader)
            })}
        </Grid>
      )
    }
  }

  private _renderHole = (
    type: ContestResultType,
    hole: string,
    result: ContestEntry[],
    measured: boolean,
    renderTypeHeader: boolean,
  ) => {
    const { classes, tournamentSite } = this.props
    const r = result.shift()

    return (
      <Grid item xs={12} md={12} lg={12} key={`${type}-${hole}`}>
        {renderTypeHeader && (
          <div
            className={classNames([
              classes.contestTypeHeader,
              Object.values(ContestResultType).indexOf(type) > 0 ? classes.contestTypeHeaderSeparator : undefined,
            ])}
          >
            {this._getTitle(type)}
          </div>
        )}
        <Table className={classes.contestTable}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} className={classes.hole}>
                <FormattedMessage id="leaderboard.hole" /> {hole}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {r && r.score && (
              <TableRow>
                <TableCell className={classes.position}>{r.position}</TableCell>
                <TableCell>
                  {r.user.firstName} {r.user.lastName}
                </TableCell>
                <TableCell className={classes.measurement}>
                  {measured && formatMeasurement(r.score, tournamentSite.units)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    )
  }

  private _getTitle = (type: string) => {
    const { classes } = this.props

    if (type === ContestResultType.CLOSEST_TO_PIN) {
      return (
        <>
          <CourseIcon className={classes.contestTypeIcon} />
          <Typography variant={'h4'} className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.closestToPin" />
          </Typography>
        </>
      )
    } else if (type === ContestResultType.LONGEST_DRIVE) {
      return (
        <>
          <GameIcon className={classes.contestTypeIcon} />
          <Typography variant={'h4'} className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.longestDrive" />
          </Typography>
        </>
      )
    } else if (type === ContestResultType.STRAIGHTEST_DRIVE) {
      return (
        <>
          <ArrowForward className={classes.contestTypeIcon} />
          <Typography variant={'h4'} className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.straightestDrive" />
          </Typography>
        </>
      )
    }
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (state) => ({
      contests: state.tournamentContestLeaderboardReducer.data?.rounds[0]?.contests,
      tournamentSite: state.tournamentReducer.tournamentSite,
    }),
    { loadTournamentContestLeaderboard },
  )(ContestsDataScreen),
)
