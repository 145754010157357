import { GlobeDoodleIcon } from '@app/assets/images/icons/globeDoodle'
import Footer from '@app/components/layout/Footer'
import { ConstructedHead, ConstructedHeadProps } from '@app/components/seo/ConstructedHead'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 800,
    padding: '4rem 1rem 8rem',
    margin: '0 auto',
  },
}))

export const PageNotFound = () => {
  const classes = useStyles()

  const seo: ConstructedHeadProps = {
    title: 'Page Not Found',
    noIndex: true,
  }

  return (
    <>
      <ConstructedHead {...seo} />
      <main className={classes.wrapper}>
        <GlobeDoodleIcon />
        <Typography variant="h1" align="center" color="primary" fontSize={25} marginBottom={1}>
          <FormattedMessage id="errors.pageNotFound" />
        </Typography>
        <Typography variant="h3" align="center" fontWeight="800" fontSize={18}>
          <FormattedMessage id="errors.pageNotFoundTip" />
        </Typography>
      </main>
      <Footer />
    </>
  )
}
