import React, { useState } from 'react'
import { MenuItem, Grid, Theme, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage, useIntl } from 'react-intl'
import { editEntryTeamMember } from '@app/store/authentication/actions'
import { formatHandicap, trimHcpDecimals, validateHandicapValue } from '@app/utils/playerUtils'
import { useDispatch, useSelector } from 'react-redux'
import isEmail from 'validator/lib/isEmail'

const useStyles = makeStyles((theme: Theme) => ({
  manualContainer: {
    width: '100%',
    marginTop: 30,
    marginLeft: 0,
    padding: '10px 20px 20px 12px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
  },
  manualInput: {
    width: '100%',
    marginTop: 10,
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
    },
  },
}))

enum Fields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  HCP = 'hcp',
  DIRTY_HCP = 'dirtyHcp',
  EMAIL = 'email',
}

interface Props {
  index: number
  temporary?: boolean
  emailInvalidCallback: (invalid: boolean) => void
}

export default function TeamMemberManual({ index, temporary = false, emailInvalidCallback }: Props) {
  const [dirtyFields, setDirtyFields] = useState<string[]>([])
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { team } = useSelector((store: StoreState) => store.authenticationReducer)
  const teamMember = team?.[index]

  // Disable email field if the team member is not temporary or if the team member is already registered
  // Changing email is not allowed in backend
  const emailFieldDisabled = !temporary || teamMember?.id !== undefined

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name
    const value = event.target.value
    editEntry(fieldName, value)
  }

  const editEntry = (fieldName: string, value: string | number) => {
    let newValue = value
    const isNonEmptyHcpValue = fieldName === Fields.DIRTY_HCP && newValue && newValue !== '+'
    if (isNonEmptyHcpValue && !validateHandicapValue(newValue.toString()).valid) {
      return
    }

    if (fieldName === Fields.DIRTY_HCP) {
      newValue = trimHcpDecimals(value.toString())
    }

    dispatch(editEntryTeamMember({ index, fieldName, value: newValue }))
    if (!dirtyFields.includes(fieldName)) {
      setDirtyFields([...dirtyFields, fieldName])
    }
  }

  const isFieldInvalid = (fieldName: string): boolean => {
    return !teamMember?.[fieldName] && dirtyFields.includes(fieldName)
  }

  const isEmailInvalid = (): boolean => {
    // Empty email is valid
    if (!teamMember?.email) {
      emailInvalidCallback(false)
      return false
    }

    const invalid = !isEmail(teamMember?.email) || isDuplicateEmail()

    emailInvalidCallback(invalid)
    return invalid
  }

  const isDuplicateEmail = (): boolean => {
    if (!teamMember?.email) return false

    // Skip the current member when checking for duplicates
    const duplicateEmail = team?.find(
      (member, memberIndex) => memberIndex !== index && member.email === teamMember.email,
    )

    return !!duplicateEmail
  }

  const getHcpValue = () => {
    switch (true) {
      case teamMember?.dirtyHcp !== undefined:
        return teamMember?.dirtyHcp
      case teamMember?.hcp !== undefined && teamMember?.id !== undefined:
        return formatHandicap(teamMember.hcp.toString())
      default:
        return ''
    }
  }

  return (
    <Grid container key={`${index}-manual`} className={classes.manualContainer} spacing={1}>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled={!temporary}
          fullWidth
          name={Fields.FIRST_NAME}
          value={teamMember?.firstName || ''}
          onChange={handleChange}
          error={isFieldInvalid(Fields.FIRST_NAME)}
          placeholder={intl.formatMessage({ id: 'strings.firstName' })}
          label={intl.formatMessage({ id: 'strings.firstName' })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ sx: { height: 40 } }}
          className={classes.manualInput}
          required
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled={!temporary}
          fullWidth
          name={Fields.LAST_NAME}
          value={teamMember?.lastName || ''}
          onChange={handleChange}
          error={isFieldInvalid(Fields.LAST_NAME)}
          placeholder={intl.formatMessage({ id: 'strings.lastName' })}
          label={intl.formatMessage({ id: 'strings.lastName' })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ sx: { height: 40 } }}
          className={classes.manualInput}
          required
        />
      </Grid>

      <Grid item sm={3.5} xs={6}>
        <TextField
          select
          name={Fields.GENDER}
          id="gender"
          value={teamMember?.gender || ''}
          onChange={handleChange}
          error={isFieldInvalid(Fields.GENDER)}
          style={{ maxHeight: 35, textTransform: 'lowercase' }}
          label={intl.formatMessage({ id: 'strings.gender' })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ sx: { height: 40 } }}
          className={classes.manualInput}
          required
          disabled={!temporary}
        >
          <MenuItem value={'male'} style={{ textTransform: 'lowercase' }}>
            <FormattedMessage id={'strings.male'} />
          </MenuItem>
          <MenuItem value={'female'} style={{ textTransform: 'lowercase' }}>
            <FormattedMessage id={'strings.female'} />
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item sm={2.5} xs={6}>
        <TextField
          name={Fields.DIRTY_HCP}
          value={getHcpValue()}
          onChange={handleChange}
          error={isFieldInvalid(Fields.DIRTY_HCP)}
          placeholder={intl.formatMessage({ id: 'strings.hcp' })}
          label={intl.formatMessage({ id: 'strings.hcp' })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ sx: { height: 40 } }}
          className={classes.manualInput}
          required
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled={emailFieldDisabled}
          fullWidth
          name={Fields.EMAIL}
          value={teamMember?.email || ''}
          onChange={handleChange}
          error={isEmailInvalid()}
          helperText={isDuplicateEmail() ? intl.formatMessage({ id: 'apiErrors.USER_ALREADY_REGISTERED' }) : ''}
          placeholder={intl.formatMessage({ id: 'strings.email' })}
          label={intl.formatMessage({ id: 'strings.email' })}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ sx: { height: 40 } }}
          className={classes.manualInput}
        />
      </Grid>
    </Grid>
  )
}
