import React, { CSSProperties } from 'react'
import { Dialog } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { Cancel } from '@mui/icons-material'

export interface DialogBaseProps {
  open?: boolean
  title?: React.ReactNode
  children?: React.ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullWidth?: boolean
  style?: CSSProperties
  className?: string
  onClose?: () => void
}

class DialogBase extends React.Component<DialogBaseProps> {
  render() {
    const { open, maxWidth = 'sm', fullWidth = true, style = {}, className } = this.props

    return (
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted={false}
        scroll={'body'}
        style={style}
        className={className}
      >
        {this._renderTitle()}
        {this.props.children}
      </Dialog>
    )
  }

  private _renderTitle = () => {
    const { title, onClose } = this.props

    return (
      <DialogTitle style={{ paddingRight: 50 }}>
        <span>{title}</span>
        {onClose && (
          <Cancel
            onClick={onClose}
            className="linkIcon"
            style={{
              position: 'absolute',
              right: 20,
              top: 28,
            }}
          />
        )}
      </DialogTitle>
    )
  }
}

export default DialogBase
