import React, { CSSProperties } from 'react'
import { Grid, Typography, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { GGBAppImg, GGBAppImg2x, AppStore, GooglePlay, GGBAppQR, GGBAppQR2x } from '../../assets/images'
import { FormattedMessageWrapper } from './FormattedMessageWrapper'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: '0 auto',
      padding: '0 16px',
      width: '100%',
      backgroundColor: theme.customPalette.appAdBackground,
      fontFamily: '"Public sans", sans-serif',
      alignSelf: 'flex-end',
    },
    wrapper: {
      padding: '30px 60px !important',
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down('md')]: {
        padding: '20px 30px !important',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    appImgWrapper: {
      display: 'flex',
      flex: '1 0 43%',
      justifyContent: 'flex-end',
      paddingRight: 16,

      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        justifyContent: 'center',
      },
    },
    img: {
      maxWidth: 272,
      width: '100%',
      margin: '0 20px',
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    QRCodeImg: {
      maxWidth: 100,
      width: '100%',
      height: 97,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%) translateX(-20px)',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    content: {
      margin: '0 20px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& a': {
        color: '#1aa85d',
      },

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    appLinks: {
      display: 'flex',
      gap: '24px',

      [theme.breakpoints.down('md')]: {
        gap: '12px',
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },

      '& img': {
        height: 45,
        marginTop: 20,

        [theme.breakpoints.down('md')]: {
          height: 31,
        },
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  style?: CSSProperties
}

class AppAd extends React.PureComponent<Props> {
  render(): React.ReactNode {
    const { classes, style } = this.props

    return (
      <Grid container spacing={2} style={style} className={classes.root}>
        <Grid item xs={12} className={classes.wrapper}>
          <div className={classes.appImgWrapper}>
            <div style={{ position: 'relative' }}>
              <img className={classes.img} alt={'Golf Gamebook App'} src={GGBAppImg} srcSet={`${GGBAppImg2x} 2x`} />
              <img
                className={classes.QRCodeImg}
                alt={'Scan the QR code to download the Golf Gamebook App'}
                src={GGBAppQR}
                srcSet={`${GGBAppQR2x} 2x`}
              />
            </div>
          </div>
          <div className={classes.content}>
            <Typography variant="h2PublicSans" style={{ marginBottom: 15 }}>
              <FormattedMessage id="strings.appAdTitle" />
            </Typography>
            <Typography variant="bodyPublicSans" style={{ marginBottom: 10 }}>
              <FormattedMessageWrapper id="strings.appAdText" />
            </Typography>
            <div className={classes.appLinks}>
              <a
                // eslint-disable-next-line max-len
                href="https://app.adjust.com/88ueiz6?redirect=http%3A%2F%2Fitunes.apple.com/us/app/golf-gamebook-best-golf-app/id409307935?mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppStore} alt="Download Golf GameBook on AppStore" />
              </a>
              <a
                // eslint-disable-next-line max-len
                href="https://app.adjust.com/88ueiz6?redirect=http%3A%2F%2Fplay.google.com/store/apps/details?id=com.freedropinnovations.gamebookInter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlay} alt="Get Golf GameBook on Google Play" />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(
  connect((state: StoreState) => ({
    tournamentSite: state.tournamentReducer.tournamentSite,
  }))(AppAd),
)
