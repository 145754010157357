import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveDivisionEndpoint } from '@app/store/tournament/actions'
import { Filter } from '@golfgamebook/web-leaderboard'
import { APIRoute } from '@app/config'
import { IntlShape } from 'react-intl'
import { SelectBoxMenuItem } from '@app/components/ui/SelectBox'

interface Props {
  intl: IntlShape
}

export const LeaderboardDivisions = ({ intl }: Props) => {
  const dispatch = useDispatch()
  const tournamentSite = useSelector((state: StoreState) => state.tournamentReducer.tournamentSite)

  const changeLeaderboardEndpoint = (value: string) => {
    if (value) {
      dispatch(setActiveDivisionEndpoint(value))
    }
  }

  const divisions = useMemo(() => {
    const items: SelectBoxMenuItem[] = tournamentSite.divisions.map((division) => ({
      label: division.name,
      id: String(division.id),
      value: APIRoute.GET_LEADERBOARD_WITH_DIVISIONS(tournamentSite.tournament.id, division.id),
    }))

    items.unshift({
      id: '0',
      value: APIRoute.GET_LEADERBOARD(tournamentSite.tournament.id),
      label: intl.formatMessage({ id: 'leaderboard.allPlayers' }),
    })

    return items
  }, [intl, tournamentSite])

  return <Filter options={divisions} handleChange={changeLeaderboardEndpoint} />
}
