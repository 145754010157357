import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  EDIT_ENTRY_TEAM_MEMBER,
  QUESTION_FIELD_UPDATE,
  USER_FIELD_UPDATE,
  AUTH_REGISTER_AND_LOGIN,
  LOGIN_MAP,
  EDIT_ENTRY_TEAM,
  AUTH_LOGIN_APPLE,
} from './constants'

export const login = (loginData: LoginPayload) => {
  return {
    type: AUTH_LOGIN,
    payload: loginData,
  }
}

export const loginApple = (loginData: AppleLoginPayload) => {
  return {
    type: AUTH_LOGIN_APPLE,
    payload: loginData,
  }
}

export const loginSuccess = (payload: LoginReturnPayload) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  }
}

export const logout = (): LogOutAction => ({
  type: AUTH_LOGOUT,
})

export const registerAndLogin = (registerData: RegisterPayload) => {
  return {
    type: AUTH_REGISTER_AND_LOGIN,
    payload: registerData,
  }
}

/*
 * TOURNAMENT SITE UPDATE (field)
 */
export interface UpdateUserFieldAction extends Action {
  type: 'USER_FIELD_UPDATE'
  payload: FieldUpdatePayload
}
export const updateUserField = (payload: FieldUpdatePayload): UpdateUserFieldAction => ({
  type: USER_FIELD_UPDATE,
  payload,
})

export interface UpdateQuestionAnswerPayload {
  questionId: number
  answer: string
}
export interface UpdateQuestionAnswerAction extends Action {
  type: 'QUESTION_FIELD_UPDATE'
  payload: UpdateQuestionAnswerPayload
}
export const updateQuestionAnswer = (payload: UpdateQuestionAnswerPayload): UpdateQuestionAnswerAction => ({
  type: QUESTION_FIELD_UPDATE,
  payload,
})

/*
  EDIT FULL TEAM ENTRY
 */
export interface EditEntryTeamPayload {
  team: TeamMember[]
}
export interface EditEntryTeamAction extends Action {
  type: 'EDIT_ENTRY_TEAM'
  payload: EditEntryTeamPayload
}
export const editEntryTeam = (payload: EditEntryTeamPayload): EditEntryTeamAction => ({
  type: EDIT_ENTRY_TEAM,
  payload,
})

/*
  EDIT TEAM MEMBER ENTRY
 */
export interface EditEntryTeamMemberPayload {
  index: number
  fieldName: string
  value: any
}
export interface EditEntryTeamMemberAction extends Action, EditEntryTeamMemberPayload {
  type: 'EDIT_ENTRY_TEAM_MEMBER'
}
export const editEntryTeamMember = (args: EditEntryTeamMemberPayload): EditEntryTeamMemberAction => ({
  type: EDIT_ENTRY_TEAM_MEMBER,
  ...args,
})

/*
  LOGIN MAP
 */
export interface LoginMapPayload {
  userId: string
  clientUid: number
  clubId: number
  membershipNumber: string
  firstNameInitials: string
  lastNameInitials: string
  birthYear: string
}
export interface LoginMapAction extends Action, LoginMapPayload {
  type: 'LOGIN_MAP'
}
export interface LoginMapSuccessAction extends Action {
  type: 'LOGIN_MAP_SUCCESS'
  payload: {
    status: string
    hcpUpdated: boolean
    newHcp?: string
  }
}
export interface LoginMapFailureAction extends Action {
  type: 'LOGIN_MAP_FAILURE'
  error: string
}
export const loginMap = (args: LoginMapPayload): LoginMapAction => ({
  type: LOGIN_MAP,
  ...args,
})
