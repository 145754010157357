import React, { CSSProperties } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { TournamentLogo } from './TournamentLogo'
import { defaultFormat } from '../../utils/date'
import { TournamentTypes } from '@app/utils/enums'
import SignUp from './SignUp'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '50px 20px 20px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 0 24px',
      },
    },
    title: {
      textTransform: 'uppercase',
      overflowWrap: 'break-word',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: '1.2em',
        marginBottom: 5,
        marginTop: 10,
      },
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    signUpContainer: {
      marginTop: 40,
      [theme.breakpoints.down('md')]: {
        marginTop: 23,
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  style?: CSSProperties
  tournamentSite: TournamentSite
  invitation?: boolean
}

class TournamentTitleBlock extends React.Component<Props> {
  render() {
    const { classes, style, invitation } = this.props

    return (
      <div className={classes.root} style={style}>
        {invitation && (
          <Typography variant="subtitle1">
            <FormattedMessage id="strings.welcome" />
          </Typography>
        )}
        <Typography variant={'h1'} className={classes.title}>
          {this._tournamentName}
        </Typography>
        <Typography variant="h3" className={classes.text}>
          {this._clubName} | {this._tournamentDate}
        </Typography>

        <TournamentLogo />

        <div className={classes.signUpContainer}>
          <SignUp onlyBtn />
        </div>
      </div>
    )
  }

  get _tournamentName() {
    const { tournamentSite } = this.props
    return get(tournamentSite, 'tournament.name')
  }

  get _clubName() {
    const { tournamentSite } = this.props
    return get(tournamentSite, 'tournament.rounds[0].club.name')
  }

  get _tournamentDate() {
    const { tournamentSite } = this.props
    const date = get(tournamentSite, 'tournament.startTime')
    const endDate = get(tournamentSite, 'tournament.endTime')

    if (tournamentSite.tournament.tournamentType === TournamentTypes.weekly) {
      return `${moment(date).format(defaultFormat('date', tournamentSite.units))} - ${moment(endDate).format(
        defaultFormat('date', tournamentSite.units),
      )}`
    } else if (date) {
      return moment(date).format(defaultFormat('date', tournamentSite.units))
    }

    return null
  }
}

export default withStyles(styles)(
  connect((state: StoreState) => ({
    tournamentSite: state.tournamentReducer.tournamentSite,
  }))(TournamentTitleBlock),
)
