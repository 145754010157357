import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { Link, Theme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { formatAddressGMapsUrl, formatGMapsStaticImageURL } from '@app/utils/googleMaps'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  titleGrid: {
    width: 125,
    [theme.breakpoints.down('sm')]: {
      width: 115,
    },
  },
  mapImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    maxWidth: 420,
    maxHeight: 312,
    paddingBottom: `${(395 / 722) * 100}%`,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      margin: '20px 0 0 0',
    },
  },
}))

type Props = {
  round: number
  tournamentSite?: TournamentSite
}

const CourseInfo = ({ round, tournamentSite }: Props) => {
  const classes = useStyles()
  if (!tournamentSite) {
    return null
  }

  const { tournament, googleMapsApiKey } = tournamentSite

  if (!tournament) {
    return null
  }

  const courseName = get(tournament, `rounds[${round}].course.courseName`)
  const homepage = get(tournament, `rounds[${round}].club.url`)
  const address = compact([
    get(tournament, `rounds[${round}].club.streetAddress`),
    get(tournament, `rounds[${round}].club.postalCode`),
    get(tournament, `rounds[${round}].club.city`),
  ]).join(', ')

  let holes: string | JSX.Element = get(tournament, `rounds[${round}].holes`)
  if (holes === 'b9') holes = <FormattedMessage id="strings.back9" />
  if (holes === 'f9') holes = <FormattedMessage id="strings.front9" />

  return (
    <Grid container={true} direction="row" style={{ paddingBottom: 30 }}>
      <Grid item xs={12} md={6}>
        <Grid container direction="column">
          <Grid item={true}>
            <Typography variant={'h2'} style={{ marginBottom: 16 }}>
              <FormattedMessage id={'strings.golfCourse'} />
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.titleGrid}>
                <Typography variant={'body1'}>
                  <FormattedMessage id={'strings.course'} />:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  <b>{courseName}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {holes && (
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.titleGrid}>
                  <Typography variant={'body1'}>
                    <FormattedMessage id={'strings.holes'} />:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    <b>{holes}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {address && (
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.titleGrid}>
                  <Typography variant={'body1'}>
                    <FormattedMessage id={'strings.clubAddress'} />:
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant={'body1'} style={{ wordWrap: 'break-word' }}>
                    <b>{address}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {homepage && (
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.titleGrid}>
                  <Typography variant={'body1'}>Website:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    <Link href={homepage} variant={'body1'} target={'__blank'}>
                      <b>{homepage}</b>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <a href={formatAddressGMapsUrl(address)} target={'_blank'} rel="noreferrer">
          <figure
            className={classes.mapImage}
            style={{
              backgroundImage: `url("${formatGMapsStaticImageURL(googleMapsApiKey, address)}")`,
            }}
          />
        </a>
      </Grid>
    </Grid>
  )
}

export default connect((state: StoreState) => ({
  tournamentSite: state.tournamentReducer.tournamentSite,
}))(CourseInfo)
