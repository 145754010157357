export function flattenMessages(nestedMessages: LocalizationObject, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: LocalizationMessages, key: string) => {
    const value: LocalizationObject | string = nestedMessages[key]
    const prefixedKey: string = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }
    return messages
  }, {})
}
