import GameInfo from '@app/components/tournament/GameInfo'
import { ImagesAndSignUp } from '@app/components/tournament/ImagesAndSignUp'
import { RoundSelect } from '@app/components/tournament/ui/RoundSelect'
import { rem } from '@app/theme/materialUITheme'
import { Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 10,
    width: 'auto',
  },
  content: {
    paddingBottom: `${rem(55)} `,
  },
  scheduleAndAbout: {
    borderBottom: `1px solid ${theme.customPalette.lightGray2}`,
    paddingBottom: rem(40),
  },
  signUp: {
    borderTop: `1px solid ${theme.customPalette.lightGray2}`,
    borderBottom: `1px solid ${theme.customPalette.lightGray2}`,
  },
  appAd: {
    paddingTop: rem(40),
  },
  roundSelect: {
    marginLeft: -10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      marginLeft: 0,
    },
  },
}))

export const GamePage = () => {
  const classes = useStyles()
  const { tournamentSite, selectedRoundIndex } = useSelector((store: StoreState) => store.tournamentReducer)
  const isMultiRound = tournamentSite?.tournament.rounds.length > 1
  return (
    <Grid container className={classes.container}>
      <Grid item sx={{ width: 1 }} className={classes.content}>
        {isMultiRound && (
          <div className={classes.roundSelect}>
            <RoundSelect />
          </div>
        )}
        <GameInfo round={selectedRoundIndex} />
      </Grid>
      <ImagesAndSignUp alwaysShowSignUp />
    </Grid>
  )
}
