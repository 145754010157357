import React, { useEffect, useMemo } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Routes, Route, useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { localizations } from './localization'
import { flattenMessages } from './utils/flattenMessages'
import materialUITheme from './theme/materialUITheme'
import CssBaseline from '@mui/material/CssBaseline'
import { TournamentSite } from './scenes/tournament-site/TournamentSite'
import Container from './components/layout/Container'
import { ColorTheme } from './utils/enums'
import { useLanguage } from './hooks/useLanguage'
import { Calendar } from './scenes/calendar/Calendar'
import { TournamentScreen } from './scenes/tournament-screen/TournamentScreen'
import { useSelector } from 'react-redux'
import { useAnalytics } from './hooks'
import { TournamentTourSite } from './scenes/tournament-tour-site/TournamentTourSite'
import { HelmetProvider } from 'react-helmet-async'
import { PageNotFound } from './scenes/page-not-found/PageNotFound'

const App = () => {
  const location = useLocation()
  const { logPageView } = useAnalytics()
  const primaryColor = useSelector(
    (state: StoreState) => state.tournamentReducer.tournamentSite?.primaryColor || '#1aa85d',
  )
  const { languageCode } = useLanguage()
  const colorTheme: ColorTheme =
    useSelector((store: StoreState) => store.tournamentReducer.tournamentSite?.colorTheme) || ColorTheme.LIGHT

  useEffect(() => {
    logPageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const shouldRenderNav = useMemo(() => {
    if (location.pathname.endsWith('/screen')) {
      return false
    }
    return true
  }, [location.pathname])

  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={materialUITheme(colorTheme, primaryColor)}>
          <HelmetProvider>
            <CssBaseline />
            <IntlProvider locale={languageCode} messages={flattenMessages(localizations[languageCode])}>
              <Container renderNav={shouldRenderNav}>
                <div style={{ backgroundColor: colorTheme === ColorTheme.LIGHT ? '#F2F2F2' : '#5C5C5C' }}>
                  <Routes>
                    <Route path="/tour/:tourId" element={<TournamentTourSite />} />
                    <Route path="/calendar/:calendarId" element={<Calendar />} />
                    <Route path="/tournament/:tournamentId" element={<TournamentSite />} />
                    <Route path="/tournament/:tournamentId/screen" element={<TournamentScreen />} />
                    <Route path="/tournament/:tournamentId/payment-return/" element={<TournamentSite />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </div>
              </Container>
            </IntlProvider>
          </HelmetProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  )
}

export default App
