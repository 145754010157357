import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Cancel, Search } from '@mui/icons-material'
import { Card, CircularProgress, FormControl, FormLabel, Grid, IconButton, TextField, Theme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { isCountrySweden } from '@app/utils/tournamentUtils'
import classNames from 'classnames'
import { formatHandicap } from '@app/utils/playerUtils'
import { makeStyles } from '@mui/styles'
import { rem } from '@app/theme/materialUITheme'
import TeamMemberManual from '../ui/TeamMemberManual'
import TeamMemberCard from '../ui/TeamMemberCard'
import TourButton from '../ui/TourButton'
import TextButton from '../ui/TextButton'
import { clearSearch, searchPlayers } from '@app/store/tournament/actions'
import { CustomScroll } from '../ui/CustomScroll'
import { getDefaultProfileImage } from '@app/config'

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  searchInputContainer: {
    flex: '1 1 auto',
  },
  searchButtonContainer: {
    flex: '0 0 50px',
  },
  searchResultContainer: {
    width: '100%',
    padding: '5px 0 5px 0',
    backgroundColor: theme.palette.common.white,
  },
  searchResultItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.common.black,
    fontSize: rem(13),
    padding: '5px 15px',
  },
  searchResultItemContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    cursor: 'pointer',
    width: '96%',
    '&:nth-child(odd)': {
      backgroundColor: theme.customPalette.tableAltLightBg,
    },
    '&:hover': {
      opacity: 0.65,
    },
  },
  playerInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 58px)',
    fontSize: 14,
  },
  playerName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#373D40',
  },
  otherInfo: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#8A8A8E',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  image: {
    width: 48,
    height: 48,
    borderRadius: '50%',
  },
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    height: 40,
    width: 40,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.95,
    },
  },
  manualText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: rem(13),
    fontWeight: 500,
    cursor: 'pointer',
    padding: 15,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontSize: rem(13),
    margin: '0 0 5px 10px',
  },
  cardContainer: {
    marginTop: 50,
  },
  dialogContent: {
    padding: '0 10px',

    [theme.breakpoints.up('sm')]: {
      padding: '20px 45px',
    },
  },
  dialogActions: {
    padding: '30px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > button': {
      width: '50%',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '30px 45px',
    },
  },
}))

interface Props {
  index: number
  addSearchedPlayer: (searchedPlayer: RegistryPlayerSearchResult) => void
  addManualPlayer: () => void
  handleCancel: () => void
}

export default function AddPlayerDialog({ index, addSearchedPlayer, addManualPlayer, handleCancel }: Props) {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [shouldRenderManualRow, setShouldRenderManualRow] = useState<boolean>(false)
  const [shouldRenderPlayerCard, setShouldRenderPlayerCard] = useState<boolean>(false)
  const [foundPlayer, setFoundPlayer] = useState<TeamMember | undefined>(undefined)
  const [searchResultIndex, setSearchResultIndex] = useState<number | undefined>(undefined)
  const {
    tournamentSite,
    searchLoading: isSearching,
    registryPlayerSearchResult: searchResults,
  } = useSelector((store: StoreState) => store.tournamentReducer)
  const team = useSelector((store: StoreState) => store.authenticationReducer.team)
  const teamMember = team[index]
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)

  const { club } = tournamentSite.tournament.tournamentOrganization
  const getPlaceholderText = isCountrySweden(club?.countryId)
    ? intl.formatMessage({ id: 'strings.enterSearchTermSweden' })
    : intl.formatMessage({ id: 'strings.enterSearchTerm' })
  const searchRef = useRef<HTMLInputElement>(null)

  const handleOnSubmit = (): void => {
    if (searchText) {
      dispatch(clearSearch())
      setShowSearchResults(true)
      setShouldRenderManualRow(false)
      setShouldRenderPlayerCard(false)
      setFoundPlayer(undefined)
      dispatch(searchPlayers(searchText))
    }
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.currentTarget.value)
  }

  const pressEnter = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleOnSubmit()
    }
  }

  const handlePlayerClick = (index: number): void => {
    setShowSearchResults(false)
    setShouldRenderPlayerCard(true)
    setShouldRenderManualRow(false)
    const resultPlayer = searchResults[index]
    const teamMember: TeamMember = {
      providerPlayerIdentifier: resultPlayer.providerPlayerIdentifier,
      firstName: resultPlayer.firstName,
      lastName: resultPlayer.lastName,
      hcp: resultPlayer.handicap,
      profileImageUrl: resultPlayer.profileImageUrl,
      clubName: resultPlayer.clubName,
      gender: '',
    }
    setFoundPlayer(teamMember)
    setSearchResultIndex(index)
  }

  const handleNewPlayerClick = (): void => {
    setShowSearchResults(false)
    setShouldRenderManualRow(true)
  }

  const addFoundPlayer = (): void => {
    if (searchResultIndex !== undefined) {
      addSearchedPlayer(searchResults[searchResultIndex])
    }
  }

  const isSaveDisabled = (): boolean => {
    if (shouldRenderManualRow) {
      return (
        !teamMember?.firstName || !teamMember?.lastName || !teamMember?.gender || !teamMember?.dirtyHcp || emailInvalid
      )
    }
    return !foundPlayer
  }

  const emailInvalidCallback = (invalid: boolean): void => {
    setEmailInvalid(invalid)
  }

  const searchResultItem = (result: RegistryPlayerSearchResult, index: number): JSX.Element => {
    const profileImg = result.profileImageUrl || getDefaultProfileImage()
    return (
      <Grid
        item
        xs={12}
        key={`searchResult_${index}`}
        className={classes.searchResultItemContainer}
        onClick={() => handlePlayerClick(index)}
      >
        <div className={classes.searchResultItem} style={{ width: searchRef.current?.offsetWidth * 0.95 || '100%' }}>
          <div className={classes.imageContainer}>
            <img className={classes.image} src={profileImg} />
          </div>
          <div className={classes.playerInfoContainer}>
            <div className={classes.playerName}>
              {result.firstName} {result.lastName}
            </div>
            <div className={classes.otherInfo}>
              {result.handicap && formatHandicap(String(result.handicap))} {result.clubName && `| ${result.clubName}`}
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  const renderSearchResults = (): JSX.Element => {
    if (!showSearchResults) {
      return <></>
    }
    return (
      <Card className={classNames([classes.searchResultContainer, isSearching && classes.loading])}>
        {isSearching ? (
          <CircularProgress color="primary" />
        ) : (
          <Grid container direction="column">
            <Grid item>
              <Grid container>
                <CustomScroll forceVisible style={{ maxHeight: 180 }}>
                  {searchResults.map(searchResultItem)}
                </CustomScroll>
              </Grid>
            </Grid>
            <Grid item>
              <div onClick={handleNewPlayerClick} className={classes.manualText}>
                <FormattedMessage id="strings.inviteTeamPlayersManualFind" />
              </div>
            </Grid>
          </Grid>
        )}
      </Card>
    )
  }

  const renderSearch = (): JSX.Element => {
    return (
      <>
        <div className={classes.searchContainer}>
          <div className={classes.searchInputContainer}>
            <FormControl fullWidth>
              <FormLabel className={classes.inputLabel}>{intl.formatMessage({ id: 'strings.searchPlayer' })}</FormLabel>
              <TextField
                autoComplete={'off'}
                type="text"
                value={searchText}
                name="searchTerm"
                placeholder={getPlaceholderText}
                onChange={onChangeSearch}
                onKeyDown={pressEnter}
                sx={{ marginTop: 0 }}
                InputProps={{ sx: { height: 40 } }}
                inputRef={searchRef}
              />
            </FormControl>
          </div>
          <div className={classes.searchButtonContainer}>
            <IconButton onClick={handleOnSubmit} className={classes.searchButton}>
              <Search style={{ fill: '#fff' }} />
            </IconButton>
          </div>
        </div>
        <div>{renderSearchResults()}</div>
      </>
    )
  }

  return (
    <Dialog open={true} fullWidth maxWidth="sm" PaperProps={{ sx: { minHeight: 500 } }}>
      <DialogTitle style={{ paddingRight: 50 }}>
        <span>{intl.formatMessage({ id: 'buttons.addPlayer' })}</span>
        <Cancel
          onClick={handleCancel}
          className="linkIcon"
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {renderSearch()}
        <Grid item xs={12}>
          {shouldRenderManualRow && (
            <TeamMemberManual index={index} temporary={true} emailInvalidCallback={emailInvalidCallback} />
          )}
          {shouldRenderPlayerCard && (
            <div className={classes.cardContainer}>
              <TeamMemberCard index={index} teamMember={foundPlayer} fullWidth readonly />
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <TextButton onClick={handleCancel}>{intl.formatMessage({ id: 'buttons.cancel' })}</TextButton>
        <TourButton
          loading={false}
          color={'primary'}
          buttonProps={{
            onClick: () => (foundPlayer ? addFoundPlayer() : addManualPlayer()),
          }}
          disabled={isSaveDisabled()}
        >
          {intl.formatMessage({ id: 'buttons.addPlayer' })}
        </TourButton>
      </DialogActions>
    </Dialog>
  )
}
