import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rem } from '@app/theme/materialUITheme'
import classNames from 'classnames'

import { PaymentTypes, EntryMethod } from '@app/utils/enums'
import { fetchTournamentProducts, startPaymentProcess, fetchBoughtProducts } from '@app/store/payments/actions'

import { ChevronRight, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material'
import {
  Theme,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  CircularProgress,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  DialogContent,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { joinTournament } from '@app/store/tournament/actions'
import { validateHandicapValue } from '@app/utils/playerUtils'
import { formatCustomQuestionAnswersForAPI } from '@app/utils/customQuestionUtils'
import { currencyFractionsToMains } from '@app/utils/paymentUtils'
import { FormattedMessage } from 'react-intl'
import DialogBase from '@app/components/dialogs/DialogBase'
import Alert from '@app/components/dialogs/Alert'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Markdown from '../layout/Markdown'
import { omit } from 'lodash'

interface OwnProps {
  isRegistered: boolean
  handleJoinTournament(paymentType?: PaymentTypes): void
  handleReturn(): void
  handleClose(): void
}

const useStyles = makeStyles((theme: Theme) => ({
  paymentStepContainer: {
    display: 'grid',
    gridTemplateRows: 'auto min-content',
    height: '100%',
  },
  paymentBtn: {
    '&.MuiButton-outlined ': {
      padding: rem(15),
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: rem(30),
      border: `2px solid ${theme.customPalette.ggbGreen}`,
    },
    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
  listItem: {
    padding: 10,
    margin: 0,
    marginBottom: 15,
    width: '100%',
    borderRadius: 3,
    '& .MuiFormControlLabel-label': {
      width: '100%',
      paddingLeft: rem(10),
      paddingRight: rem(10),
    },
  },
  selectedProductItem: {
    border: `2px solid ${theme.customPalette.ggbGreen}`,
    boxShadow: `0 1px 3px ${theme.customPalette.ggbGreen}`,
    '&:hover': {
      border: `2px solid ${theme.customPalette.ggbGreen}`,
    },
  },
  notSelectedProductItem: {
    border: `2px solid ${theme.customPalette.lightGray2}`,
    '&:hover': {
      border: `2px solid ${theme.customPalette.lightGray2}`,
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
  button: {
    '&.MuiButton-root': {
      minWidth: rem(150),
      padding: '8px 15px',
    },
  },
  proceedBtn: {
    '&.MuiButton-root': {
      paddingTop: rem(10),
      paddingBottom: rem(10),
      width: rem(220),
    },
  },
  termsContainer: {
    width: rem(500),
    height: rem(300),
    overflowX: 'scroll',
    paddingLeft: rem(10),
    paddingRight: rem(10),
    marginTop: rem(20),
    marginBottom: rem(50),
  },
  acceptTermsBtn: {
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
  circularProgressBtn: {
    position: 'absolute',
    marginLeft: rem(10),
    right: rem(10),
  },
  itemListContainer: {
    maxHeight: 360,
    overflow: 'scroll',
    paddingRight: 10,
    paddingTop: 0,
    marginBottom: 20,
  },
  priceText: {
    fontWeight: 'bold',
    marginLeft: 10,
  },
  titleCase: {
    textTransform: 'capitalize',
  },
}))

export const PaymentProgressView: React.FC<OwnProps> = ({
  isRegistered,
  handleJoinTournament,
  handleReturn,
  handleClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [showProducts, setShowProducts] = useState<boolean>(false)
  const [selectedProductIds, setSelectedProductId] = useState<number[]>([])
  const [step, setStep] = useState<number>(0)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [selectedProductCurrency, setSelectedProductCurrency] = useState<string>()

  const auth = useSelector((store: StoreState) => store.authenticationReducer)
  const tournamentSite = useSelector((store: StoreState) => store.tournamentReducer.tournamentSite)
  const payments = useSelector((store: StoreState) => store.paymentReducer)
  const locale = useSelector((store: StoreState) => store.localeReducer)
  const currencies = useSelector((store: StoreState) => store.paymentReducer.currencies)

  const { userId, authToken, clientUid, hcp, gender, teebox, teamName, divisionId } = auth.user || ({} as GGBUser)
  const { id } = tournamentSite.tournament

  const handleFetchProducts = useCallback(() => {
    dispatch(
      fetchTournamentProducts({ userId, authToken, clientUid }, (success: boolean) => !success && setOpenAlert(true)),
    )
    if (isRegistered) {
      dispatch(fetchBoughtProducts({ userId, authToken, clientUid }))
    }
  }, [dispatch, authToken, clientUid, userId, isRegistered])

  const handleSelectedPayment = useCallback(
    (selected: PaymentTypes) => {
      if (selected === PaymentTypes.NOW) {
        handleFetchProducts()

        setStep(step + 1)
        setShowProducts(true)
      } else {
        handleJoinTournament()
        setShowLoader(true)
      }
    },
    [handleFetchProducts, handleJoinTournament, step],
  )

  const renderPaymentSelection = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={6} style={{ width: '100%' }}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.paymentBtn}
              onClick={() => handleSelectedPayment(PaymentTypes.NOW)}
              fullWidth
            >
              <Typography variant="h4">
                <FormattedMessage id={'payments.payNow'} />
              </Typography>
              <ChevronRight />
            </Button>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.paymentBtn}
              onClick={() => handleSelectedPayment(PaymentTypes.LATER)}
              fullWidth
              disabled={isRegistered}
            >
              <span style={{ textAlign: 'left' }}>
                <Typography variant="h4">
                  <FormattedMessage id={'payments.payLater'} />
                </Typography>
                <Typography variant="body2" style={{ fontSize: 10 }}>
                  {tournamentSite.postponePaymentNote}
                </Typography>
              </span>
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Button color="primary" variant="contained" onClick={handleReturn} fullWidth>
            <FormattedMessage id={'buttons.return'} />
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderLoaderOrError = () => {
    if (payments.error) {
      return (
        <Alert
          open={showLoader}
          title={<FormattedMessage id="strings.error" />}
          text={'error'}
          showCancel={false}
          okAction={handleReturn}
        />
      )
    } else {
      return (
        <div className={classes.circularProgressContainer}>
          <CircularProgress size={48} thickness={2} />
        </div>
      )
    }
  }

  const handleCurrencySelection = (product: TournamentProducts, noProductsSelected = false) => {
    if (noProductsSelected) {
      setSelectedProductCurrency('')
      return
    }
    if (!selectedProductCurrency) {
      setSelectedProductCurrency(product.currency)
    }
  }

  const handleProductSelection = (product: TournamentProducts) => {
    const { id } = product
    if (selectedProductIds.includes(id)) {
      const updatedProducts = selectedProductIds.filter((selectedId) => selectedId !== id)
      setSelectedProductId(updatedProducts)
      handleCurrencySelection(product, updatedProducts.length === 0)
      return
    }

    handleCurrencySelection(product)
    setSelectedProductId([...selectedProductIds, id])
  }

  const getCurrency = (currencyCode: string) => {
    return currencies.find((currency) => currency.code === currencyCode)
  }

  const getCurrencyMinorDivider = (currencyCode: string) => {
    return getCurrency(currencyCode)?.minorDivider || 100
  }

  const getCurrencySymbol = (currencyCode: string) => {
    return currencyCode
  }

  const renderProductsListItem = (product: TournamentProducts) => (
    <FormControlLabel
      key={product.id}
      color="primary"
      label={
        <Grid container style={{ width: '100%' }} direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ fontWeight: 'bold' }}>{product.name}</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="caption">
                  {product.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.priceText}>
              {currencyFractionsToMains(product.price, getCurrencyMinorDivider(product.currency))}{' '}
              {getCurrencySymbol(product.currency)}
            </Typography>
          </Grid>
        </Grid>
      }
      className={classNames([
        classes.listItem,
        selectedProductIds?.includes(product.id) ? classes.selectedProductItem : classes.notSelectedProductItem,
      ])}
      value={product.id.toString()}
      control={
        <Checkbox
          onChange={() => handleProductSelection(product)}
          disabled={selectedProductCurrency && selectedProductCurrency !== product.currency}
          color="primary"
          icon={<RadioButtonUnchecked color="disabled" />}
          checkedIcon={<CheckCircle />}
        />
      }
    />
  )

  const isProceedBtnDisabled = () => {
    if (!tournamentSite.termsAndConditions) {
      return selectedProductIds.length === 0
    }

    return selectedProductIds.length === 0 || !termsAccepted
  }

  const renderProductsList = () => {
    return (
      <Grid container direction="row" justifyContent="space-around" alignItems="center" style={{ paddingBottom: 40 }}>
        {payments.tournamentProducts?.length > 0 ? (
          <>
            <Grid item>
              <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item>
                  <FormLabel component="legend" style={{ marginBottom: rem(15) }}>
                    <Typography variant="h5">
                      <FormattedMessage id={'payments.selectProducts'} />
                    </Typography>
                  </FormLabel>
                </Grid>
                <Grid item className={classes.itemListContainer}>
                  <FormControl component="fieldset">
                    <RadioGroup name="products-list">
                      {payments.tournamentProducts?.map((product: TournamentProducts) =>
                        renderProductsListItem(product),
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {tournamentSite.termsAndConditions && (
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Checkbox
                      id="acceptTerms"
                      name="acceptTerms"
                      color="primary"
                      required
                      checked={termsAccepted}
                      onChange={() => setTermsAccepted(!termsAccepted)}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <FormattedMessage id="strings.acceptTerms1" />
                      <button onClick={() => setOpenTermsDialog(true)} className={classes.acceptTermsBtn}>
                        <Typography style={{ textDecoration: 'underline' }}>
                          <FormattedMessageWrapper id="strings.acceptTerms2" hasLink />
                        </Typography>
                      </button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleStartPaymentProcess()}
                disabled={isProceedBtnDisabled()}
                className={classes.proceedBtn}
              >
                <FormattedMessage id="buttons.proceedToPayment" />
                {payments.loading && (
                  <CircularProgress size={20} thickness={5} className={classes.circularProgressBtn} />
                )}
              </Button>
            </Grid>
          </>
        ) : (
          renderLoaderOrError()
        )}
      </Grid>
    )
  }

  const handleUserJoinTournament = (args: StartPaymentProcessPayload) => {
    const { team, questionAnswers } = auth
    const { entryMethod } = tournamentSite
    const customQuestions = formatCustomQuestionAnswersForAPI(questionAnswers)

    const parsedTeam = team.map((player) => {
      return {
        ...omit(player, ['dirtyHcp']),
        hcp: player.dirtyHcp ? validateHandicapValue(player.dirtyHcp).value : Number(player.hcp),
      }
    })

    dispatch(
      joinTournament({
        tournamentId: id,
        userId,
        authToken,
        clientUid,
        hcp: validateHandicapValue(hcp).value,
        customQuestions,
        team: entryMethod === EntryMethod.TEAM && !tournamentSite.tournament.noStartTimes ? parsedTeam : null,
        teamName,
        gender,
        teebox,
        divisionId,
        onComplete: () => {
          dispatch(
            startPaymentProcess(args, (terminalUrl: string) => {
              window.location.replace(terminalUrl)
            }),
          )
        },
      }),
    )
  }

  const handleStartPaymentProcess = () => {
    // temporary fix for swedish. Should be fixed in locale/reducer
    const language = locale.appLanguage.code === 'fi-SV' ? 'sv' : locale.appLanguage.code.split('-')[0]

    const tournamentProduct = payments.tournamentProducts
      .filter((product) => selectedProductIds.includes(product.id))
      .map(({ id, organizationProductId, price, vat, currency }: TournamentProducts) => {
        return {
          id,
          organizationProductId,
          price,
          vat,
          currency,
        }
      })

    const args: StartPaymentProcessPayload = {
      authToken,
      clientUid,
      userId,
      tournamentProducts: tournamentProduct,
      language,
    }

    if (payments.tournamentProducts) {
      if (isRegistered) {
        dispatch(
          startPaymentProcess(args, (terminalUrl: string) => {
            window.location.replace(terminalUrl)
          }),
        )
      } else {
        handleUserJoinTournament(args)
      }
    }
  }

  const handleAlertOnPress = useCallback(() => {
    setOpenAlert(false)
    handleReturn()
  }, [setOpenAlert, handleReturn])

  useEffect(() => {
    if (
      !tournamentSite.allowPostponePayment &&
      !payments.tournamentProducts.length &&
      !payments.loading &&
      !payments?.productsFetched
    ) {
      handleSelectedPayment(PaymentTypes.NOW)
    }

    if (payments?.productsFetched && !payments.tournamentProducts.length) {
      handleAlertOnPress()
    }
  }, [
    handleAlertOnPress,
    handleSelectedPayment,
    tournamentSite.allowPostponePayment,
    payments.tournamentProducts,
    payments.loading,
    payments.productsFetched,
  ])

  const handleRender = () => {
    if (showLoader) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={48} thickness={2} />
        </div>
      )
    }

    return (
      <>
        <Alert
          open={openAlert}
          title={<FormattedMessage id="strings.error" />}
          text={<FormattedMessage id="payments.errorMsg" />}
          showCancel={false}
          okAction={() => handleAlertOnPress()}
        />
        <DialogBase
          title={<FormattedMessage id="strings.acceptTerms2" />}
          open={openTermsDialog}
          onClose={() => setOpenTermsDialog(false)}
          className={classes.titleCase}
        >
          <DialogContent>
            <div className={classes.termsContainer}>
              <Markdown>{tournamentSite.termsAndConditions}</Markdown>
            </div>
            <div style={{ marginBottom: 20 }}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => setOpenTermsDialog(false)}
              >
                <FormattedMessage id="buttons.close" />
              </Button>
            </div>
          </DialogContent>
        </DialogBase>
        <div className={classes.paymentStepContainer}>
          {showProducts || !tournamentSite.allowPostponePayment ? renderProductsList() : renderPaymentSelection()}
          {isRegistered && (
            <div>
              <Button color="primary" variant="contained" className={classes.button} onClick={() => handleClose()}>
                <FormattedMessage id="buttons.saveAndExit" />
              </Button>
            </div>
          )}
        </div>
      </>
    )
  }

  return handleRender()
}
