import axios from 'axios'
import { APIRoute } from '../../../config'
import { GetTourAction } from '../actions'
import { put } from 'redux-saga/effects'
import { GET_TOUR_SUCCESS, GET_TOUR_FAILURE } from '../constants'

function* getTourFlow({ tourId }: GetTourAction) {
  try {
    const res = yield axios.get(APIRoute.GET_TOUR(tourId))

    yield put({
      type: GET_TOUR_SUCCESS,
      tour: res.data,
    })
  } catch (e) {
    yield put({
      type: GET_TOUR_FAILURE,
      error: e,
    })
  }
}

export default getTourFlow
