import axios from 'axios'
import { APIRoute } from '../../../config'
import { put } from 'redux-saga/effects'
import { GetTournamentTeamsAction } from '../actions'
import { TOURNAMENT_TEAMS_FAILURE, TOURNAMENT_TEAMS_SUCCESS } from '../constants'

function* doFetchTeams({ tournamentId }: GetTournamentTeamsAction) {
  try {
    const { data } = yield axios.get(APIRoute.GET_TEAMS(tournamentId))
    yield put({ type: TOURNAMENT_TEAMS_SUCCESS, teams: data.teams })
  } catch (error) {
    yield put({ type: TOURNAMENT_TEAMS_FAILURE, error })
  }
}

export default doFetchTeams
