import React from 'react'
import { FormControl, MenuItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { fetchCountries, fetchCountryStates } from '../../store/clubsAndCountries/actions'
import TMSelect from './inputs/TMSelect'

interface OwnProps {
  disabled?: boolean
  raised?: boolean
  error?: boolean
  required?: boolean
  showInnerLabel?: boolean
  countryId?: number
  handleStatesFetch?: boolean
  enableAllOption?: boolean
  onChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: any }>) => void
  style?: React.CSSProperties
}

interface StateProps {
  countries: Country[]
  countriesLoading: boolean
}

interface DispatchProps {
  fetchCountries(): void
  fetchCountryStates(countryId: number): void
}

type Props = OwnProps & StateProps & DispatchProps

class CountryPicker extends React.Component<Props> {
  componentDidMount(): void {
    const { countriesLoading, countries = [], countryId, fetchCountryStates, fetchCountries } = this.props

    if (!countriesLoading && !countries.length) {
      fetchCountries()
    }

    if (countryId) {
      fetchCountryStates(countryId)
    }
  }

  render() {
    const { error, countryId = '', required, enableAllOption = false, disabled = false } = this.props

    return (
      <FormControl fullWidth={true} required={required} error={error} style={this.props.style}>
        <TMSelect
          label={<FormattedMessage id="strings.country" />}
          disabled={disabled}
          error={error}
          required={required}
          value={countryId}
          onChange={this._handleOnChange}
          displayEmpty={enableAllOption}
          name="country-picker"
          id="country-picker"
        >
          {enableAllOption && (
            <MenuItem value="" selected={countryId === ''}>
              All
            </MenuItem>
          )}
          {this._renderCountryItems()}
        </TMSelect>
      </FormControl>
    )
  }

  public _handleOnChange = (event) => {
    const { onChange, handleStatesFetch = false, fetchCountryStates } = this.props

    if (handleStatesFetch) {
      const countryId = parseInt(event.target.value, 10)
      fetchCountryStates(countryId)
    }

    if (onChange) {
      onChange(event)
    }
  }

  private _renderCountryItems = () => {
    const countries = this.props.countries || []
    return countries.map((country) => (
      <MenuItem key={country.id} value={country.id}>
        {country.name}
      </MenuItem>
    ))
  }
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  (state): StateProps => ({
    countries: state.clubsAndCountriesReducer.countries,
    countriesLoading: state.clubsAndCountriesReducer.loading,
  }),
  {
    fetchCountries,
    fetchCountryStates,
  },
)(CountryPicker)
