import {
  getNavigatorLanguageCode,
  getStoredLanguageCode,
  resolveLanguageCode,
  supportedLanguageCodes,
} from '../../localization'
import { SET_LANGUAGE } from './constants'

//TODO: fi-SV is because we need the finnish date formatting, but it has caused some issues and should be fixed
const availableLanguages = supportedLanguageCodes.map((code) => ({ code }))
const savedLanguageCode = getStoredLanguageCode()
const resolvedLanguageCode = resolveLanguageCode(availableLanguages, getNavigatorLanguageCode())
const defaultLanguageCode = savedLanguageCode || resolvedLanguageCode

export const initialState: LocaleState = {
  availableLanguages,
  appLanguage: { code: defaultLanguageCode },
}

type LocaleActions = StoreInitAction | SetLanguage

const localeReducer = (state: LocaleState = initialState, action: LocaleActions): LocaleState => {
  switch (action.type) {
    case SET_LANGUAGE: {
      const { language } = action

      return {
        ...state,
        appLanguage: state.availableLanguages.find(({ code }) => code === language.code),
      }
    }

    default:
      return state
  }
}

export { localeReducer }
