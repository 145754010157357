import { TabsEnum } from '@app/utils/enums'
import { SET_CURRENT_PAGE } from './constants'

interface SetCurrentPageAction {
  type: typeof SET_CURRENT_PAGE
  payload: TabsEnum
}

export type PageActionTypes = SetCurrentPageAction

export const setCurrentPage = (page: TabsEnum): SetCurrentPageAction => ({
  type: SET_CURRENT_PAGE,
  payload: page,
})
