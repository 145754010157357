import React from 'react'
import { Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Carousel, { Modal, ModalGateway } from 'react-images'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 50,
      marginTop: 20,
      justifyContent: 'center',
    },
    container: {
      padding: 5,
      height: 262,
      width: 262,
    },
    imgWrapper: {
      width: 200,
      height: 200,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('md')]: {
        width: 160,
        height: 160,
      },
    },
  })

interface Props {
  images?: TournamentFile[]
}

interface State {
  lightboxIsOpen: boolean
  selectedIndex?: number
}

class Ads extends React.Component<Props & WithStyles<typeof styles>, State> {
  state: State = {
    selectedIndex: 0,
    lightboxIsOpen: false,
  }

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }))
  }

  render() {
    const { images, classes } = this.props
    const { lightboxIsOpen, selectedIndex } = this.state

    if (!images || images.length === 0) {
      return null
    }

    const imgs = []
    images.map((i) => imgs.push({ src: i.url }))

    return (
      <>
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2, md: 2 }}>
          {images.map((img, idx) => (
            <Grid key={`ad-image-${idx}`} item>
              <div
                style={{
                  backgroundImage: `url(${img.url})`,
                }}
                className={classes.imgWrapper}
                onClick={() => this.toggleLightbox(idx)}
              />
            </Grid>
          ))}
        </Grid>

        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={() => this.toggleLightbox(selectedIndex)}>
              <Carousel currentIndex={selectedIndex} views={imgs} />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    )
  }
}

export default withStyles(styles)(Ads)
