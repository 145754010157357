import React from 'react'
import { Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '@global': {
        '.slick-prev, .slick-next': {
          color: '#555555',
        },
        '.slick-next::before, .slick-prev::before': {
          content: 'none',
        },
        '.slick-track': {
          display: 'flex',
          justifyContent: 'space-between',
        },
        '.slick-slide': {
          width: '130px !important',
          [theme.breakpoints.down('md')]: {
            width: '100px !important',
            margin: '0 18px 0 18px',
          },
        },
        '.slick-track .slick-slide': {
          display: 'flex',
          height: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          '& img': {
            padding: 10,
            [theme.breakpoints.down('md')]: {
              padding: 0,
            },
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
    },
    sliderContainer: {
      width: '90%',
      margin: '0 auto',
    },
  })

interface Props {
  images?: TournamentFile[]
}

interface State {
  mapModalOpen: boolean
}

const PrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos />
    </div>
  )
}

const NextArrow = (props) => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos />
    </div>
  )
}

class SponsorLogos extends React.Component<Props & WithStyles<typeof styles>, State> {
  state: State = {
    mapModalOpen: false,
  }

  render() {
    const { images, classes } = this.props

    if (!images || images.length === 0) {
      return null
    }

    const slidesToShowAmount = (images: TournamentFile[], amount) => (images.length < amount ? images.length : amount)

    const settings = {
      dots: false,
      autoplay: true,
      infinite: images.length > 4,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: slidesToShowAmount(images, 3),
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: slidesToShowAmount(images, 3),
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: slidesToShowAmount(images, 4),
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: slidesToShowAmount(images, 4),
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: slidesToShowAmount(images, 6),
          },
        },
      ],
    }

    return (
      <div style={{ width: '100%' }}>
        <Grid container={true} spacing={3}>
          <Grid item xs={12} className={classes.root}>
            <div className={classes.sliderContainer}>
              <Slider {...settings}>
                {images.map((logo) => (
                  <img key={logo.id} src={logo.url} alt="" />
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(SponsorLogos)
