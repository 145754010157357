import { CircularProgress, Theme } from '@mui/material'
import { InnerContentWrap } from '@app/components/ui/ContentWrap'
import makeStyles from '@mui/styles/makeStyles'
import { RankingLeaderboard } from '@app/components/tour/RankingLeaderboard'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.customPalette.lightGray2}`,
    padding: '25px 50px',
    textAlign: 'center',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
      '& p': {
        paddingBottom: 30,
      },
    },
  },
}))

const TourRankings = () => {
  const classes = useStyles()
  const { tour, rankings, isRankingLoading, error } = useSelector((store: StoreState) => store.tourAndRankingReducer)

  if (!tour) {
    return null
  }

  const shouldShowLoading: boolean =
    isRankingLoading || (rankings.length > 0 && rankings.length < tour.rankingIds.length && !error)

  if (shouldShowLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0' }}>
        <CircularProgress size={48} thickness={2} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {tour.rankingIds.map((ranking) => (
        <InnerContentWrap key={ranking}>
          <RankingLeaderboard id={ranking} />
        </InnerContentWrap>
      ))}
    </div>
  )
}

export default TourRankings
