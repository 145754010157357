export const LOCAL_CLUBS_FETCH = 'LOCAL_CLUBS_FETCH'
export const LOCAL_CLUBS_FETCH_SUCCESS = 'LOCAL_CLUBS_FETCH_SUCCESS'
export const LOCAL_CLUBS_FETCH_FAILURE = 'LOCAL_CLUBS_FETCH_FAILURE'

export const UHS_CLUBS_FETCH = 'UHS_CLUBS_FETCH'
export const UHS_CLUBS_FETCH_SUCCESS = 'UHS_CLUBS_FETCH_SUCCESS'
export const UHS_CLUBS_FETCH_FAILURE = 'UHS_CLUBS_FETCH_FAILURE'

export const COUNTRIES_FETCH = 'COUNTRIES_FETCH'
export const COUNTRIES_FETCH_SUCCESS = 'COUNTRIES_FETCH_SUCCESS'
export const COUNTRIES_FETCH_FAILURE = 'COUNTRIES_FETCH_FAILURE'

export const COUNTRY_STATES_FETCH = 'COUNTRY_STATES_FETCH'
export const COUNTRY_STATES_FETCH_SUCCESS = 'COUNTRY_STATES_FETCH_SUCCESS'
export const COUNTRY_STATES_FETCH_FAILURE = 'COUNTRY_STATES_FETCH_FAILURE'
