export const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE

export const formAPIRoot = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL || 'http://localhost:9981/'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-api.staging.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-api.rc.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-api.golfgamebook.com/'
  } else {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  }
}

export const API_ROOT: string = formAPIRoot()
export const APIRoute = {
  POST_LOGIN: () => `${API_ROOT}api/1/login`,
  POST_LOGIN_MAP: () => `${API_ROOT}api/1/login/map`, //TODO:PHASE:CLEAN_UP Remove this and related code.
  POST_CORE_REGISTER_AND_LOGIN: () => `${API_ROOT}api/1/core/registration`,
  POST_CORE_LOGIN: () => `${API_ROOT}api/1/core/login`,
  POST_CORE_LOGIN_APPLE: () => `${API_ROOT}api/1/core/login/apple`,
  GET_TOURNAMENT: (identifier: number) => `${API_ROOT}api/1/site/tournament/${identifier}`,
  REGISTER: (identifier: number) => `${API_ROOT}api/1/site/tournament/${identifier}/registration`,
  DELETE_LEAVE_TOURNAMENT: (identifier: number) => `${API_ROOT}api/1/site/tournament/${identifier}/registration`,
  GET_PLAYER_SEARCH_REGISTRY: (tournamentId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/players/search/provider`,
  GET_REGISTRATIONS: (tournamentId: number, userId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/user/${userId}/registrations`,
  GET_PLAYERS: (identifier: string | number) => `${API_ROOT}api/1/site/tournament/${identifier}/players`,
  GET_TEAMS: (identifier: string | number) => `${API_ROOT}api/1/site/tournament/${identifier}/teams`,
  GET_STARTLISTS: (identifier: string | number) => `${API_ROOT}api/1/site/tournament/${identifier}/startlists`,
  GET_LEADERBOARD: (identifier: string | number) => `${API_ROOT}api/1/site/tournament/${identifier}/leaderboard`,
  GET_LEADERBOARD_WITH_DIVISIONS: (tournamentId: number, divisionId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/division/${divisionId}/leaderboard`,
  GET_CONTEST_LEADERBOARD: (identifier: string | number) =>
    `${API_ROOT}api/1/site/tournament/${identifier}/contest-leaderboard`,
  GET_COUNTRIES: `${API_ROOT}api/1/countries`,
  GET_COUNTRY_STATES: (countryId: number) => `${API_ROOT}api/1/country/${countryId}/states`,
  GET_SEARCH_CLUBS: `${API_ROOT}api/1/site/clubs/search`,
  GET_CLUBS_UHS: `${API_ROOT}api/1/site/clubs`,
  GET_TOUR: (tourId: number) => `${API_ROOT}api/1/site/tour/${tourId}`,
  GET_RANKING: (rankingId: number) => `${API_ROOT}api/1/site/ranking/${rankingId}/leaderboard`,
  GET_CALENDAR: (calendarId: string) => `${API_ROOT}api/1/site/calendar/${calendarId}`,
  GET_ORGANIZATION_PRODUCTS: (organizationId: string) => `${API_ROOT}api/1/organization/${organizationId}/products`,
  GET_TOURNAMENT_PRODUCTS: (tournamentId: string) => `${API_ROOT}api/1/site/tournament/${tournamentId}/products`,
  GET_PURCHASES: (tournamentId: string) => `${API_ROOT}api/1/site/tournament/${tournamentId}/payments`,
  START_PAYMENT_PROCESS: (tournamentId: string) => `${API_ROOT}api/1/site/tournament/${tournamentId}/payment`,
  POST_VERIFY_NETS_PAYMENT: (tournamentId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/payment/nets-return`,
  GET_BOUGHT_PRODUCTS: (tournamentId: string) => `${API_ROOT}api/1/site/tournament/${tournamentId}/payments`,
  GET_CURRENCIES: `${API_ROOT}api/1/currencies`,
}

export const LOCAL_STORAGE_LANG_KEY = 'tournament_lang_token'

export const SITE_TITLE = 'Golf GameBook Tournament'
export const TITLE_DIVIDER = ' | '

const getTournamentSiteDomain = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3099'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-site.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-site.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-site.golfgamebook.com'
  } else {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  }
}

export const formTournamentSiteUrl = (id: number): string => {
  return `${getTournamentSiteDomain()}/tournament/${id}`
}

export const formTourSiteUrl = (id: number): string => {
  return `${getTournamentSiteDomain()}/tour/${id}`
}

export const getOfficeRoot = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://office.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://office.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://office.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://office.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://office.golfgamebook.com'
  } else {
    return 'https://office.sandbox.golfgamebook.com'
  }
}

export const getAppleLoginRedirectUrl = () => {
  if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-site.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-site.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-site.golfgamebook.com'
  } else {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  }
}

export const getDefaultProfileImage = () => {
  const env = process.env.REACT_APP_ENV || 'sandbox'
  if (env === 'production') {
    return 'https://static.golfgamebook.com/defaults/avatar.png'
  }
  return `https://static.${env}.golfgamebook.com/defaults/avatar.png`
}
