import { ImagesAndSignUp } from '@app/components/tournament/ImagesAndSignUp'
import { Leaderboards } from '@app/components/tournament/Leaderboards'
import SummaryText from '@app/components/tournament/SummaryText'
import { LeaderboardTabs } from '@app/store/tournamentContestLeaderboard/enums'
import { Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  topContainer: {
    borderBottom: `1px solid ${theme.customPalette.lightGray2}`,
  },
  middleContainer: {
    borderBottom: `1px solid ${theme.customPalette.lightGray2}`,
    paddingBottom: 40,
  },
  notPublished: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
}))

interface Props {
  isPreview: boolean
}

export const SummaryPage = ({ isPreview }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const { tournamentSite } = useSelector((store: StoreState) => store.tournamentReducer)
  const tournament = tournamentSite?.tournament

  if (!tournament?.resultsPublished && !isPreview) {
    return <div className={classes.notPublished}>{intl.formatMessage({ id: 'strings.summaryNotPublished' })}</div>
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item sx={{ width: 1 }} className={classes.topContainer}>
        <SummaryText />
      </Grid>
      <Grid item sx={{ width: 1 }} className={classes.middleContainer}>
        <Leaderboards selectedTab={LeaderboardTabs.LEADERBOARD_TYPE_LEADERBOARD} />
      </Grid>
      <ImagesAndSignUp isSummaryPage={true} />
    </Grid>
  )
}
