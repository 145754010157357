import React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Theme } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { FormattedMessage } from 'react-intl'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '../../theme/materialUITheme'
import { Slider } from '@mui/material'

const ITEM_HEIGHT = 40
const DEFAULT_SCROLLING_SPEED = 40
const rollingSpeedOptions = [
  { value: 20, label: '1x' },
  { value: 40, label: '1.5x' },
  { value: 60, label: '2x' },
]

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 3,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: rem(12),
  },
  paper: {
    backgroundColor: theme.customPalette.mediumGray2,
    maxHeight: ITEM_HEIGHT * 4.5,
  },
  on: {
    color: theme.customPalette.success,
  },
  off: {
    color: theme.customPalette.error,
  },
  sliderContainer: {
    '& .MuiSlider-thumb, & .MuiSlider-track, & .MuiSlider-rail, & .MuiSlider-active': {
      color: '#fff',
    },
    '& .MuiSlider-markLabel': {
      color: '#fff',
      fontSize: rem(12),
    },
  },
}))

export default function ScreenMenu(props: {
  selected: string
  setSelected: (name: string) => void
  rolling: boolean
  setRolling: (value: boolean) => void
  rollingSpeed: number
  setRollingSpeed: (value: number) => void
  setIsMenuOpen: (value: boolean) => void
  sideGameEnabled: boolean
  gameModeNames: {
    primary: string
    side: string
  }
}) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    props.setIsMenuOpen(true)
    props.setRollingSpeed(DEFAULT_SCROLLING_SPEED)
  }

  const handleClose = (value: string) => {
    if (['primary', 'side'].includes(value)) {
      props.setSelected(value)
    }
    setAnchorEl(null)
    props.setIsMenuOpen(false)
  }

  const handleRollingToggle = () => {
    props.setRolling(!props.rolling)
  }

  const handleSpeedChange = (e: React.ChangeEvent<HTMLElement>, value: number): void => {
    e.stopPropagation()
    props.setRollingSpeed(value)
  }

  return (
    <div style={{ marginLeft: 10 }}>
      <IconButton
        aria-label="more"
        aria-controls="screen-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 0 }}
        size="large"
      >
        <MenuIcon htmlColor={'#fff'} fontSize={'large'} />
      </IconButton>
      <Menu
        id="screen-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <MenuItem
          selected={props.selected === 'primary'}
          onClick={() => handleClose('primary')}
          className={classes.menuItem}
        >
          <ArrowRight fontSize={'small'} />
          {props.gameModeNames['primary']}
        </MenuItem>
        {props.sideGameEnabled && (
          <MenuItem
            selected={props.selected === 'side'}
            onClick={() => handleClose('side')}
            className={classes.menuItem}
          >
            <ArrowRight fontSize={'small'} />
            {props.gameModeNames['side']}
          </MenuItem>
        )}
        <MenuItem onClick={handleRollingToggle} className={classes.menuItem}>
          {props.rolling ? <ArrowDropDown fontSize={'small'} /> : <ArrowRight fontSize={'small'} />}
          <FormattedMessage id="leaderboard.rolling" />
          &nbsp;&nbsp;
          <span className={props.rolling ? classes.on : classes.off}>
            <FormattedMessage id={props.rolling ? 'leaderboard.on' : 'leaderboard.off'} />
          </span>
        </MenuItem>
        {props.rolling && (
          <MenuItem onClick={(e) => e.stopPropagation()} className={classes.sliderContainer}>
            <Slider
              value={props.rollingSpeed}
              step={null}
              marks={rollingSpeedOptions}
              onChangeCommitted={handleSpeedChange}
              min={20}
              max={60}
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
