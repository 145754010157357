import {
  TOURNAMENT_LOAD_PLAYERS,
  TOURNAMENT_LOAD_PLAYERS_SUCCESS,
  TOURNAMENT_LOAD_PLAYERS_FAILURE,
  TOURNAMENT_SET_PLAYER_POOL,
  TOURNAMENT_SET_RESERVE_LIST,
  TOURNAMENT_TEAMS_SUCCESS,
  TOURNAMENT_TEAMS_FAILURE,
} from './constants'
import {
  LoadTournamentPlayersAction,
  LoadTournamentPlayersFailureAction,
  LoadTournamentPlayersSuccessAction,
  SetPlayerPoolAction,
  SetReserveListAction,
  GetTournamentTeamsAction,
  GetTournamentTeamsSuccessAction,
  GetTournamentTeamsFailureAction,
} from './actions'

export const initialState: TournamentPlayersState = {
  players: [],
  teams: [],
  reserveList: [],
  error: undefined,
  loading: false,
}

type TournamentPlayerActions =
  | SetPlayerPoolAction
  | SetReserveListAction
  | LoadTournamentPlayersAction
  | LoadTournamentPlayersSuccessAction
  | LoadTournamentPlayersFailureAction
  | GetTournamentTeamsAction
  | GetTournamentTeamsSuccessAction
  | GetTournamentTeamsFailureAction

const tournamentPlayersReducer = (state: TournamentPlayersState = initialState, action: TournamentPlayerActions) => {
  switch (action.type) {
    case TOURNAMENT_LOAD_PLAYERS:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENT_LOAD_PLAYERS_SUCCESS:
      const { loading = false } = action
      return {
        ...state,
        loading,
      }
    case TOURNAMENT_LOAD_PLAYERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case TOURNAMENT_SET_PLAYER_POOL:
      return {
        ...state,
        loading: false,
        players: action.players,
      }
    case TOURNAMENT_SET_RESERVE_LIST:
      return {
        ...state,
        reserveList: action.reserveList,
      }
    case TOURNAMENT_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.teams,
      }
    case TOURNAMENT_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export { tournamentPlayersReducer }
