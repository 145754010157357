import { ImagesAndSignUp } from '@app/components/tournament/ImagesAndSignUp'
import { Leaderboards } from '@app/components/tournament/Leaderboards'
import { LeaderboardTabs } from '@app/store/tournamentContestLeaderboard/enums'
import { Grid } from '@mui/material'

export const StartListPage = () => {
  return (
    <Grid container>
      <Grid item sx={{ width: 1 }} style={{ paddingBottom: 40 }}>
        <Leaderboards selectedTab={LeaderboardTabs.LEADERBOARD_TYPE_START_TIMES} showOptions />
      </Grid>
      <ImagesAndSignUp alwaysShowSignUp />
    </Grid>
  )
}
