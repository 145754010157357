import moment from 'moment'
import { GameFormats, TournamentTypes, EntryMethod } from './enums'
import { defaultFormat } from './date'

const twoManFormats = [
  GameFormats.STROKE_PLAY_BETTER_BALL,
  GameFormats.STABLEFORD_BETTER_BALL,
  GameFormats.STROKE_PLAY_SCRAMBLE_2_MAN,
  GameFormats.STROKE_PLAY_GREENSOME,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STROKE_PLAY_FOURSOME,
  GameFormats.STABLEFORD_FOURSOME,
]

const threeOrMoreManFormats = [
  GameFormats.STROKE_PLAY_BEST_BALL,
  GameFormats.STROKE_PLAY_SCRAMBLE,
  GameFormats.STROKE_PLAY_LOW_SCRATCH_NET,
  GameFormats.STABLEFORD_BEST_BALL,
  GameFormats.STABLEFORD_SCRAMBLE_DUPLICATE,
  GameFormats.IRISH_RUMBLE_BEST_BALL,
  GameFormats.TEAM_PAR_IS_YOUR_FRIEND,
]

const specialTeamFormats = [
  GameFormats.STROKE_PLAY_SCRAMBLE,
  GameFormats.MATCH_PLAY_SCRAMBLE,
  GameFormats.STROKE_PLAY_SCRAMBLE_2_MAN,
  GameFormats.STROKE_PLAY_GREENSOME,
  GameFormats.STROKE_PLAY_FOURSOME,
  GameFormats.STABLEFORD_SCRAMBLE_DUPLICATE,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STABLEFORD_FOURSOME,
  GameFormats.MATCH_PLAY_GREENSOME,
  GameFormats.MATCH_PLAY_FOURSOME,
]

const stablefordFormats = [
  GameFormats.STABLEFORD_BETTER_BALL,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STABLEFORD_FOURSOME,
  GameFormats.STABLEFORD_BEST_BALL,
  GameFormats.STABLEFORD_SCRAMBLE_DUPLICATE,
  GameFormats.STABLEFORD_INDIVIDUAL_DUPLICATE,
  GameFormats.STABLEFORD_INDIVIDUAL,
]

export const separatelyScoredTeamFormats = [
  GameFormats.STROKE_PLAY_BETTER_BALL,
  GameFormats.STABLEFORD_BETTER_BALL,
  GameFormats.STROKE_PLAY_BEST_BALL,
  GameFormats.STABLEFORD_BEST_BALL,
  GameFormats.IRISH_RUMBLE_BEST_BALL,
  GameFormats.STROKE_PLAY_BETTER_BALL,
  GameFormats.TEAM_PAR_IS_YOUR_FRIEND,
  GameFormats.STROKE_PLAY_LOW_SCRATCH_NET,
]

export const isIndividualFormatId = (formatId: number): boolean =>
  !twoManFormats.includes(formatId) && !threeOrMoreManFormats.includes(formatId)

export const isTeamFormatId = (formatId: number): boolean =>
  twoManFormats.includes(formatId) || threeOrMoreManFormats.includes(formatId)

export const isTwoPlayerFormatId = (formatId: number) => twoManFormats.includes(formatId)

export const isSpecialTeamFormatId = (formatId: number) => specialTeamFormats.includes(formatId)

export const isSeparatelyScoredTeamFormatId = (formatId: number) => separatelyScoredTeamFormats.includes(formatId)

export const isTogetherScoredTeamFormatId = (formatId: number) =>
  isTeamFormatId(formatId) && !isSeparatelyScoredTeamFormatId(formatId)

export const isStablefordFormat = (formatId: number) => stablefordFormats.includes(formatId)

export const showStablefordPoints = (id?: number) => (id ? isStablefordFormat(id) : false)

export const getActiveRoundNumber = (rounds: TournamentRound[]) => {
  // Find rounds with ongoing scoring
  const sortedRoundsDesc = rounds.slice().sort((a, b) => b.roundNumber - a.roundNumber)
  let round: TournamentRound = sortedRoundsDesc.find((r) => {
    const isScoringEnabled = typeof r.status.isScoringDisabled !== 'undefined' && !r.status.isScoringDisabled
    return isScoringEnabled && !r.status.isCompleted
  })

  // Find rounds with scoring not started if not found
  if (!round) {
    round = rounds.find((r) => r.status.isScoringDisabled && !r.status.isCompleted)
  }

  // If not found, check if last round scoring has already ended
  if (!round && rounds[rounds.length - 1].status.isCompleted) {
    round = rounds[rounds.length - 1]
  }

  // If no round index found, return first round
  return round ? round.roundNumber : 1
}

export const validMaleTeeBoxes = (courseTees: TeeBox[]) => {
  return courseTees.filter((tee) => tee.crMen > 0 && tee.slopeMen > 0)
}

export const validFemaleTeeBoxes = (courseTees: TeeBox[]) => {
  return courseTees.filter((tee) => tee.crWomen > 0 && tee.slopeWomen > 0)
}

//TODO: refactor to use country codes. ID's are primary keys and are unreliable.
export const FI_COUNTRY_ID = 73
export const SV_COUNTRY_ID = 204

/**
 * Get date for tournament title
 * @param tournamentSite
 * @returns
 */
export const getTimeForTitle = (tournamentSite: TournamentSite) => {
  const { startTime, endTime } = tournamentSite.tournament
  if (tournamentSite.tournament.tournamentType === TournamentTypes.weekly) {
    return `${moment(startTime).format(defaultFormat('date', tournamentSite.units))} - ${moment(endTime).format(
      defaultFormat('date', tournamentSite.units),
    )}`
  } else if (startTime) {
    return moment(startTime).format(defaultFormat('date', tournamentSite.units))
  }
  return null
}

/**
 * Get player/team entry amount
 * @param tournamentSite
 * @param players
 * @returns
 */
export const getEntryAmount = (tournamentSite: TournamentSite, players: TournamentPlayersState) => {
  const isTeamFormat =
    tournamentSite.tournament.isTeamFormat &&
    tournamentSite.onlineEntriesEnabled &&
    tournamentSite.entryMethod === EntryMethod.TEAM
  return isTeamFormat ? players.teams?.length : players.players.length + players.reserveList.length
}

/**
 * Check if tournament is full
 * @param tournamentSite
 * @param players
 * @returns
 */
export const maxEntriesAmountExceeded = (tournamentSite: TournamentSite, players: TournamentPlayersState) => {
  const entryAmount = getEntryAmount(tournamentSite, players)
  return !tournamentSite.maximumPlayerAmount
    ? entryAmount < tournamentSite.maximumTeamAmount
    : entryAmount < tournamentSite.maximumPlayerAmount
}

export const isCountrySweden = (countryId?: number) => countryId === SV_COUNTRY_ID

export const isCountryWithPlayerRegistry = (organization?: Organization) => {
  if (!organization) {
    return false
  }

  const { club } = organization

  if (!club || !club.id) {
    return false
  }

  if (club.countryId === FI_COUNTRY_ID || club.countryId === SV_COUNTRY_ID) {
    return true
  }

  return false
}
