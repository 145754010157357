import {
  TOURNAMENT_LOAD_LEADERBOARD,
  TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
} from './constants'
import {
  LoadTournamentLeaderboardAction,
  LoadTournamentLeaderboardFailureAction,
  LoadTournamentLeaderboardSuccessAction,
} from './actions'

export const initialState: TournamentLeaderboardState = {
  data: undefined,
  error: undefined,
  loading: false,
}

type TournamentLeaderboardActions =
  | LoadTournamentLeaderboardAction
  | LoadTournamentLeaderboardSuccessAction
  | LoadTournamentLeaderboardFailureAction

const tournamentLeaderboardReducer = (
  state: TournamentLeaderboardState = initialState,
  action: TournamentLeaderboardActions,
) => {
  switch (action.type) {
    case TOURNAMENT_LOAD_LEADERBOARD:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENT_LOAD_LEADERBOARD_SUCCESS:
      const { loading = false } = action
      return {
        ...state,
        loading,
        data: action.data,
      }
    case TOURNAMENT_LOAD_LEADERBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    default:
      return state
  }
}

export { tournamentLeaderboardReducer }
