import { takeEvery, put, all } from 'redux-saga/effects'
import {
  COUNTRIES_FETCH,
  COUNTRIES_FETCH_SUCCESS,
  COUNTRIES_FETCH_FAILURE,
  COUNTRY_STATES_FETCH_FAILURE,
  COUNTRY_STATES_FETCH,
  LOCAL_CLUBS_FETCH,
  LOCAL_CLUBS_FETCH_SUCCESS,
  LOCAL_CLUBS_FETCH_FAILURE,
  UHS_CLUBS_FETCH_SUCCESS,
  UHS_CLUBS_FETCH_FAILURE,
  UHS_CLUBS_FETCH,
} from './constants'
import { fetchCountries, fetchCountryStates, searchClubs, getUHSClubs } from './clubApiActions'
import { FetchCountryStatesAction, fetchCountryStatesSuccess, LocalFetchClubsAction } from './actions'

function* doFetchCountries() {
  try {
    const countries = yield fetchCountries()
    yield put({
      type: COUNTRIES_FETCH_SUCCESS,
      countries,
    })
  } catch (error) {
    yield put({
      type: COUNTRIES_FETCH_FAILURE,
      error,
    })
  }
}

function* doFetchCountryStates({ countryId }: FetchCountryStatesAction) {
  try {
    const states = yield fetchCountryStates(countryId)
    yield put(fetchCountryStatesSuccess(states))
  } catch (error) {
    yield put({
      type: COUNTRY_STATES_FETCH_FAILURE,
      error,
    })
  }
}

function* doLocalFetchClubs({ searchTerm, onComplete, countryId }: LocalFetchClubsAction) {
  try {
    const clubs: Club[] = yield searchClubs(searchTerm, countryId)

    yield put({
      type: LOCAL_CLUBS_FETCH_SUCCESS,
      clubs,
    })
  } catch (error) {
    yield put({
      type: LOCAL_CLUBS_FETCH_FAILURE,
      error,
    })

    if (onComplete) {
      onComplete({
        error,
      })
    }
  }
}

function* doFetchUHSClubs() {
  try {
    const clubs: Club[] = yield getUHSClubs()

    yield put({
      type: UHS_CLUBS_FETCH_SUCCESS,
      clubs,
    })
  } catch (error) {
    yield put({
      type: UHS_CLUBS_FETCH_FAILURE,
      error,
    })
  }
}

export function* watchClubsAndCountries() {
  yield all([
    takeEvery(COUNTRIES_FETCH, doFetchCountries),
    takeEvery(COUNTRY_STATES_FETCH, doFetchCountryStates),
    takeEvery(LOCAL_CLUBS_FETCH, doLocalFetchClubs),
    takeEvery(UHS_CLUBS_FETCH, doFetchUHSClubs),
  ])
}
