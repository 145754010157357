import axios from 'axios'
import { APIRoute } from '../../../config'
import { put } from 'redux-saga/effects'
import { getCalendarError, getCalendarSuccess, GetCalendarAction } from '../actions'

function* getCalendarFlow({ calendarId }: GetCalendarAction) {
  try {
    const res = yield axios.get(APIRoute.GET_CALENDAR(calendarId))

    if (res && !res.error) {
      yield put(getCalendarSuccess(calendarId, res.data))
    } else {
      yield put(getCalendarError(res.data.error))
    }
  } catch (error) {
    yield put(getCalendarError(error))
  }
}

export default getCalendarFlow
