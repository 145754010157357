import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import ButtonBase from '@mui/material/ButtonBase'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: '10px 0',
    },
    buttonBase: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontWeight: 500,
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      display: 'flex',
      flexDirection: 'row',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: any
  component?: any
  onClick?: any
  style?: CSSProperties
}

class TextButton extends React.Component<Props> {
  render(): React.ReactNode {
    const { children, classes, component, onClick, style } = this.props

    return (
      <ButtonBase style={style} className={classes.buttonBase} component={component} onClick={onClick}>
        <span className={classes.label}>{children}</span>
      </ButtonBase>
    )
  }
}

export default withStyles(styles)(TextButton)
