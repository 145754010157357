import { DEFAULT_LANGUAGE_CODE, getStoredLanguageCode, resolveLanguageCode, storeLanguageCode } from '@app/localization'
import { setLanguage } from '@app/store/locale/actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useLanguage = () => {
  const dispatch = useDispatch()
  const localeState = useSelector((state: StoreState) => state.localeReducer)
  const savedLanguageCode = getStoredLanguageCode()

  const setLanguageCode = useCallback(
    (languageCode: string) => {
      const resolvedLanguageCode = resolveLanguageCode(localeState.availableLanguages, languageCode)
      storeLanguageCode(resolvedLanguageCode)
      dispatch(setLanguage({ code: resolvedLanguageCode }))
    },
    [dispatch, localeState.availableLanguages],
  )

  /**
   * 1. get language from local storage
   * 2. get language from state (defaults to navigator language)
   * 3. use default language
   */
  return {
    languageCode: savedLanguageCode || localeState.appLanguage.code || DEFAULT_LANGUAGE_CODE,
    setLanguageCode,
  }
}
