import { takeEvery, put, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  TOURNAMENT_LOAD_LEADERBOARD,
  TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
} from './constants'
import { APIRoute } from '../../config'
import { LoadTournamentLeaderboardAction } from './actions'

function* doTournamentLoadLeaderboard({ id, divisionId, onSuccess }: LoadTournamentLeaderboardAction) {
  try {
    let res
    if (divisionId > 0) {
      res = yield axios.get(APIRoute.GET_LEADERBOARD_WITH_DIVISIONS(id, divisionId))
    } else {
      res = yield axios.get(APIRoute.GET_LEADERBOARD(id))
    }

    if (res && res.data.success === true && res.data.data) {
      yield put({
        type: TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
        data: res.data.data ? res.data.data : undefined,
      })
      if (onSuccess) {
        onSuccess()
      }
    } else {
      yield put({
        type: TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
        error: res.error,
      })
    }
  } catch (e) {
    console.log(`Tournament leaderboard ${id} requested - ${e}`)
    yield put({
      type: TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
      error: e,
    })
  }
}

export function* watchTournamentLeaderboard() {
  yield all([takeEvery(TOURNAMENT_LOAD_LEADERBOARD, doTournamentLoadLeaderboard)])
}
