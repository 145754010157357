import { all, spawn } from 'redux-saga/effects'
import { watchAuthentication } from './authentication/sagas'
import { watchTournament } from './tournament/sagas'
import { watchTournamentPlayers } from './tournamentPlayers/sagas'
import { watchTournamentStartlists } from './tournamentStartlists/sagas'
import { watchTournamentLeaderboard } from './tournamentLeaderboard/sagas'
import { watchTournamentContestLeaderboard } from './tournamentContestLeaderboard/sagas'
import { watchRegistration } from './registrations/sagas'
import { watchClubsAndCountries } from './clubsAndCountries/sagas'
import { watchTourAndRanking } from './tourAndRanking/sagas'
import { watchCalendar } from './calendar/sagas'
import { watchPayment } from './payments/sagas'

export default function* sagas() {
  yield all([
    spawn(watchAuthentication),
    spawn(watchTournament),
    spawn(watchTournamentPlayers),
    spawn(watchRegistration),
    spawn(watchTournamentStartlists),
    spawn(watchTournamentLeaderboard),
    spawn(watchTournamentContestLeaderboard),
    spawn(watchClubsAndCountries),
    spawn(watchTourAndRanking),
    spawn(watchCalendar),
    spawn(watchPayment),
  ])
}
