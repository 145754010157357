import axios from 'axios'
import { APIRoute } from '../../../config'
import { JoinTournamentAction } from '../actions'

function* editRegistrationFlow({
  tournamentId,
  authToken,
  userId,
  clientUid,
  hcp,
  customQuestions,
  team,
  teamName,
  teebox,
  divisionId,
  onComplete,
}: JoinTournamentAction) {
  try {
    yield axios.put(APIRoute.REGISTER(tournamentId), {
      authToken,
      userId,
      clientUid,
      hcp,
      customQuestions,
      team,
      teamName,
      teebox,
      divisionId,
    })
    onComplete(true)
  } catch (error) {
    onComplete(false, false, error)
  }
}

export default editRegistrationFlow
