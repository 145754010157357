import { Page } from '@app/components/layout/Page'
import { formatDefaultTourSeo } from '../../components/seo/utils'
import { InnerContentWrap } from '../../components/ui/ContentWrap'
import SponsorLogos from '../../components/tournament/SponsorLogos'
import Ads from '../../components/tournament/Ads'
import OverlayLoader from '../../components/ui/OverlayLoader'
import TourTitleBlock from '../../components/tour/TourTitleBlock'
import { useAppState } from '@app/hooks'
import { Navigation } from '@app/components/layout/Navigation'

export const TournamentTourSite = () => {
  const {
    tourAndRankingState: { tour, isTourLoading },
  } = useAppState({ autoloadResources: ['tour'] })

  if (!tour || isTourLoading) {
    return <OverlayLoader />
  }

  return (
    <Page seo={formatDefaultTourSeo({ tour })} hero={tour.hero}>
      <InnerContentWrap>
        <TourTitleBlock />
      </InnerContentWrap>

      <InnerContentWrap>
        <Navigation isTour={true} />
      </InnerContentWrap>

      {Boolean(tour.sponsorLogos && tour.sponsorLogos.length) && (
        <InnerContentWrap>
          <SponsorLogos images={tour.sponsorLogos} />
        </InnerContentWrap>
      )}

      {Boolean(tour.ads && tour.ads.length) && (
        <InnerContentWrap>
          <Ads images={tour.ads} />
        </InnerContentWrap>
      )}
    </Page>
  )
}
