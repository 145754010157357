import { GET_TOUR, GET_RANKING } from './constants'

export interface GetTourAction extends Action {
  type: 'GET_TOUR'
  tourId: number
}

export interface GetTourSuccessAction extends Action {
  type: 'GET_TOUR_SUCCESS'
  tour: Tour
}

export interface GetTourFailureAction extends Action {
  type: 'GET_TOUR_FAILURE'
  error: string
}

export const getTour = (tourId: number): GetTourAction => ({
  type: GET_TOUR,
  tourId,
})

export interface GetRankingAction extends Action {
  type: 'GET_RANKING'
  rankingId: number
}

export interface GetRankingSuccessAction extends Action {
  type: 'GET_RANKING_SUCCESS'
  ranking: Ranking
}

export interface GetRankingFailureAction extends Action {
  type: 'GET_RANKING_FAILURE'
  error: string
}

export const getRanking = (rankingId: number): GetRankingAction => ({
  type: GET_RANKING,
  rankingId,
})
