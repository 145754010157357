import { PageActionTypes } from './actions'
import { SET_CURRENT_PAGE } from './constants'

export const initialState: PageState = {
  currentPage: '',
}

export const pageReducer = (state = initialState, action: PageActionTypes): PageState => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { currentPage: action.payload }
    default:
      return state
  }
}
