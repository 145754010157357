export const testHandicapValueString = (value: number | string): RegExpMatchArray | null => {
  const valToString = String(value)
  return valToString.match(/^\+?\d+([.,]\d*)?$/)
}

export interface ValidateHandicapValueResult {
  value?: number
  valid: boolean
  test: RegExpMatchArray | null
}

const validateHandicapValueError: ValidateHandicapValueResult = {
  value: undefined,
  valid: false,
  test: null,
}

// TODO: Should evaluate if we can make changes to this function to make it use more clear.
// Currently it handles both normal handicap values as numbers and dirty handicap values as strings.
export const validateHandicapValue = (value: number | undefined | string): ValidateHandicapValueResult => {
  if (!value && value !== 0) {
    return validateHandicapValueError
  }

  const test = testHandicapValueString(value)

  if (test === null) {
    return validateHandicapValueError
  }

  const hcpValue = test[0].replace(/,/g, '.')
  const isPlusHandicap = hcpValue.startsWith('+')

  const hcpIsTooLow = isPlusHandicap && Number(hcpValue) > 10
  const hcpIsTooHigh = !isPlusHandicap && Number(hcpValue) > 54
  const hcpNotInRange = hcpIsTooLow || hcpIsTooHigh
  if (hcpNotInRange) {
    return validateHandicapValueError
  }

  let hcpFloatValue: number
  const isDirtyHcpValue = typeof value === 'string'

  if (isDirtyHcpValue) {
    hcpFloatValue = hcpValue.startsWith('+') ? parseFloat(hcpValue) : -1 * parseFloat(hcpValue)
  } else {
    hcpFloatValue = parseFloat(hcpValue)
  }

  if (Object.is(hcpFloatValue, -0)) {
    hcpFloatValue = 0
  }

  return {
    value: hcpFloatValue,
    valid: true,
    test,
  }
}

export const formatHcpToDirtyHcp = (hcp: number): string => {
  return hcp <= 0 ? `${Math.abs(hcp)}` : `+${Math.abs(hcp)}`
}

export const formatHandicap = (hcp: string): string => {
  if (hcp === '0' || hcp === '' || hcp === '+') {
    return hcp
  }
  if (hcp.includes('-')) {
    return hcp.replace('-', '')
  }
  return hcp.includes('+') ? hcp : `+${hcp}`
}

export const formatDirtyHcpToHcp = (dirtyHcp: string): number => {
  const hcp = dirtyHcp.replace(',', '.')
  if (!hcp || hcp === '+' || hcp === '-') {
    return null
  }

  if (hcp.includes('+')) {
    return Number(hcp)
  }

  return Number(hcp) * -1
}

export const updateTeamMembersHcp = (team: TeamMember[]): TeamMember[] =>
  team.map((member) => {
    if (member.dirtyHcp) {
      const { dirtyHcp, ...rest } = member
      return {
        ...rest,
        hcp: formatDirtyHcpToHcp(dirtyHcp),
      }
    }

    return { ...member, hcp: Number(member.hcp) }
  })

export const isTeamCreator = (teams: Team[], user: GGBUser): boolean => {
  const teamPlayer = teams
    ?.map((team) => team.players)
    .flat()
    .find((player) => player.userId === Number(user.userId))

  // If player is not in any team, we can assume that player has to be team creator
  if (!teamPlayer) {
    return true
  }
  return teamPlayer.teamPlayerOrder === 1 || teamPlayer.teamPlayerOrder === undefined
}

export const trimHcpDecimals = (hcp: string): string => {
  // Allow optional + sign, 2 digits, optional decimal point, optional 1 digit
  const regex = /^(?:\+)?(\d{0,2})([.,](\d)?)?$/
  const value = hcp.replace(',', '.')

  if (!regex.test(value)) {
    return value.substring(0, value.length - 1)
  }

  return value
}

export const sanitizeHcpInputValue = (value: string): string => {
  const hcp = trimHcpDecimals(value)
  const isPlusHandicap = hcp.startsWith('+')
  if (isPlusHandicap) {
    return Number(hcp) > 10 ? '+10' : hcp
  } else {
    return Number(hcp) > 54 ? '54' : hcp
  }
}
