export const TOURNAMENT_REQUESTING = 'TOURNAMENT_REQUESTING'
export const TOURNAMENT_SUCCESS = 'TOURNAMENT_SUCCESS'
export const TOURNAMENT_ERROR = 'TOURNAMENT_ERROR'

export const OPEN_REGISTER_MODAL = 'OPEN_REGISTER_MODAL'
export const CLOSE_REGISTER_MODAL = 'CLOSE_REGISTER_MODAL'

export const LEAVE_TOURNAMENT_ACTION = 'LEAVE_TOURNAMENT_ACTION'
export const LEAVE_TOURNAMENT_ACTION_SUCCESS = 'LEAVE_TOURNAMENT_ACTION_SUCCESS'

export const SET_ACTIVE_DIVISION = 'SET_ACTIVE_DIVISION'
export const SET_ACTIVE_DIVISION_ENDPOINT = 'SET_ACTIVE_DIVISION_ENDPOINT'

export const TOURNAMENT_SET_SELECTED_ROUND = 'TOURNAMENT_SET_SELECTED_ROUND'

export const PLAYERS_SEARCH = 'PLAYERS_SEARCH'
export const PLAYERS_SEARCH_SUCCESS = 'PLAYERS_SEARCH_SUCCESS'
export const PLAYERS_SEARCH_FAILURE = 'PLAYERS_SEARCH_FAILURE'

export const CLEAR_SEARCH = 'CLEAR_SEARCH'
