import { Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import ScheduleAndAbout from '@app/components/tournament/ScheduleAndAbout'
import CourseInfo from '@app/components/tournament/CourseInfo'
import { ImagesAndSignUp } from '@app/components/tournament/ImagesAndSignUp'
import classNames from 'classnames'
import { RoundSelect } from '@app/components/tournament/ui/RoundSelect'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'auto',
    marginTop: 0,
  },
  top: {
    paddingBottom: theme.spacing(5),
    paddingTop: '0px !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: `${theme.spacing(1)} !important`,
    },
  },
  border: {
    borderTop: `1px solid ${theme.customPalette.lightGray2}`,
  },
  noTopBorder: {
    paddingTop: `0 !important`,
    borderTop: '0',
  },
  roundSelect: {
    marginTop: 0,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
}))

export const InfoPage = () => {
  const classes = useStyles()
  const { selectedRoundIndex, tournamentSite } = useSelector((store: StoreState) => store.tournamentReducer)
  const { tournamentInformationText, tournamentSchedule } = tournamentSite
  const showInfoSchedule = Boolean(tournamentInformationText[0]) || Boolean(tournamentSchedule[0])
  const isMultiRound = tournamentSite?.tournament.rounds.length > 1

  return (
    <Grid container className={classes.container} rowSpacing={5}>
      {showInfoSchedule && (
        <Grid item xs={12} className={classes.top}>
          {isMultiRound && (
            <div className={classes.roundSelect}>
              <RoundSelect />
            </div>
          )}
          <ScheduleAndAbout round={selectedRoundIndex} />
        </Grid>
      )}
      <Grid item xs={12} className={classNames([classes.border, !showInfoSchedule && classes.noTopBorder])}>
        <CourseInfo round={selectedRoundIndex} />
      </Grid>
      <ImagesAndSignUp alwaysShowSignUp />
    </Grid>
  )
}
