import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  USER_FIELD_UPDATE,
  QUESTION_FIELD_UPDATE,
  EDIT_ENTRY_TEAM_MEMBER,
  AUTH_REGISTER_AND_LOGIN,
  LOGIN_MAP,
  LOGIN_MAP_SUCCESS,
  LOGIN_MAP_FAILURE,
  EDIT_ENTRY_TEAM,
  AUTH_LOGIN_APPLE,
  AUTH_LOGIN_APPLE_FAILURE,
} from './constants'
import {
  EditEntryTeamMemberAction,
  UpdateQuestionAnswerAction,
  UpdateUserFieldAction,
  LoginMapAction,
  LoginMapSuccessAction,
  LoginMapFailureAction,
  EditEntryTeamAction,
} from './actions'
import get from 'lodash/get'
import { emptyTeamMember } from '../registrations/reducer'

export const initialState: AuthenticationState = {
  loading: false,
  appleLoading: false,
  error: undefined,
  appleError: undefined,
  user: undefined,
  questionAnswers: {},
  team: Array.from({ length: 5 }).map(() => ({ ...emptyTeamMember })),
}

type AuthenticationActions =
  | LoginAction
  | AppleLoginAction
  | LogOutAction
  | LoginSuccessAction
  | LogOutSuccessAction
  | LoginFailureAction
  | AppleLoginFailureAction
  | UpdateUserFieldAction
  | UpdateQuestionAnswerAction
  | EditEntryTeamMemberAction
  | RegisterAndLoginAction
  | LoginMapAction
  | LoginMapSuccessAction
  | LoginMapFailureAction
  | UpdateUserFieldAction
  | EditEntryTeamAction

const authenticationReducer = (state: AuthenticationState = initialState, action: AuthenticationActions) => {
  switch (action.type) {
    case AUTH_LOGIN:
    case AUTH_REGISTER_AND_LOGIN:
    case LOGIN_MAP:
      return {
        ...state,
        error: undefined,
        loading: true,
      }
    case AUTH_LOGIN_APPLE:
      return {
        ...state,
        appleError: undefined,
        appleLoading: true,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        appleLoading: false,
        error: undefined,
        appleError: undefined,
        user: {
          ...action.payload,
          hcp: get(action, 'payload.hcp', 0),
        },
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: action.error,
      }
    case AUTH_LOGIN_APPLE_FAILURE:
      return {
        ...state,
        loggedIn: false,
        appleLoading: false,
        appleError: action.error,
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        name: null,
        email: null,
        loggedIn: false,
        loading: false,
        error: undefined,
      }
    case USER_FIELD_UPDATE:
      const { payload } = action
      return {
        ...state,
        user: {
          ...state.user,
          [payload.fieldName]: payload.value,
        },
      }
    case AUTH_LOGOUT:
      return initialState
    case QUESTION_FIELD_UPDATE:
      return {
        ...state,
        questionAnswers: {
          ...state.questionAnswers,
          [action.payload.questionId]: {
            answer: action.payload.answer,
          },
        },
      }
    case EDIT_ENTRY_TEAM:
      return {
        ...state,
        team: action.payload.team,
      }
    case EDIT_ENTRY_TEAM_MEMBER:
      return {
        ...state,
        team: [
          ...state.team.slice(0, action.index),
          {
            ...state.team[action.index],
            [action.fieldName]: action.value,
          },
          ...state.team.slice(action.index + 1),
        ],
      }
    case LOGIN_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        user: {
          ...state.user,
          userMapped: true,
          hcp: get(action, 'payload.newHcp', state.user.hcp),
        },
      }
    case LOGIN_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export { authenticationReducer }
