import { rem } from '@app/theme/materialUITheme'
import { Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import Ads from './Ads'
import SignUp from './SignUp'
import SponsorLogos from './SponsorLogos'
import SummaryPictures from './SummaryPictures'
import { signUpEnabled } from '@app/utils/signUpUtils'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingTop: `${rem(40)} !important`,
    paddingBottom: `${rem(40)} !important`,
    borderTop: `1px solid ${theme.customPalette.lightGray2}`,
  },
  scheduleAndAbout: {
    borderBottom: `1px solid ${theme.customPalette.lightGray2}`,
    padding: `0 0 ${rem(40)} 0`,
  },
}))

interface Props {
  alwaysShowSignUp?: boolean
  isSummaryPage?: boolean
}

export const ImagesAndSignUp = ({ alwaysShowSignUp = false, isSummaryPage = false }: Props) => {
  const classes = useStyles()
  const { tournamentSite } = useSelector((store: StoreState) => store.tournamentReducer)
  const shouldShowSignUp: boolean = alwaysShowSignUp || signUpEnabled(tournamentSite)
  return (
    <>
      {shouldShowSignUp && (
        <Grid item sx={{ width: 1 }} className={classes.content}>
          <SignUp />
        </Grid>
      )}
      {!!tournamentSite.images.sponsorLogos.length && (
        <Grid item sx={{ width: 1 }} className={classes.content}>
          <SponsorLogos images={tournamentSite.images.sponsorLogos} />
        </Grid>
      )}
      {!!tournamentSite.images.ads.length && (
        <Grid item sx={{ width: 1 }} className={classes.content}>
          <Ads images={tournamentSite.images.ads} />
        </Grid>
      )}
      {isSummaryPage && <SummaryPictures />}
    </>
  )
}
