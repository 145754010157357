import { GA_TRACKING_CODE } from '@app/config'
import { useCallback, useEffect } from 'react'
import ReactGA from 'react-ga'

// TODO:nextjs remove console logging?

export const useAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_CODE || 'UA-xxxxxxxxx-1', { debug: false })
  }, [])

  const logPageView = useCallback(() => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }, [])

  const logEvent = ReactGA.event

  const logException = ReactGA.exception

  return {
    logPageView,
    logEvent,
    logException,
  }
}
