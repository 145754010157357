import { CALENDAR_SUCCESS, CALENDAR_REQUESTING, CALENDAR_ERROR } from './constants'

export interface GetCalendarAction extends Action {
  type: 'CALENDAR_REQUESTING'
  calendarId: string
}
export const getCalendar = (calendarId: string): GetCalendarAction => ({
  type: CALENDAR_REQUESTING,
  calendarId,
})

export interface GetCalendarErrorAction extends Action {
  type: 'CALENDAR_ERROR'
  error?: any
}
export const getCalendarError = (error?: any): GetCalendarErrorAction => ({
  type: CALENDAR_ERROR,
  error,
})

export interface GetCalendarSuccessAction extends Action {
  type: 'CALENDAR_SUCCESS'
  calendarId: string
  calendars: Calendar[]
}

export const getCalendarSuccess = (calendarId: string, calendars: Calendar[]): GetCalendarSuccessAction => ({
  type: CALENDAR_SUCCESS,
  calendarId,
  calendars,
})
