import React from 'react'
import { connect } from 'react-redux'
import DialogBase, { DialogBaseProps } from './DialogBase'
import {
  closeRegisterModal,
  joinTournament,
  JoinTournamentPayload,
  editRegistration,
  LeaveTournamentPayload,
  leaveTournament,
} from '@app/store/tournament/actions'
import { Typography, Theme, CircularProgress, DialogContent, Stepper, Step, StepLabel } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import TourButton from '../ui/TourButton'
import { login, logout, updateUserField } from '@app/store/authentication/actions'
import {
  areRequiredAnswersValid,
  formatCustomQuestionAnswersForAPI,
  isMandatoryDivisionSelected,
} from '@app/utils/customQuestionUtils'
import IndividualTournamentEntryForm from '../tournament/IndividualTournamentEntryForm'
import TeamTournamentEntryForm from '../tournament/TeamTournamentEntryForm'
import AccountLoginForm from '../ui/AccountLoginForm'
import Alert from './Alert'
import { loadTournamentPlayers, getTournamentTeams } from '@app/store/tournamentPlayers/actions'
import { ColorTheme, EntryMethod } from '@app/utils/enums'
import RegisterForm from '../ui/RegisterForm'
import { LineDivider } from './LineDivider'
import { updateTeamMembersHcp, validateHandicapValue } from '@app/utils/playerUtils'
import { rem } from '@app/theme/materialUITheme'
import { PaymentProgressView } from '../payment/PaymentProgressView'
import { maxEntriesAmountExceeded } from '@app/utils/tournamentUtils'
import { AppleSignInButton } from '../ui/AppleSignInButton'
import LoadingSpinner from '../tournament/ui/LoadingSpinner'
import { SignUpSuccessModal } from './signUpSuccessModal'
import { RegistrationSuccessModal } from './RegistrationSuccessModal'

const styles = (theme: Theme) =>
  createStyles({
    paymentBtn: {
      '&.MuiButton-outlined ': {
        padding: rem(15),
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: rem(30),
      },
    },
    selectedProduct: {
      padding: rem(10),
      border: '1px solid green',
      boxShadow: '0 1px 3px green',
      margin: 0,
      marginBottom: rem(15),
      width: '100%',
      '& .MuiFormControlLabel-label': {
        width: '100%',
      },
    },
    notSelectedProduct: {
      padding: rem(10),
      border: '1px solid grey',
      margin: 0,
      marginBottom: rem(15),
      width: '100%',
      '& .MuiFormControlLabel-label': {
        width: '100%',
      },
    },
    stepperLine: {
      backgroundColor: '#F2F2F2',
      marginBottom: rem(20),
      '& .MuiStepConnector-line': {
        borderColor: theme.customPalette.ggbGreen,
      },
      '&.MuiStepper-root': {
        marginTop: '-20px',
        paddingBottom: '10px',

        [theme.breakpoints.up('md')]: {
          marginTop: '-30px',
        },
      },
    },
    dialogContainer: {
      '& .MuiPaper-root': {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        '& .MuiPaper-root': {
          maxWidth: 757,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiPaper-root': {
          margin: 0,
          maxWidth: '100%',
        },
      },
    },
    dialogContent: {
      padding: '20px',

      [theme.breakpoints.up('md')]: {
        padding: '50px',
      },
    },
    registerHere: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
    },
    emptyTitle: {
      height: 24,
    },
  })

const Divider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '28px 0 21px',
      position: 'relative',
      width: '100%',
      textAlign: 'center',
      '&:before': {
        position: 'absolute',
        content: '""',
        display: 'block',
        height: '1px',
        left: 0,
        right: 0,
        top: 'calc(50% - 1px)',
        backgroundColor: theme.palette.primary.main,
      },
    },
    text: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.mode === ColorTheme.LIGHT.toLowerCase() ? '#FFFFFF' : '#121212',
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
      padding: `0 20px`,
      display: 'inline-block',
      position: 'relative',
    },
  }),
)(({ classes }: WithStyles<any>) => (
  <div className={classes.root}>
    <Typography variant={'body2'} className={classes.text}>
      <FormattedMessage id="strings.or" />
    </Typography>
  </div>
))

interface StateProps {
  open: boolean
  tournamentSite?: TournamentSite
  auth: AuthenticationState
  registration?: UserRegistration
  registrationLoading: boolean
  cancelSuccess: boolean
  players?: TournamentPlayersState
  locale: LocaleState
}

interface DispatchProps {
  closeRegisterModal(): void
  login(args: LoginPayload): void
  logout(): void
  joinTournament(args: JoinTournamentPayload): void
  editRegistration(args: JoinTournamentPayload): void
  updateUserField(payload: FieldUpdatePayload): void
  loadTournamentPlayers(id: number): void
  getTournamentTeams(tournamentId: string | number): void
  leaveTournament(args: LeaveTournamentPayload, onComplete: () => void): void
}

type Props = StateProps & DispatchProps & DialogBaseProps & WrappedComponentProps & WithStyles<typeof styles>

interface State {
  questionErrors: { [property: string]: boolean }
  mandatoryDivisionError: boolean
  joiningTournament: boolean
  signUpSuccess: boolean
  invalidCredentials: boolean
  invalidFacebook: boolean
  registrationOnReserveList: boolean
  showAlert: 'edit' | 'join' | null
  alertMessage: string | null
  alertTitle?: string | null
  registerAndLogin: boolean
  registrationSuccessModal: boolean
  showPaymentOptions: boolean
  step: number
}

class RegisterDialog extends React.Component<Props, State> {
  state: State = {
    questionErrors: {},
    mandatoryDivisionError: false,
    joiningTournament: false,
    signUpSuccess: false,
    invalidCredentials: false,
    invalidFacebook: false,
    registrationOnReserveList: false,
    showAlert: null,
    alertMessage: null,
    alertTitle: null,
    registerAndLogin: false,
    registrationSuccessModal: false,
    showPaymentOptions: false,
    step: 0,
  }

  handleStepChange = (step?: number) => {
    this.setState({
      step: step ?? 1,
    })
  }

  private static getStepTitle() {
    return ['payments.signUp', 'payments.payment']
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { tournamentSite } = this.props
    if (prevProps.tournamentSite !== this.props.tournamentSite) {
      if (tournamentSite) {
        this.props.getTournamentTeams(tournamentSite.tournament.id)
        this.props.loadTournamentPlayers(tournamentSite.tournament.id)
      }
    }
  }

  render() {
    const { showPaymentOptions, showAlert, step } = this.state
    const { auth, cancelSuccess, registration, open, classes, tournamentSite, players } = this.props
    let alertMessage = this.state.alertMessage ? <FormattedMessage id={this.state.alertMessage} /> : null
    const alertTitle = this.state.alertTitle ? (
      <FormattedMessage id={this.state.alertTitle} />
    ) : (
      <FormattedMessage id="strings.error" />
    )
    if (!alertMessage)
      alertMessage =
        this.state.showAlert === 'edit' ? (
          <FormattedMessage id="strings.editRegistrationError" />
        ) : (
          <FormattedMessage id="strings.editJoinError" />
        )

    const maxPlayerAmountExceeded = tournamentSite?.reserveListEnabled
      ? maxEntriesAmountExceeded(tournamentSite, players)
      : !tournamentSite?.reserveListEnabled

    if (!open) {
      return null
    }

    return (
      <>
        {open && (
          <DialogBase
            open={open}
            maxWidth={showPaymentOptions ? 'sm' : 'lg'}
            onClose={this._handleCloseAfterSignUp}
            title={this._registerDialogTitle}
            fullWidth={showPaymentOptions}
            className={classes.dialogContainer}
          >
            {auth?.user &&
              !cancelSuccess &&
              !registration &&
              tournamentSite?.paymentEnabled &&
              maxPlayerAmountExceeded && (
                <Stepper alternativeLabel activeStep={step} className={classes.stepperLine}>
                  {RegisterDialog.getStepTitle().map((label, id) => {
                    return (
                      <Step key={id}>
                        <StepLabel>
                          <FormattedMessage id={label} />
                        </StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              )}

            {this._renderContents()}

            {Boolean(showAlert) && (
              <Alert
                open={true}
                title={alertTitle}
                text={alertMessage}
                showOk={false}
                cancelText={<FormattedMessage id="buttons.close" />}
                cancelAction={this._closeAlert}
              />
            )}
          </DialogBase>
        )}

        {this.state.registrationSuccessModal && (
          <RegistrationSuccessModal
            open={this.state.registrationSuccessModal}
            closeCallback={() => this.setState({ registrationSuccessModal: false })}
          />
        )}

        {this.state.signUpSuccess && (
          <SignUpSuccessModal
            open={this.state.signUpSuccess}
            registrationOnReserveList={this.state.registrationOnReserveList}
            closeCallback={() => this._handleCloseAfterSignUp()}
          />
        )}
      </>
    )
  }

  get _registerDialogTitle() {
    const { showPaymentOptions } = this.state
    const { tournamentSite, registrationLoading, classes } = this.props

    if (registrationLoading) return <div className={classes.emptyTitle} />

    if (this.state.registerAndLogin) return <FormattedMessage id="strings.register" />

    if (this.props.registration && !showPaymentOptions) return <FormattedMessage id="strings.yourEntry" />

    if (!this.props.auth.user) return <FormattedMessage id="strings.logIn" />

    if (showPaymentOptions) return <FormattedMessage id="payments.payment" />

    return (
      <FormattedMessage
        id={this._isTeamTournament ? 'strings.registerTeamToTournament' : 'strings.registerToTournament'}
        values={{ name: tournamentSite?.tournament?.name }}
      />
    )
  }

  get _isTeamTournament() {
    const { tournamentSite } = this.props

    if (tournamentSite && tournamentSite.tournament) {
      return tournamentSite.tournament.isTeamFormat && tournamentSite.entryMethod === EntryMethod.TEAM
    }

    return false
  }

  private _setQuestionErrors = (questionErrors: { [property: string]: boolean | undefined }) => {
    this.setState({ questionErrors })
  }

  private _setMandatoryDivisionError = () => {
    this.setState({ mandatoryDivisionError: true })
  }

  private _renderContents = () => {
    const { auth, cancelSuccess, registrationLoading, classes } = this.props
    const { showPaymentOptions } = this.state

    if (registrationLoading) {
      return (
        <div className={classes.spinnerContainer}>
          <LoadingSpinner size={64} />
        </div>
      )
    }

    if (cancelSuccess) {
      return (
        <DialogContent style={{ textAlign: 'center', maxWidth: '100%' }}>
          <Typography variant={'body1'} style={{ marginTop: 20, marginBottom: 20 }}>
            <FormattedMessage id="strings.cancelSuccess" />
          </Typography>
          <TourButton
            color={'primary'}
            variant="outlined"
            buttonProps={{
              style: {
                marginTop: 24,
                marginBottom: 20,
                minWidth: 175,
              },
              onClick: this._handleCloseAfterSignUp,
            }}
          >
            <FormattedMessage id={'buttons.close'} />
          </TourButton>
        </DialogContent>
      )
    } else if (this.state.registerAndLogin) {
      return (
        <DialogContent className={classes.dialogContent}>
          <RegisterForm
            onComplete={() => this.setState({ registerAndLogin: false, registrationSuccessModal: true })}
            onCancel={() => this.setState({ registerAndLogin: false })}
          />
        </DialogContent>
      )
    } else if (auth.user && !showPaymentOptions) {
      return this._renderTournamentRegistration()
    } else if (showPaymentOptions) {
      return this.renderPaymentSelection()
    } else {
      return this._renderRegisterOptions()
    }
  }

  private _cancelEntry = () => {
    const {
      user: { authToken, clientUid, userId },
    } = this.props.auth
    const { id } = this.props.tournamentSite.tournament
    const { locale } = this.props

    const payload = {
      tournamentId: id,
      authToken,
      clientUid,
      userId,
      lang: locale.appLanguage.code,
    }

    this.props.leaveTournament(payload, () => {})
  }

  private _renderStatus = () => {
    const { registration } = this.props
    const entryAmount = this._entryAmount
    const maxEntryAmount = this._maxEntryAmount
    const status = entryAmount
      ? `${maxEntryAmount && entryAmount > maxEntryAmount ? maxEntryAmount : entryAmount}${
          maxEntryAmount ? `/${maxEntryAmount}` : ''
        }`
      : '0'
    const reservelist =
      this.props.tournamentSite.reserveListEnabled && entryAmount && entryAmount > maxEntryAmount ? (
        <>
          ,&nbsp;
          <FormattedMessage id={'strings.reserveListCount'} />
          :&nbsp;
          <FormattedMessage
            id={this._isTeamTournament ? 'strings.teamsCount' : 'strings.playersCount'}
            values={{ status: entryAmount - maxEntryAmount }}
          />
        </>
      ) : (
        ''
      )

    return (
      <>
        <div>
          <Typography variant="body1">
            <strong>
              <FormattedMessage id="strings.tournamentStatus" />:
            </strong>
            &nbsp;
            <FormattedMessage
              id={this._isTeamTournament ? 'strings.teamsCount' : 'strings.playersCount'}
              values={{ status }}
            />
            &nbsp;{reservelist}
            {maxEntryAmount && entryAmount >= maxEntryAmount && !registration && (
              <>
                <br />
                <FormattedMessage
                  id={
                    !this.props.tournamentSite.reserveListEnabled
                      ? 'strings.tournamentFull'
                      : this.props.tournamentSite.entryMethod === EntryMethod.TEAM
                      ? 'strings.tournamentTeamFullReserveList'
                      : 'strings.tournamentFullReserveList'
                  }
                />
              </>
            )}
          </Typography>
        </div>
        <LineDivider style={{ padding: '16px 0' }} />
      </>
    )
  }

  private get _entryAmount() {
    const isTeamFormat =
      this.props.tournamentSite.tournament.isTeamFormat &&
      this.props.tournamentSite.onlineEntriesEnabled &&
      this.props.tournamentSite.entryMethod === EntryMethod.TEAM
    return isTeamFormat
      ? this.props.players.teams.length
      : this.props.players.players.length + this.props.players.reserveList.length
  }

  private get _maxEntryAmount() {
    const { tournamentSite } = this.props
    return tournamentSite.onlineEntriesEnabled && tournamentSite.maximumEntryAmountEnabled
      ? tournamentSite.entryMethod === EntryMethod.TEAM
        ? tournamentSite.maximumTeamAmount
        : tournamentSite.maximumPlayerAmount
      : null
  }

  private _handleCloseAfterSignUp = () => {
    const isSuccessfulSignUp = this.state.signUpSuccess
    this.setState({
      signUpSuccess: false,
      invalidCredentials: false,
      registerAndLogin: false,
      showPaymentOptions: false,
    })
    this.handleStepChange(0)
    this.props.logout()
    if (this.props.tournamentSite) {
      this.props.loadTournamentPlayers(this.props.tournamentSite.tournament.id)
      this.props.getTournamentTeams(this.props.tournamentSite.tournament.id)
    }
    this.props.closeRegisterModal()

    if (isSuccessfulSignUp) window.location.href = `/tournament/${this.props.tournamentSite.tournament.id}`
  }

  private _closeAlert = () => {
    this.setState({ showAlert: null, alertMessage: null, alertTitle: null })
  }

  private get _currentRound() {
    //TODO: PHASE:MULTIROUND
    const { rounds } = this.props.tournamentSite.tournament
    return rounds[0]
  }

  private _renderTournamentRegistration = () => {
    const {
      auth: { user, loading },
      registration,
      tournamentSite,
    } = this.props
    const entryAmount = this._entryAmount
    const maxEntryAmount = this._maxEntryAmount

    if (loading) {
      return (
        <DialogContent style={{ textAlign: 'center', margin: `48px 0`, maxWidth: 600 }}>
          <CircularProgress size={48} thickness={2} />
        </DialogContent>
      )
    }

    /*
      USER AUTHED
     */
    if (user) {
      if (this._currentRound.status.isScoringDisabled && this._currentRound.status.isCompleted) {
        return (
          <DialogContent style={{ maxWidth: 600 }}>
            <Typography variant="body1" color="error" style={{ marginBottom: 30, textAlign: 'center' }}>
              <strong>
                <FormattedMessage id="strings.resultsEnteredError" />
              </strong>
            </Typography>
          </DialogContent>
        )
      }

      /**
       * TODO:PHASE:WEEKLY enable checks when weeklies implemented
       * const isScoringEnabled =
        typeof this._currentRound.status.isScoringDisabled !== 'undefined' &&
        !this._currentRound.status.isScoringDisabled

      if (tournamentSite && tournamentSite.tournament && !tournamentSite.tournament.noStartTimes && isScoringEnabled) {
        return (
          <DialogContent style={{ maxWidth: 600 }}>
            <Typography variant="body1" color="error" style={{ marginBottom: 30, textAlign: 'center' }}>
              <strong>
                <FormattedMessage id="strings.scoringStartedError" />
              </strong>
            </Typography>
          </DialogContent>
        )
      }
      */

      /*
        TEAM FORMAT REGISTER/EDIT FORM
       */
      if (this._isTeamTournament && this._isTeamEntryMethod) {
        return (
          <DialogContent style={{ maxWidth: 1200 }}>
            {this._renderStatus()}

            <IndividualTournamentEntryForm
              isTeam
              questionErrors={this.state.questionErrors}
              mandatoryDivisionError={this.state.mandatoryDivisionError}
              loading={this.state.joiningTournament}
              handleJoinTournament={this._handleJoinTournament}
              handleEditRegistration={this._handleEditRegistrationInfo}
              isRegistered={false}
              divisions={tournamentSite.divisions}
              divisionsEnabled={tournamentSite.manualDivisionSelectionEnabled}
              divisionSelectionRequired={tournamentSite.manualDivisionSelectionRequired}
              clearErrors={() => this.setState({ mandatoryDivisionError: false })}
            />

            <TeamTournamentEntryForm
              questionErrors={this.state.questionErrors}
              loading={this.state.joiningTournament}
              handleJoinTournament={this._handleJoinTournament}
              disableSignUp={
                !this.props.tournamentSite.reserveListEnabled && maxEntryAmount && entryAmount >= maxEntryAmount
              }
              isRegistered={false}
              cancelEntryFn={this._cancelEntry}
              handleContinueToPayment={this._handleContinueToPayment}
              handleStep={this.handleStepChange}
              handleEditRegistration={this._handleEditRegistrationInfo}
              clearErrors={() => this.setState({ questionErrors: {} })}
              setQuestionErrors={this._setQuestionErrors}
              setMandatoryDivisionError={this._setMandatoryDivisionError}
            />
          </DialogContent>
        )
      }

      /*
        INDIVIDUAL REGISTER/EDIT FORM
      */

      return (
        <DialogContent style={{ maxWidth: 700 }}>
          {!registration && this._renderStatus()}
          <IndividualTournamentEntryForm
            questionErrors={this.state.questionErrors}
            mandatoryDivisionError={this.state.mandatoryDivisionError}
            loading={this.state.joiningTournament}
            handleJoinTournament={this._handleJoinTournament}
            handleEditRegistration={this._handleEditRegistrationInfo}
            handleContinueToPayment={this._handleContinueToPayment}
            handleStep={this.handleStepChange}
            isRegistered={!!registration}
            disableSignUp={
              entryAmount &&
              maxEntryAmount &&
              entryAmount >= maxEntryAmount &&
              !this.props.tournamentSite.reserveListEnabled
            }
            divisions={tournamentSite.divisions}
            divisionsEnabled={tournamentSite.manualDivisionSelectionEnabled}
            divisionSelectionRequired={tournamentSite.manualDivisionSelectionRequired}
            clearErrors={() => this.setState({ mandatoryDivisionError: false })}
          />
        </DialogContent>
      )
    }
  }

  public _handleJoinTournament = () => {
    const { auth, joinTournament, tournamentSite, locale } = this.props
    const questionsAnswered = areRequiredAnswersValid(tournamentSite.customQuestions, auth.questionAnswers)
    const mandatoryDivisionSelected = isMandatoryDivisionSelected(tournamentSite, auth.user?.divisionId)

    let shouldExitEarly = false
    if (!questionsAnswered.valid) {
      this.setState({ questionErrors: questionsAnswered.errors })
      shouldExitEarly = true
    }

    if (!mandatoryDivisionSelected) {
      this._setMandatoryDivisionError()
      shouldExitEarly = true
    }

    if (shouldExitEarly) {
      return
    }

    if (auth.user && !this.state.joiningTournament && tournamentSite.tournament) {
      this.setState({ joiningTournament: true, questionErrors: {} }, () => {
        const { team } = auth
        const { userId, authToken, clientUid, hcp, dirtyHcp, teamName, gender, teebox, divisionId } = auth.user
        const { id } = tournamentSite.tournament
        const { entryMethod } = tournamentSite
        const customQuestions = formatCustomQuestionAnswersForAPI(auth.questionAnswers)
        const updatedTeam = updateTeamMembersHcp(team)

        joinTournament({
          tournamentId: id,
          userId,
          authToken,
          clientUid,
          hcp: dirtyHcp ? validateHandicapValue(dirtyHcp).value : validateHandicapValue(hcp).value,
          customQuestions,
          team:
            entryMethod === EntryMethod.TEAM && !tournamentSite.tournament.noStartTimes
              ? updatedTeam.slice(0, tournamentSite.defaultTeamSize) //Remove extra rows
              : null,
          teamName,
          gender,
          teebox,
          divisionId,
          lang: locale.appLanguage.code,
          onComplete: (success: boolean, reserveList?: boolean, error?: any): void => {
            this.setState({ joiningTournament: false }, () => {
              if (!success) {
                if (error && error.message === 'Request failed with status code 409') {
                  const data = error.response.data
                  const message = data.error.message
                  if (message) {
                    this.setState({ alertMessage: `apiErrors.${message}`, alertTitle: 'strings.signUpFailed' })
                  }
                }

                this.setState({ showAlert: 'join' })
              } else {
                this.setState({
                  signUpSuccess: true,
                  registrationOnReserveList: reserveList,
                })
              }
            })
          },
        })
      })
    }
  }

  public _handleEditRegistrationInfo = (closeOnComplete = true) => {
    const { auth, editRegistration, tournamentSite } = this.props

    if (auth.user && tournamentSite.tournament) {
      const { valid, errors } = areRequiredAnswersValid(tournamentSite.customQuestions, auth.questionAnswers)
      const mandatoryDivisionSelected = isMandatoryDivisionSelected(tournamentSite, auth.user?.divisionId)

      let shouldExitEarly = false
      if (!valid) {
        this.setState({ questionErrors: errors })
        shouldExitEarly = true
      }

      if (!mandatoryDivisionSelected) {
        this._setMandatoryDivisionError()
        shouldExitEarly = true
      }

      if (shouldExitEarly) {
        return
      }

      const { team } = auth
      const { userId, authToken, clientUid, hcp, teebox, divisionId, teamName } = auth.user
      const { id } = tournamentSite.tournament
      const customQuestions = formatCustomQuestionAnswersForAPI(auth.questionAnswers)
      const updatedTeam = updateTeamMembersHcp(team)

      this.setState({ joiningTournament: true }, () => {
        editRegistration({
          tournamentId: id,
          userId,
          authToken,
          clientUid,
          hcp,
          customQuestions,
          team: updatedTeam,
          teamName,
          teebox,
          divisionId,
          onComplete: (success: boolean, _reserveList?: boolean, error?: any): void => {
            this.setState({ joiningTournament: false }, () => {
              if (!success) {
                if (error && error.message === 'Request failed with status code 409') {
                  const data = error.response.data
                  const message = data.error.message
                  if (message) this.setState({ alertMessage: `apiErrors.${message}` })
                }

                this.setState({ showAlert: 'edit' })
              } else {
                closeOnComplete && this._handleCloseAfterSignUp()
              }
            })
          },
        })
      })
    }
  }

  private _renderRegisterOptions = () => {
    const { tournamentSite, classes } = this.props

    if (!tournamentSite) {
      return null
    }

    return (
      <>
        <DialogContent style={{ textAlign: 'center' }}>
          <AccountLoginForm />
        </DialogContent>

        <Divider />

        <DialogContent style={{ textAlign: 'center', paddingTop: 0 }}>
          <AppleSignInButton />

          <Typography variant={'body1'} style={{ maxWidth: 550, fontWeight: 500, margin: '0 auto 30px' }}>
            <FormattedMessage id={'strings.noGgbProfileQuestion'} />{' '}
            <span className={classes.registerHere} onClick={() => this.setState({ registerAndLogin: true })}>
              <FormattedMessage id={'buttons.register'} />
            </span>
          </Typography>
        </DialogContent>
      </>
    )
  }

  /* private _renderEBirdieLogin = () => {
    const { auth } = this.props

    if (!auth.user) return null

    return (
      <DialogContent style={{ textAlign: 'center' }}>
        <EBirdieLoginForm />
      </DialogContent>
    )
  } */

  private get _isTeamEntryMethod() {
    return this.props.tournamentSite.entryMethod === EntryMethod.TEAM
  }

  private renderPaymentSelection = () => {
    return (
      <DialogContent style={{ height: 500 }}>
        <PaymentProgressView
          handleReturn={this._handleContinueToPayment}
          handleJoinTournament={this._handleJoinTournament}
          handleClose={this._handleCloseAfterSignUp}
          isRegistered={!!this.props.registration}
        />
      </DialogContent>
    )
  }

  private _handleContinueToPayment = () => {
    const { showPaymentOptions } = this.state
    const { tournamentSite, auth } = this.props
    const { valid, errors } = areRequiredAnswersValid(tournamentSite.customQuestions, auth.questionAnswers)

    if (!valid) {
      this.setState({ questionErrors: errors })
      return
    }

    this.setState({
      showPaymentOptions: !showPaymentOptions,
      step: 0,
    })

    this.handleStepChange()
  }
}

export default withStyles(styles)(
  injectIntl(
    connect<StateProps, DispatchProps, {}, StoreState>(
      (state) => ({
        open: state.tournamentReducer.registerModalOpen,
        cancelSuccess: state.tournamentReducer.cancelSuccess,
        tournamentSite: state.tournamentReducer.tournamentSite,
        auth: state.authenticationReducer,
        registration: state.registrationsReducer.registration,
        registrationLoading: state.registrationsReducer.loading,
        players: state.tournamentPlayersReducer,
        locale: state.localeReducer,
      }),
      {
        closeRegisterModal,
        login,
        logout,
        joinTournament,
        updateUserField,
        editRegistration,
        loadTournamentPlayers,
        getTournamentTeams,
        leaveTournament,
      },
    )(RegisterDialog),
  ),
)
