import React from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { Flipper } from 'react-flip-toolkit'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Paper from '@mui/material/Paper'
import classNames from 'classnames'
import { ColorTheme } from '../../utils/enums'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: 'none',
    },
    tableContainer: {
      maxHeight: 500,
      width: '100%',
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
      },
    },
    table: {
      width: '100%',
      overflow: 'hidden',
      borderSpacing: 0,
    },
    thead: {
      '& .Mui-active': {
        color: '#FFFFFF',
        '& .MuiTableSortLabel-icon': {
          color: '#FFFFFF',
        },
      },
      background:
        theme.palette.mode === ColorTheme.LIGHT.toLowerCase()
          ? theme.customPalette.mediumGray2
          : theme.customPalette.dark,
    },
    tbody: {},
  })

interface Props extends WithStyles<typeof styles> {
  arraySortKey?: string
  className?: string
  children?: React.ReactNode
}

class TournamentTable extends React.Component<Props> {
  render() {
    const { children, arraySortKey = 'no-sorting', classes, className } = this.props

    return (
      <Paper className={classes.paper}>
        <Flipper flipKey={arraySortKey}>
          <div className={classNames(classes.tableContainer, className)}>
            <table className={classes.table}>{children}</table>
          </div>
        </Flipper>
      </Paper>
    )
  }
}

interface TournamentTableHeadBody extends WithStyles<typeof styles> {
  children: any
}

export const TournamentTableHead = withStyles(styles)(({ children, classes }: TournamentTableHeadBody) => (
  <thead className={classes.thead}>{children}</thead>
))

export const TournamentTableBody = withStyles(styles)(({ children, classes }: TournamentTableHeadBody) => (
  <tbody className={classes.tbody}>{children}</tbody>
))

export default withStyles(styles)(TournamentTable)
