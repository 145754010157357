export const PAYMENT_PROCESS = 'PAYMENT_PROCESS'
export const PAYMENT_PROCESS_SUCCESS = 'PAYMENT_PROCESS_SUCCESS'
export const PAYMENT_PROCESS_FAILURE = 'PAYMENT_PROCESS_FAILURE'

export const FETCH_TOURNAMENT_PRODUCTS = 'FETCH_TOURNAMENT_PRODUCTS'
export const FETCH_TOURNAMENT_PRODUCTS_SUCCESS = 'FETCH_TOURNAMENT_PRODUCTS_SUCCESS'
export const FETCH_TOURNAMENT_PRODUCTS_FAILURE = 'FETCH_TOURNAMENT_PRODUCTS_FAILURE'

export const START_PAYMENT_PROCESS = 'START_PAYMENT_PROCESS'
export const START_PAYMENT_PROCESS_SUCCESS = 'START_PAYMENT_PROCESS_SUCCESS'
export const START_PAYMENT_PROCESS_FAILURE = 'START_PAYMENT_PROCESS_FAILURE'

export const VERIFY_NETS_PAYMENT = 'VERIFY_NETS_PAYMENT'
export const VERIFY_NETS_PAYMENT_SUCCESS = 'VERIFY_NETS_PAYMENT_SUCCESS'
export const VERIFY_NETS_PAYMENT_FAILURE = 'VERIFY_NETS_PAYMENT_FAILURE'

export const FETCH_BOUGHT_PRODUCTS = 'FETCH_BOUGHT_PRODUCTS'
export const FETCH_BOUGHT_PRODUCTS_SUCCESS = 'FETCH_BOUGHT_PRODUCTS_SUCCESS'
export const FETCH_BOUGHT_PRODUCTS_FAILURE = 'FETCH_BOUGHT_PRODUCTS_FAILURE'

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES'
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS'
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE'
