import { SuccessIcon } from '@app/assets/images/icons'
import { Dialog, Typography } from '@mui/material'
import TourButton from '../ui/TourButton'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  registrationSuccessModal: {
    '& .MuiDialog-paper': {
      maxWidth: 347,
      borderRadius: 12,
      margin: 14,
      padding: '70px 20px 50px',
      textAlign: 'center',
    },
  },
}))

type Props = {
  open: boolean
  closeCallback: () => void
}

export const RegistrationSuccessModal = (props: Props) => {
  const classes = useStyles()
  return (
    <Dialog className={classes.registrationSuccessModal} open={props.open}>
      <SuccessIcon style={{ width: 85, height: 85, margin: '0 auto 10px' }} />
      <Typography variant="h2" component="h2" sx={{ fontSize: 20 }}>
        <FormattedMessage id="strings.registrationSuccessTitle" />
      </Typography>
      <p style={{ marginTop: 16, fontSize: 16, lineHeight: 1.5 }}>
        <FormattedMessage id="strings.registrationSuccessText" />
      </p>
      <TourButton buttonProps={{ onClick: () => props.closeCallback(), sx: { mt: 4 } }}>
        <FormattedMessage id="buttons.continue" />
      </TourButton>
    </Dialog>
  )
}
