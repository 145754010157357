import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Theme, CircularProgress, useTheme, useMediaQuery, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { loadTournamentStartlists } from '../../store/tournamentStartlists/actions'
import TournamentTableRow from '../table/TournamentTableRow'
import TournamentTableCell from '../table/TournamentTableCell'
import TournamentTable, { TournamentTableHead, TournamentTableBody } from '../table/TournamentTable'
import { defaultFormat } from '../../utils/date'
import { formatHandicap, formatHcpToDirtyHcp } from '@app/utils/playerUtils'
import { isTogetherScoredTeamFormatId } from '@app/utils/tournamentUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.customPalette.tableTextColor,
    fontFamily: ['Exo', 'sans-serif'].join(','),
  },
  flag: {
    display: 'block',
    margin: '0 auto',
    width: 24,
  },
  extraInfo: {
    fontWeight: 'normal',
    fontSize: 10,
    fontFamily: 'Roboto',
    '& > span': {
      '&:first-of-type': {
        marginRight: 5,
      },
      '&:last-of-type': {
        marginLeft: 5,
      },
    },
  },
  extraInfoText: {
    fontWeight: 'normal',
    fontSize: 10,
    '& > span': {
      '&:first-of-type': {
        marginRight: 5,
      },
      '&:last-of-type': {
        marginLeft: 5,
      },
    },
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  numberCell: {
    width: 40,
    paddingLeft: 20,
  },
  playerNo: {
    textAlign: 'center',
    paddingLeft: 20,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
  flagCell: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 20,
      paddingLeft: 15,
    },
  },
  smallCell: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

interface Props {
  tournamentId?: number
}

export const LeaderboardStartlists = ({ tournamentId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'))

  const { tournamentSite, selectedRoundIndex } = useSelector((store: StoreState) => store.tournamentReducer)
  const { loading, rounds } = useSelector((store: StoreState) => store.tournamentStartlistsReducer)
  const { isTeamFormat } = tournamentSite.tournament

  useEffect(() => {
    tournamentId && dispatch(loadTournamentStartlists(tournamentId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _currentRound = () => {
    const { rounds } = tournamentSite.tournament
    return rounds && rounds[selectedRoundIndex] ? rounds[selectedRoundIndex] : rounds[0]
  }

  const _playerTeamHCP = (player: Player, group: Group): string => {
    const { isTeamFormat } = tournamentSite.tournament
    if (!isTeamFormat) return null

    const teamId = player.team?.id
    const team = group.teams.find((team) => team.tournamentTeamId === teamId)
    return formatHcpToDirtyHcp(team.primaryPlayingHcp)
  }

  const _renderSortedPlayers = (startlist: Group[]) => {
    return startlist.map((group) => {
      const groupPlayers = [...group.startListPlayers]
      groupPlayers.sort((a, b) => a.groupOrder - b.groupOrder)
      return groupPlayers.map((player, idx) => _renderRow(group, player, idx))
    })
  }

  const startlist = rounds[selectedRoundIndex]
  const isStartListVisible: boolean = _currentRound().status.isConfigured

  if (loading) {
    return (
      <div className={classes.container}>
        <CircularProgress size={48} thickness={2} />
      </div>
    )
  }

  if (!isStartListVisible || !startlist || startlist.length === 0) {
    return (
      <div className={classes.container}>
        <FormattedMessage id="strings.emptyStartList" />
      </div>
    )
  }

  const _renderRow = (group: Group, startListPlayer: TournamentStartListPlayer, idx: number) => {
    const player = startListPlayer.tournamentPlayer
    const playerNo = ++idx
    return (
      <TournamentTableRow alternatingRows key={`${player.id}_${playerNo}`} rowKey={`${player.id}`}>
        {tournamentSite.countryFlagForPlayersEnabled && (
          <TournamentTableCell className={classes.flagCell}>
            {player.countryCode && (
              <img
                src={`//static.golfgamebook.com/flags/${player.countryCode}.png`}
                alt={player.country}
                className={classes.flag}
              />
            )}
          </TournamentTableCell>
        )}
        <TournamentTableCell style={{ fontWeight: 600 }}>
          <div className={classes.nameContainer}>
            <span style={{ fontSize: 12 }}>
              {player.firstName} {player.lastName}
            </span>
            {mediaQuery && (
              <div className={classes.extraInfo}>
                <span>{isTeamFormat ? player.team?.name : player.club?.name || '-'}</span>|
                <span>{startListPlayer.teeBox?.teeboxName || '-'}</span>
              </div>
            )}
          </div>
        </TournamentTableCell>
        {!mediaQuery ? (
          <>
            <TournamentTableCell>
              {moment(group.startTime).format(defaultFormat('time', tournamentSite.units))}
            </TournamentTableCell>
            <TournamentTableCell>{group.teeNumber}</TournamentTableCell>
            <TournamentTableCell>{isTeamFormat ? player.team?.name : player.club?.name || '-'}</TournamentTableCell>
            <TournamentTableCell>{formatHandicap(startListPlayer.hcp?.toFixed(1).toString())}</TournamentTableCell>
            <TournamentTableCell>
              {isTogetherScoredTeamFormatId(_currentRound().primaryGame.id)
                ? _playerTeamHCP(player, group)
                : formatHandicap(startListPlayer.playingHandicap?.toString())}
            </TournamentTableCell>
            <TournamentTableCell>
              {player.gender === 'male' ? (
                <FormattedMessage id="strings.male" />
              ) : (
                <FormattedMessage id="strings.female" />
              )}
            </TournamentTableCell>
            <TournamentTableCell style={{ paddingRight: 20 }}>{startListPlayer.teeBox.teeboxName}</TournamentTableCell>
          </>
        ) : (
          <>
            <TournamentTableCell>
              <Typography>{moment(group.startTime).format(defaultFormat('time', tournamentSite.units))}</Typography>
            </TournamentTableCell>
            <TournamentTableCell>
              <Typography>{group.teeNumber}</Typography>
            </TournamentTableCell>
          </>
        )}
      </TournamentTableRow>
    )
  }

  return (
    <TournamentTable className={classes.root} arraySortKey={startlist[0].startListPlayers.map((p) => p.id).join('')}>
      <TournamentTableHead>
        <TournamentTableRow style={{ backgroundColor: '#555555' }}>
          {tournamentSite.countryFlagForPlayersEnabled && (
            <TournamentTableCell element="th" className={classes.flagCell} style={{ width: 40 }}></TournamentTableCell>
          )}
          <TournamentTableCell element="th">
            <div className={classes.headerNameContainer}>
              <FormattedMessage id="leaderboard.name" />
              {mediaQuery && (
                <div className={classes.extraInfoText}>
                  <span>
                    <FormattedMessage id={isTeamFormat ? 'strings.team' : 'strings.club'} />
                  </span>
                  |
                  <span>
                    <FormattedMessage id="leaderboard.tee" />
                  </span>
                </div>
              )}
            </div>
          </TournamentTableCell>
          {!mediaQuery ? (
            <>
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.time" />
              </TournamentTableCell>
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.hole" />
              </TournamentTableCell>
              <TournamentTableCell element="th">
                {isTeamFormat ? (
                  <FormattedMessage id="leaderboard.teamName" />
                ) : (
                  <FormattedMessage id="leaderboard.homeClub" />
                )}
              </TournamentTableCell>
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.hcpIndex" />
              </TournamentTableCell>
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.playingHcp" />
              </TournamentTableCell>
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.gender" />
              </TournamentTableCell>
              <TournamentTableCell element="th" style={{ paddingRight: 20 }}>
                <FormattedMessage id="leaderboard.tee" />
              </TournamentTableCell>
            </>
          ) : (
            <>
              <TournamentTableCell element="th" className={classes.smallCell}>
                <FormattedMessage id="leaderboard.time" />
              </TournamentTableCell>
              <TournamentTableCell element="th" className={classes.smallCell}>
                <FormattedMessage id="leaderboard.hole" />
              </TournamentTableCell>
            </>
          )}
        </TournamentTableRow>
      </TournamentTableHead>
      <TournamentTableBody>{_renderSortedPlayers(startlist)}</TournamentTableBody>
    </TournamentTable>
  )
}
