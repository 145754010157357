import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../dialogs/Alert'
import { FormattedMessageWrapper } from '../ui/FormattedMessageWrapper'
import { fetchBoughtProducts } from '@app/store/payments/actions'

interface Props {
  open: boolean
  okAction: () => void
  cancelAction: () => void
}

export const CancelEntryConfirmation: React.FC<Props> = ({ open, okAction, cancelAction }) => {
  const dispatch = useDispatch()
  const isRegistered = useSelector((store: StoreState) => store.registrationsReducer.registration) !== undefined
  const { userId, authToken, clientUid } =
    useSelector((store: StoreState) => store.authenticationReducer).user || ({} as GGBUser)
  const hasBoughtProducts = useSelector((store: StoreState) => store.paymentReducer.boughtProducts).length > 0

  useEffect(() => {
    if (isRegistered && !hasBoughtProducts) {
      dispatch(fetchBoughtProducts({ userId, authToken, clientUid }))
    }
  }, [isRegistered])

  const getText = (): React.ReactNode => {
    if (!hasBoughtProducts) {
      return <FormattedMessageWrapper id="strings.cancelConfirmationText" />
    }
    return (
      <>
        <p>
          <FormattedMessageWrapper id="strings.cancelEntryWithBoughtProducts" />
        </p>
        <p>
          <FormattedMessageWrapper id="strings.cancelConfirmationText" />
        </p>
      </>
    )
  }

  return (
    <Alert
      open={open}
      title={<FormattedMessageWrapper id="strings.cancelConfirmationTitle" />}
      text={getText()}
      okText={<FormattedMessageWrapper id="buttons.yes" />}
      cancelText={<FormattedMessageWrapper id="buttons.no" />}
      okAction={okAction}
      cancelAction={cancelAction}
    />
  )
}
