/* eslint-disable max-len */

export const SuccessIcon = ({
  style = {},
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 150 150',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <g id="Group 235">
      <circle
        id="Ellipse 249"
        cx="75.7353"
        cy="75.7338"
        r="30.1103"
        transform="rotate(128.544 75.7353 75.7338)"
        stroke="url(#paint0_linear_4411_12338)"
        strokeOpacity="0.15"
        strokeWidth="17"
        fill="none"
      />
      <circle
        id="Ellipse 250"
        cx="75.7346"
        cy="75.7335"
        r="47.0841"
        transform="rotate(-150.542 75.7346 75.7335)"
        stroke="url(#paint1_linear_4411_12338)"
        strokeOpacity="0.13"
        strokeWidth="17"
        fill="none"
      />
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.7344 105.733C92.3029 105.733 105.734 92.3012 105.734 75.7327C105.734 59.1641 92.3029 45.7327 75.7344 45.7327C59.1658 45.7327 45.7344 59.1641 45.7344 75.7327C45.7344 92.3012 59.1658 105.733 75.7344 105.733ZM89.636 70.8843C91.1005 69.4199 91.1005 67.0455 89.636 65.581C88.1716 64.1166 85.7972 64.1166 84.3327 65.581L71.9844 77.9294L67.136 73.081C65.6716 71.6165 63.2972 71.6165 61.8327 73.081C60.3683 74.5455 60.3683 76.9198 61.8327 78.3843L69.3327 85.8843C70.7972 87.3488 73.1716 87.3488 74.636 85.8843L89.636 70.8843Z"
        fill="#098F50"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4411_12338"
        x1="75.7353"
        y1="37.1235"
        x2="75.7353"
        y2="114.344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" />
        <stop offset="1" stopColor="#1AA85D" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4411_12338"
        x1="117.65"
        y1="22.4274"
        x2="75.7346"
        y2="131.318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" stopOpacity="0" />
        <stop offset="1" stopColor="#1AA85D" />
      </linearGradient>
    </defs>
  </svg>
)
