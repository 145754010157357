import { useDispatch, useSelector } from 'react-redux'
import { formatDefaultTournamentSeo } from '../../components/seo/utils'
import { ConstructedHead } from '../../components/seo/ConstructedHead'
import {
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Select,
  SelectChangeEvent,
  MenuItem,
  OutlinedInput,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { ArrowDropDown, ArrowDropUp, LaunchOutlined } from '@mui/icons-material'
import { defaultFormat } from '../../utils/date'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAnalytics } from '@app/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { getCalendar } from '@app/store/calendar/actions'
import { useLanguage } from '@app/hooks/useLanguage'
import { rem } from '@app/theme/materialUITheme'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: 0,
    padding: 0,
    backgroundColor: '#ffffff',
    maxHeight: '100%',
    minWidth: 500,
    overflow: 'hidden',
  },
  root: {
    backgroundColor: theme.customPalette.background,
    overflow: 'auto',
    padding: 20,
  },
  tableHead: {
    marginBottom: 10,
  },
  tableHeader: {
    color: theme.customPalette.mediumGray2,
  },
  tableBody: {
    '& td': {
      borderBottom: 0,
      height: 38,
    },
    '& tr:first-of-type td': {
      paddingTop: 15,
      height: 44,
    },
  },
  name: {
    fontWeight: 'bold',
    '& a': {
      color: theme.palette.primary.main,
    },
    '& a:hover, & a:active': {
      textDecoration: 'none',
    },
  },
  noUpcomingTournamentsContainer: {
    marginTop: 10,
  },
  select: {
    height: rem(40),
    fontSize: rem(14),
    fontWeight: 500,
    borderRadius: Number(theme.shape.borderRadius) * 6,
    padding: `${rem(1)} ${rem(1)}`,
    textTransform: 'none',
    color: theme.palette.primary.main,
    border: `${rem(2)} solid ${theme.palette.primary.main}`,
    width: rem(200),
    '& > .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& > fieldset': {
      border: '0px none',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },
}))

export const Calendar = () => {
  const dispatch = useDispatch()
  const { calendarId } = useParams()
  const { logPageView } = useAnalytics()
  const classes = useStyles()
  const calendar = useSelector((state: StoreState) => state.calendarReducer)
  const { languageCode: language, setLanguageCode } = useLanguage()
  const [urlParams, setUrlParams] = useSearchParams()
  const sort = urlParams.get('sort') || 'ASC'
  const lang = urlParams.get('lang') || 'en-US'
  const currentYear = moment().year()
  const [year, setYear] = useState<number>(currentYear)

  useEffect(() => {
    logPageView()
  }, [logPageView])

  useEffect(() => {
    if (calendar.calendars.length > 0) {
      // Post message about the content height to iframe
      const height = document.getElementById('calendar-content').offsetHeight
      window.parent.postMessage(height + 2, '*') // add borders
    }
  }, [calendar])

  useEffect(() => {
    if (calendarId && calendar.calendarId !== calendarId) {
      dispatch(getCalendar(calendarId))
    }
  }, [calendar.calendarId, calendarId, dispatch])

  useEffect(() => {
    if (lang !== language) {
      setLanguageCode(lang)
    }
  }, [lang, language, setLanguageCode])

  if (!calendar.calendars) {
    return null
  }

  const calendars = calendar.calendars.slice()
  if (sort === 'DESC') calendars.reverse()
  const filteredCalendars = useMemo(() => {
    return calendars.filter((c) => moment(c.startTime).year() === year)
  }, [calendars])

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number
    setYear(value)
  }

  const handleToggleSortOrder = () => {
    const newSortOrder = sort === 'ASC' ? 'DESC' : 'ASC'
    urlParams.set('sort', newSortOrder)
    setUrlParams(urlParams)
  }

  const getYears = useCallback((): number[] => {
    const years: number[] = []

    calendars.forEach((c) => {
      const year = moment(c.startTime).year()
      if (!years.includes(year)) {
        years.push(year)
      }
    })

    if (!years.includes(currentYear)) {
      years.push(currentYear)
    }

    return years.sort((a, b) => b - a)
  }, [calendars])

  return (
    <>
      <ConstructedHead {...formatDefaultTournamentSeo({ pageName: 'Calendar' })} noIndex />
      <main className={classes.wrapper}>
        <div id="calendar-content" className={classes.root}>
          <div className={classes.header}>
            <Typography variant={'h2'}>
              <FormattedMessage id={'calendar.calendar'} />
            </Typography>
            <Select
              variant="filled"
              className={classes.select}
              value={year}
              input={<OutlinedInput />}
              onChange={handleYearChange}
            >
              {getYears().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </div>
          <TableContainer>
            <Table stickyHeader size={'small'} style={{ marginTop: 10 }}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeader} style={{ paddingLeft: 0 }}>
                    <FormattedMessage id={'calendar.name'} />
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <FormattedMessage id={'calendar.date'} />
                    <IconButton onClick={handleToggleSortOrder} style={{ height: 20, width: 20 }}>
                      {sort === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableHeader} style={{ textAlign: 'center' }}>
                    <FormattedMessage id={'calendar.infoAndSignUp'} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {filteredCalendars.length ? (
                  filteredCalendars.map((t) => (
                    <TableRow key={t.id}>
                      <TableCell style={{ paddingLeft: 0 }} className={classes.name}>
                        <a href={t.url} target={'_blank'} rel="noreferrer">
                          {t.name}
                        </a>
                      </TableCell>
                      <TableCell>{moment(t.startTime).format(defaultFormat('date', t.units))}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {t.url && (
                          <a
                            style={{ lineHeight: 0, padding: '0 5px' }}
                            href={t.url}
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            <LaunchOutlined color={'primary'} fontSize={'small'} />
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div className={classes.noUpcomingTournamentsContainer}>
                    <Typography variant="caption">
                      <FormattedMessage id="calendar.noUpcomingTournamentsText" />
                    </Typography>
                  </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </main>
    </>
  )
}
