import { createStyles, withStyles, WithStyles } from '@mui/styles'
import React from 'react'
import { connect } from 'react-redux'

const styles = () =>
  createStyles({
    img: {
      maxHeight: 200,
      maxWidth: 200,
      margin: '30px auto 0',
      display: 'block',
    },
  })

interface OwnProps {
  logo: TournamentFile
}

type Props = OwnProps & WithStyles<typeof styles>

class TourLogo extends React.Component<Props> {
  render() {
    const { logo, classes } = this.props
    const logoUrl = logo && logo.url ? logo.url : null

    if (!logoUrl) {
      return null
    }

    return <img src={logoUrl} alt={logoUrl} className={classes.img} />
  }
}

export default withStyles(styles)(
  connect((store: StoreState) => ({
    logo: store.tourAndRankingReducer.tour.logo,
  }))(TourLogo),
)
