import { GetCalendarAction, GetCalendarSuccessAction, GetCalendarErrorAction } from './actions'
import { CALENDAR_ERROR, CALENDAR_SUCCESS, CALENDAR_REQUESTING } from './constants'

export const initialState: CalendarState = {
  calendarId: '',
  calendars: [],
  loading: false,
  error: undefined,
}

type CalendarAction = GetCalendarAction | GetCalendarSuccessAction | GetCalendarErrorAction

export const calendarReducer = (state: CalendarState = initialState, action: CalendarAction): CalendarState => {
  switch (action.type) {
    case CALENDAR_REQUESTING:
      return {
        ...initialState,
        loading: true,
        error: undefined,
      }
    case CALENDAR_SUCCESS:
      return {
        ...initialState,
        loading: false,
        calendarId: action.calendarId,
        calendars: action.calendars,
        error: undefined,
      }
    case CALENDAR_ERROR:
      return {
        ...initialState,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
