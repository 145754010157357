import Typography from '@mui/material/Typography'
import { connect, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import RegisterToTourButton from '../ui/RegisterToTourButton'
import { defaultFormat } from '@app/utils/date'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { pageShouldShowHeroSignUpButton } from '@app/utils/pageUtils'
import { TabsEnum } from '@app/utils/enums'
import { signUpEnabled } from '@app/utils/signUpUtils'

const useStyles = makeStyles(() => ({
  signUpText: {
    marginBottom: 16,
  },
  loginText: {
    textAlign: 'center',
    maxWidth: 500,
  },
  entryDeadlineCancelText: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '10px',
  },
}))

type Props = {
  site?: TournamentSite
  onlyBtn?: boolean
}

const SignUp = ({ site, onlyBtn = false }: Props) => {
  const classes = useStyles()
  const currentPage: TabsEnum | null = useSelector(
    (store: StoreState) => store.pageReducer.currentPage || null,
  ) as TabsEnum

  if (!site || !site.onlineEntriesEnabled) {
    return null
  }

  const registerBtnMargin = '20px 0 15px'

  if (onlyBtn) {
    const showButton = pageShouldShowHeroSignUpButton(currentPage) || signUpEnabled(site)
    if (!showButton) {
      return
    }
    return (
      <Grid container sx={{ width: 1 }} justifyContent="center" alignItems="center">
        <Grid item>
          <RegisterToTourButton style={{ margin: registerBtnMargin }} />
        </Grid>
      </Grid>
    )
  }

  const entryOpen = moment.parseZone(site.registrationStartsTime).format(defaultFormat('both', site.units))
  const entryEnd = moment.parseZone(site.registrationEndsTime).format(defaultFormat('both', site.units))
  const cancelBy = moment.parseZone(site.registrationCancellingEndsTime).format(defaultFormat('both', site.units))

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="h2" className={classes.signUpText}>
          <FormattedMessage id={'buttons.signUp'} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.loginText}>
          <FormattedMessage id={'strings.loginText'} />
        </Typography>
      </Grid>
      <Grid item>
        <RegisterToTourButton style={{ margin: registerBtnMargin }} />
      </Grid>
      {site.registerAndCancelTimeFramesEnabled && entryEnd !== 'Invalid date' && (
        <Grid item>
          <Typography variant={'body1'} className={classes.entryDeadlineCancelText}>
            <FormattedMessage
              id="strings.entryDeadline"
              values={{
                startDate: entryOpen,
                endDate: entryEnd,
              }}
            />
            <br />
            <FormattedMessage
              id="strings.cancellationsBy"
              values={{
                date: cancelBy,
              }}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default connect((state: StoreState) => ({
  site: state.tournamentReducer.tournamentSite,
}))(SignUp)
