import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import {
  Theme,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import TournamentTableRow from '../table/TournamentTableRow'
import TournamentTableCell from '../table/TournamentTableCell'
import TournamentTable, { TournamentTableHead, TournamentTableBody } from '../table/TournamentTable'
import { FormattedMessage } from 'react-intl'
import { ArrowDropDown, OpenInNew } from '@mui/icons-material'
import { getRanking } from '@app/store/tourAndRanking/actions'
import { rem } from '@app/theme/materialUITheme'
import RankingLeaderboardTitle from './RankingLeaderboardTitle'
import { formTournamentSiteUrl } from '@app/config'
import { formatOutputNumber } from '@app/utils/numbers'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0 0 12px 3px rgba(0,0,0,0.15)',
    backgroundColor: theme.customPalette.background,
  },
  flag: {
    display: 'block',
    margin: '0 auto',
    width: 24,
  },
  bold: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  table: {
    background: theme.customPalette.background,
    '& > thead > tr > th': {
      fontFamily: ['Exo', 'sans-serif'].join(','),
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      fontSize: rem(14),
      background: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
      paddingTop: 4,
      paddingBottom: 2,
      paddingRight: 0,
      paddingLeft: 8,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: rem(10),
      },
    },
    '& > tbody': {
      border: `2px solid ${theme.palette.primary.dark}`,
    },
    '& > tbody > tr > td': {
      fontFamily: ['Exo', 'sans-serif'].join(','),
      backgroundColor: theme.customPalette.lightGray,
      border: 0,
      fontSize: rem(13),
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 0,
      textAlign: 'left',
      paddingLeft: 8,
      [theme.breakpoints.down('sm')]: {
        fontSize: rem(10),
      },
    },
    '& > tbody > tr.bold > td': {
      fontWeight: 'bold',
    },
  },
  rankingTable: {
    fontFamily: ['Exo', 'sans-serif'].join(','),
    color: theme.customPalette.tableTextColor,
  },
  leaderboardPosTitle: {
    width: 40,
    textAlign: 'center',
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      paddingLeft: 10,
    },
  },
  leaderboardNameCell: {
    cursor: 'pointer',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textOverflow: 'ellipsis',
    },
  },
  leaderboardArrowDownTitle: {
    width: 40,
    [theme.breakpoints.down('sm')]: {
      width: 10,
    },
  },
  leaderboardEventsTitle: {
    textAlign: 'center',
  },
  leaderboardPointsTitle: {
    textAlign: 'center',
    paddingRight: 20,
  },
  eventsTableNameTitle: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      paddingRight: 40,
    },
  },
  eventsTableNameCell: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      paddingRight: 40,
    },
  },
  nameContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 100,
    },
  },
  namePosContainer: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 60,
    },
  },
  bodyCell: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 10px 5px',
    },
  },
  headCell: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 10px 5px',
      width: 20,
    },
  },
  eventsContainer: {
    [theme.breakpoints.down('sm')]: {
      width: 70,
    },
  },
  eventsPosCell: {
    width: 1,
    whiteSpace: 'nowrap',
    paddingRight: 20,
    textAlign: 'right',
  },
  eventsPointsCell: {
    width: 1,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  openInNew: {
    marginLeft: 7,
    fontSize: 16,
    position: 'relative',
    top: 3,
  },
  tableHeaderRow: {
    backgroundColor: '#555555',
  },
}))

interface Props {
  id: number
}

interface RankingResultRow extends RankingResult {
  position: string
}

export const RankingLeaderboard: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  const { rankings } = useSelector((store: StoreState) => store.tourAndRankingReducer)
  const ranking = rankings.find((r) => r.id === id)

  const [scoreExpanded, changeScoreExpanded] = useState<number>(undefined)

  useEffect(() => {
    if (id && !rankings.length) {
      dispatch(getRanking(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ranking) {
    return
  }

  const _renderRow = (i: number, result: RankingResultRow, bestResultsCount?: number) => {
    const expanded = scoreExpanded === result.playerId

    return (
      <React.Fragment key={result.playerId}>
        <TournamentTableRow withBg={i % 2 === 0 ? true : false} expanded={expanded} rowKey={String(result.playerId)}>
          <TournamentTableCell style={{ textAlign: 'center', fontWeight: 600 }}>{result.position}</TournamentTableCell>
          {!mediaQuery && (
            <TournamentTableCell>
              {result.countryCode && (
                <img
                  src={`//static.golfgamebook.com/flags/${result.countryCode}.png`}
                  alt={result.countryCode}
                  className={classes.flag}
                />
              )}
            </TournamentTableCell>
          )}
          <TournamentTableCell className={classNames([classes.leaderboardNameCell, classes.bodyCell])}>
            <div className={classes.nameContainer}>
              <div className={classes.namePosContainer}>
                <span
                  onClick={() => {
                    const newId: number = expanded ? 0 : result.playerId
                    changeScoreExpanded(newId)
                  }}
                >
                  {result.playerName}
                </span>
              </div>
            </div>
          </TournamentTableCell>
          <TournamentTableCell>
            <IconButton
              style={{
                padding: 0,
                margin: 0,
                color: expanded ? '#fff' : 'inherit',
                transform: expanded ? 'rotate(180deg)' : 'none',
              }}
              onClick={() => {
                const newId: number = expanded ? 0 : result.playerId
                changeScoreExpanded(newId)
              }}
              size="large"
            >
              <ArrowDropDown />
            </IconButton>
          </TournamentTableCell>
          <TournamentTableCell style={{ textAlign: 'center', fontWeight: 500 }} className={classes.bodyCell}>
            <strong>{result.events.length}</strong>
          </TournamentTableCell>
          <TournamentTableCell style={{ textAlign: 'center' }} className={classes.bodyCell}>
            <span className={expanded ? '' : classes.bold}>
              <strong>{formatOutputNumber(result.points)}</strong>
            </span>
          </TournamentTableCell>
        </TournamentTableRow>
        <TournamentTableRow>
          <TournamentTableCell colSpan={6} style={{ padding: 0 }}>
            <Collapse in={expanded}>{_renderEventsTable(result.playerId, result.events, bestResultsCount)}</Collapse>
          </TournamentTableCell>
        </TournamentTableRow>
      </React.Fragment>
    )
  }

  const _calculatePositions = (results: RankingResult[]): RankingResultRow[] => {
    results.sort((a, b) => b.points - a.points)
    let lastPoints = null
    let lastPosition = null

    return results.map((row, idx) => {
      const position = row.points === lastPoints ? lastPosition : idx + 1
      lastPoints = row.points
      lastPosition = position
      return { ...row, position: `${position}.` } as RankingResultRow
    }) as RankingResultRow[]
  }

  const _renderEventsTable = (playerId: number, events: RankingResultEvent[], bestResultsCount?: number) => {
    return (
      <Table className={classes.table} key={`events-${playerId}`}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.eventsTableNameTitle}>
              <FormattedMessage id="leaderboard.events" />
              {bestResultsCount && (
                <span style={{ fontWeight: 400, marginLeft: 10, fontSize: '90%' }}>
                  (<FormattedMessage id="leaderboard.bestResultsCount" values={{ count: bestResultsCount }} />)
                </span>
              )}
            </TableCell>
            <TableCell style={{ whiteSpace: 'nowrap', paddingRight: 20 }}>
              <FormattedMessage id="leaderboard.position" />
            </TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
              <FormattedMessage id="leaderboard.points" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event, i) => (
            <TableRow key={i} className={bestResultsCount && i < bestResultsCount ? 'bold' : ''}>
              <TableCell className={classes.eventsTableNameCell}>
                <div className={classes.eventsContainer}>
                  {event.name}
                  {event.tournamentSite && (
                    <a href={formTournamentSiteUrl(event.tournamentSite)}>
                      <OpenInNew color="primary" className={classes.openInNew} />
                    </a>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.eventsPosCell}>{event.position ? `${event.position}.` : ''}</TableCell>
              <TableCell className={classes.eventsPointsCell}>{event.points}</TableCell>
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  const leaderboardRows = _calculatePositions(ranking.leaderboard)

  return (
    <div className={classes.root}>
      <RankingLeaderboardTitle ranking={ranking} />
      <TournamentTable className={classes.rankingTable}>
        <TournamentTableHead>
          <TournamentTableRow darkBg>
            <TournamentTableCell element="th" className={classes.leaderboardPosTitle}>
              {!mediaQuery && <FormattedMessage id="leaderboard.pos" />}
            </TournamentTableCell>
            {!mediaQuery && (
              <TournamentTableCell element="th">
                <FormattedMessage id="leaderboard.country" />
              </TournamentTableCell>
            )}
            <TournamentTableCell element="th" className={classes.headCell}>
              <FormattedMessage id="leaderboard.name" />
            </TournamentTableCell>
            <TournamentTableCell className={classes.leaderboardArrowDownTitle}></TournamentTableCell>
            <TournamentTableCell
              element="th"
              className={classNames([classes.leaderboardEventsTitle, classes.headCell])}
            >
              <FormattedMessage id="leaderboard.events" />
            </TournamentTableCell>
            <TournamentTableCell
              element="th"
              className={classNames([classes.leaderboardPointsTitle, classes.headCell])}
            >
              <FormattedMessage id="leaderboard.points" />
            </TournamentTableCell>
          </TournamentTableRow>
        </TournamentTableHead>
        <TournamentTableBody>
          {leaderboardRows.map((entry, i) => _renderRow(i, entry, ranking.bestResultsCount))}
        </TournamentTableBody>
      </TournamentTable>
    </div>
  )
}
