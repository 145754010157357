import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './indexReducer'
import rootSaga from './indexSagas'
import * as authReducer from './authentication/reducer'
import * as localeReducer from './locale/reducer'
import * as tournamentReducer from './tournament/reducer'
import * as registrationsReducer from './registrations/reducer'
import * as tournamentPlayersReducer from './tournamentPlayers/reducer'
import * as tournamentStartlistsReducer from './tournamentStartlists/reducer'
import * as tournamentLeaderboardReducer from './tournamentLeaderboard/reducer'
import * as tournamentContestLeaderboardReducer from './tournamentContestLeaderboard/reducer'
import * as clubsAndCountriesReducer from './clubsAndCountries/reducer'
import * as tourAndRankingReducer from './tourAndRanking/reducer'
import * as calendarReducer from './calendar/reducer'
import * as paymentReducer from './payments/reducer'
import * as pageReducer from './pages/reducer'
import { api } from './api/baseApi'

const _initialState = {
  authenticationReducer: authReducer.initialState,
  tournamentReducer: tournamentReducer.initialState,
  localeReducer: localeReducer.initialState,
  registrationsReducer: registrationsReducer.initialState,
  tournamentPlayersReducer: tournamentPlayersReducer.initialState,
  tournamentStartlistsReducer: tournamentStartlistsReducer.initialState,
  tournamentLeaderboardReducer: tournamentLeaderboardReducer.initialState,
  tournamentContestLeaderboardReducer: tournamentContestLeaderboardReducer.initialState,
  clubsAndCountriesReducer: clubsAndCountriesReducer.initialState,
  tourAndRankingReducer: tourAndRankingReducer.initialState,
  calendarReducer: calendarReducer.initialState,
  paymentReducer: paymentReducer.initialState,
  pageReducer: pageReducer.initialState,
}

const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    // TODO: fix the actual issue with unserializable data (dates) in store
    getDefaultMiddleware({ serializableCheck: false })
      .concat(sagaMiddleware) // allows redux devtools to watch sagas
      .concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: _initialState,
})
;(store as any).sagaTask = sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
