import React from 'react'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Footer from './Footer'
import { ConstructedHead, ConstructedHeadProps } from '../seo/ConstructedHead'
import Background from '../ui/Background'
import classNames from 'classnames'
import AppAd from '../ui/AppAd'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    minWidth: 300,
    padding: '0 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  root: {
    zIndex: 2,
    position: 'relative',
    maxWidth: 1200,
    width: '90%',
    margin: '0 auto',
    minHeight: 'calc(100vh - 210px)',
    boxSizing: 'border-box',
    boxShadow: '0 0 22px 4px rgba(0,0,0,0.15)',
    backgroundColor: theme.customPalette.background,
    display: 'grid',
    gridTemplateRows: 'min-content auto min-content',
    gridTemplateColumns: '100%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  narrow: {
    maxWidth: 1000,
  },
}))

interface PageProps {
  hero?: TournamentFile
  children?: React.ReactNode
  seo?: ConstructedHeadProps
  narrow?: boolean
}

export const Page = (props: PageProps) => {
  const classes = useStyles()

  const { children, seo = {}, narrow, hero } = props

  return (
    <>
      <Background image={hero} />
      <ConstructedHead {...seo} />
      <main className={classes.wrapper}>
        <div className={classNames(classes.root, narrow && classes.narrow)}>
          {children}
          <AppAd />
        </div>
      </main>
      <Footer />
    </>
  )
}
