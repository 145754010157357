import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT } from '../../config'
import { CacheTag } from './cacheTags'

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
  }),
  tagTypes: Object.values(CacheTag),
  endpoints: () => ({}),
})

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
