import axios from 'axios'
import { put, select, all } from '@redux-saga/core/effects'
import { getTournamentId } from '../../tournament/selectors'
import { APIRoute } from '../../../config'
import { find, toInteger } from 'lodash'
import { FETCH_REGISTRATIONS_FAILURE, FETCH_REGISTRATIONS_SUCCESS } from '../constants'
import { USER_FIELD_UPDATE, QUESTION_FIELD_UPDATE } from '../../../store/authentication/constants'

export default function* doFetchRegistrations({ payload }: LoginSuccessAction) {
  try {
    const { userId, authToken } = payload

    const tournamentId = yield select(getTournamentId)

    const { data } = yield axios.get(APIRoute.GET_REGISTRATIONS(tournamentId, toInteger(userId)), {
      params: {
        authToken,
      },
    })
    const { registrations } = data
    const registration: UserRegistration = find(registrations, { tournamentId: tournamentId })

    if (registration) {
      yield put({
        type: USER_FIELD_UPDATE,
        payload: {
          fieldName: 'hcp',
          value: registration.handicap,
        },
      })

      yield put({
        type: USER_FIELD_UPDATE,
        payload: {
          fieldName: 'teebox',
          value: registration.teebox,
        },
      })

      yield put({
        type: USER_FIELD_UPDATE,
        payload: {
          fieldName: 'divisionId',
          value: registration.divisionId,
        },
      })

      yield all(
        registration.customQuestions.map((q) =>
          put({
            type: QUESTION_FIELD_UPDATE,
            payload: {
              questionId: q.id,
              answer: q.answer,
            },
          }),
        ),
      )
    }

    yield put({
      type: FETCH_REGISTRATIONS_SUCCESS,
      registrations,
      registration,
    })
  } catch (error) {
    console.log(`User registration ${payload.userId} requested - ${error}`)
    yield put({
      type: FETCH_REGISTRATIONS_FAILURE,
      error: error.toString(),
    })
  }
}
