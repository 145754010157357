import axios from 'axios'
import { APIRoute } from '../../../config'
import { GetTournamentAction, getTournamentError, getTournamentSuccess } from '../actions'
import { put } from 'redux-saga/effects'
import { TOURNAMENT_SET_SELECTED_ROUND } from '../constants'

function* getTournamentFlow({ tournamentId, screen }: GetTournamentAction) {
  try {
    const res = yield axios.get(APIRoute.GET_TOURNAMENT(tournamentId), {
      params: {
        screen,
      },
    })

    if (res && !res.error) {
      // Remove timeZone from startTime and endTime
      res.data.tournament.startTime = res.data.tournament.startTime.substr(0, 19)
      res.data.tournament.rounds.forEach((e) => {
        e.startTime = e.startTime && e.startTime.substr(0, 19)
        e.endTime = e.endTime && e.endTime.substr(0, 19)
      })
      yield put({
        type: TOURNAMENT_SET_SELECTED_ROUND,
        roundIndex: 0,
      })
      yield put(getTournamentSuccess(res.data))
    } else {
      yield put(getTournamentError(res.data.error))
    }
  } catch (error) {
    console.log(`Tournament ${tournamentId} requested - ${error}`)
    yield put(getTournamentError(error))
  }
}

export default getTournamentFlow
