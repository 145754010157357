import React from 'react'

export const InfoIcon = ({ style = {}, width = '100%', className = '', height = '100%', viewBox = '0 0 55 55' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <path d="M27.5,4.5a23,23,0,1,1-23,23,23,23,0,0,1,23-23m0-2a25,25,0,1,0,25,25,25,25,0,0,0-25-25Z" />
    <rect x="25.94" y="24.38" width="3.13" height="12.5" />
    <circle cx="27.5" cy="21.26" r="1.55" />
  </svg>
)
