import React from 'react'
import {
  Typography,
  Grid,
  FormLabel,
  Checkbox,
  TextField,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  Theme,
  Button,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { FormattedMessage } from 'react-intl'
import Avatar from '@mui/material/Avatar'
import FormControl from '@mui/material/FormControl'
import CustomQuestions from './CustomQuestions'
import TourButton from '../ui/TourButton'
import { connect } from 'react-redux'
import { updateUserField } from '../../store/authentication/actions'
import UnregisterFromTournamentAction from './UnregisterFromTournamentAction'
import { LineDivider } from '../dialogs/LineDivider'
import { validFemaleTeeBoxes, validMaleTeeBoxes, maxEntriesAmountExceeded } from '../../utils/tournamentUtils'
import {
  formatDirtyHcpToHcp,
  formatHandicap,
  formatHcpToDirtyHcp,
  isTeamCreator,
  trimHcpDecimals,
  validateHandicapValue,
} from '../../utils/playerUtils'
import { rem } from '@app/theme/materialUITheme'
import { DivisionType, TournamentTypes } from '@app/utils/enums'

interface OwnProps {
  loading?: boolean
  questionErrors: { [property: string]: boolean }
  mandatoryDivisionError: boolean
  handleJoinTournament?(): void
  handleEditRegistration?(): void
  handleContinueToPayment?(): void
  isRegistered?: boolean
  isTeam?: boolean
  registration?: UserRegistration
  disableSignUp?: boolean
  divisions: Divisions[]
  divisionsEnabled: boolean
  divisionSelectionRequired: boolean
  handleStep?(): void
  joined?: boolean
  clearErrors(): void
}

interface StateProps {
  auth: AuthenticationState
  registration: UserRegistration
  termsAndConditions: string | null
  customQuestionsEnabled: boolean
  rounds: TournamentRound[]
  teeboxSelectionEnabled: boolean
  players: Player[]
  tournamentSite: TournamentSite
  tournamentPlayers: TournamentPlayersState
}

interface DispatchProps {
  updateUserField(payload: FieldUpdatePayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  acceptTerms: boolean
  divisionId?: number
}

const getDivisionClass = (theme: Theme) => {
  return {
    marginRight: 24,
    '& .MuiChip-label': {
      color: theme.customPalette.siteNavigationText,
      fontWeight: 500,
    },
    '&.MuiChip-filledPrimary': {
      padding: `${rem(12)}`,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      '& .MuiChip-label': {
        color: theme.palette.primary.main,
      },
    },
    '&.MuiChip-outlinedPrimary': {
      border: '1px solid transparent',
      boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontWeight: 'bold',
      padding: `${rem(12)}`,
    },
  }
}

const styles = (theme: Theme) =>
  createStyles({
    division: getDivisionClass(theme),
    divisionError: {
      ...getDivisionClass(theme),
      '& .MuiChip-label': {
        color: theme.palette.error.main,
      },
      '&.MuiChip-outlinedPrimary': {
        border: `1px solid ${theme.palette.error.main}`,
        boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontWeight: 'bold',
        padding: `${rem(12)}`,
      },
    },
    buttonsContainer: {
      justifyContent: 'space-between',
      display: 'flex',
      gap: 10,
    },
    button: {
      minWidth: 150,
      padding: `${rem(13)} ${rem(14)}`,
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: 10,
      '& > *': {
        maxHeight: 50,
      },
    },
    actionButtons: {
      display: 'flex',
    },
    terms: {
      marginLeft: 10,
    },
    divisionContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        '& > div': {
          maxWidth: '50%',
          marginBottom: 15,
        },
      },
    },
    teeboxField: {
      height: 35,
      minWidth: 120,
    },
    hcpField: {
      height: 35,
      width: 65,
      '& > div': {
        height: 35,
      },
    },
    hcpLabel: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 800,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontStyle: 'italic',
    },
    teeboxLabel: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 800,
      marginBottom: 8,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontStyle: 'italic',
    },
    name: {
      fontWeight: 500,
    },
    teeBoxInfo: {
      marginTop: 10,
      marginLeft: 0,
    },
  })

class IndividualTournamentEntryForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      acceptTerms: false,
    }
  }

  handleDivisionSelect = (id: number) => {
    const { divisionId } = this.props.auth.user
    this.props.updateUserField({ fieldName: 'divisionId', value: id === divisionId ? undefined : id })
    if (this.props.mandatoryDivisionError) {
      this.props.clearErrors()
    }
  }

  handleContinue = () => {
    this.props.handleContinueToPayment()
    this.props.handleStep()
  }

  tournamentHasManualDivisions = () => {
    return this.props.divisions.some((division) => division.type === DivisionType.MANUAL)
  }

  render() {
    const hcp = this.props.auth.user.hcp
    const dirtyHcp = this.props.auth.user.dirtyHcp
    const {
      tournamentPlayers,
      divisions,
      divisionsEnabled,
      divisionSelectionRequired,
      mandatoryDivisionError,
      classes,
      tournamentSite,
      isRegistered,
      termsAndConditions,
      handleJoinTournament,
      handleEditRegistration,
    } = this.props
    const courseTees = this.props.rounds[0].course.teeBoxes
    const teeboxes =
      this.props.auth.user.gender === 'female' ? validFemaleTeeBoxes(courseTees) : validMaleTeeBoxes(courseTees)
    let defaultValue = this.props.auth.user.teebox ? this.props.auth.user.teebox : null

    if (!defaultValue)
      defaultValue =
        this.props.auth.user.gender === 'female'
          ? this.props.rounds[0].teeBoxWomen.id
          : this.props.rounds[0].teeBoxMen.id

    const useReserveList = tournamentSite.reserveListEnabled
      ? maxEntriesAmountExceeded(tournamentSite, tournamentPlayers)
      : !tournamentSite.reserveListEnabled

    const shouldShowQuestionsValidationError = Object.keys(this.props.questionErrors).length > 0 && !this.props.isTeam

    return (
      <div>
        <Grid container spacing={2}>
          {!this.props.isTeam && (
            <>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ marginBottom: 10 }}>
                  <FormattedMessage id="strings.yourInformation" />
                </Typography>
                {!this.props.isRegistered && (
                  <Typography variant={'body1'} style={{ marginBottom: 15 }}>
                    <FormattedMessage id={'strings.confirmDetails'} />
                  </Typography>
                )}
                <Grid container spacing={2}>
                  {this._user.avatarUrl && (
                    <Grid item>
                      <Avatar src={this._user.avatarUrl} style={{ width: 75, height: 75 }} />
                    </Grid>
                  )}
                  <Grid item style={{ marginRight: 20 }}>
                    <Typography variant={'body2'}>
                      <span className={classes.name}>{`${this._user.firstName} ${this._user.lastName}`}</span>
                    </Typography>
                    <Typography variant={'body2'}>
                      {<FormattedMessage id={`strings.${this._user.gender}`} />}
                    </Typography>
                    <Typography variant={'body2'}>
                      {this._user.club ? this._user.club : <FormattedMessage id={'strings.noHomeClub'} />}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ paddingLeft: 20, paddingTop: 0 }}>
                    <div style={{ marginTop: 15, display: 'flex' }}>
                      <FormControl margin={'none'} style={{ marginRight: 20 }}>
                        <FormLabel htmlFor={'user-hcp'} className={this.props.classes.hcpLabel}>
                          <FormattedMessage id={'strings.hcp'} />
                        </FormLabel>

                        <TextField
                          id={'user-hcp'}
                          variant={'outlined'}
                          margin={'dense'}
                          style={{ maxWidth: 80 }}
                          value={dirtyHcp !== undefined ? dirtyHcp : formatHandicap(String(hcp))}
                          disabled={tournamentSite.tournament.tournamentType === TournamentTypes.weekly}
                          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const value = trimHcpDecimals(e.currentTarget.value)
                            const isNonEmptyHcpValue = value && value !== '+'
                            const isValid = validateHandicapValue(value).valid

                            if (isNonEmptyHcpValue && !isValid) return

                            this.props.updateUserField({
                              fieldName: 'dirtyHcp',
                              value,
                            })

                            this.props.updateUserField({
                              fieldName: 'hcp',
                              value: formatDirtyHcpToHcp(value),
                            })
                          }}
                          className={this.props.classes.hcpField}
                          error={dirtyHcp !== undefined && !validateHandicapValue(dirtyHcp).valid}
                        />
                      </FormControl>
                      {this.props.teeboxSelectionEnabled && (
                        <FormControl margin={'none'}>
                          <FormLabel htmlFor={'user-teebox'} className={this.props.classes.teeboxLabel}>
                            <FormattedMessage id={'strings.teebox'} />
                          </FormLabel>

                          <Select
                            name="user-teebox"
                            id="user-teebox"
                            value={defaultValue}
                            margin={'dense'}
                            input={<OutlinedInput name="user-teebox" id="user-teebox" />}
                            onChange={(event) => {
                              this.props.updateUserField({
                                fieldName: 'teebox',
                                value: event.target.value,
                              })
                            }}
                            className={this.props.classes.teeboxField}
                          >
                            {teeboxes.map((t) => (
                              <MenuItem key={t.id} value={t.id}>
                                {t.teeboxName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                    {this.props.isRegistered && tournamentSite.tournament.tournamentType === TournamentTypes.weekly && (
                      <Typography variant={'body1'} className={this.props.classes.teeBoxInfo}>
                        <FormattedMessage id={'strings.teeBoxInfo'} />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {this.tournamentHasManualDivisions() && divisionsEnabled && (
            <>
              {!this.props.isTeam && (
                <Grid item xs={12}>
                  <LineDivider />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h2" style={{ marginBottom: 10 }}>
                  <FormattedMessage id="strings.division" />
                </Typography>
                <Typography style={{ marginBottom: 20 }}>
                  <FormattedMessage id="strings.divisionDesc" />
                </Typography>
                <div className={classes.divisionContainer}>
                  {divisions
                    .filter((division) => division.type === DivisionType.MANUAL)
                    .map(({ id, name }: Divisions) => {
                      const isSelected = id === this.props.auth.user.divisionId
                      const className =
                        divisionSelectionRequired && mandatoryDivisionError ? classes.divisionError : classes.division
                      return (
                        <Chip
                          key={id}
                          clickable
                          label={name}
                          color="primary"
                          variant={isSelected ? 'filled' : 'outlined'}
                          className={className}
                          onClick={() => this.handleDivisionSelect(id)}
                          disabled={!isTeamCreator(this.props.tournamentPlayers.teams, this.props.auth.user)}
                        />
                      )
                    })}
                </div>
              </Grid>
              {this.props.isTeam && (
                <Grid item xs={12}>
                  <LineDivider />
                </Grid>
              )}
            </>
          )}

          {this.props.customQuestionsEnabled && (
            <>
              {!this.props.isTeam && (
                <Grid item xs={12}>
                  <LineDivider />
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomQuestions errors={this.props.questionErrors} />
              </Grid>
            </>
          )}

          {shouldShowQuestionsValidationError && (
            <Typography variant="body1" color="error" style={{ marginTop: 20, marginLeft: 15 }}>
              <FormattedMessage id="strings.invalidEntry" />
            </Typography>
          )}

          {!this.props.isTeam && (
            <Grid item xs={12}>
              <LineDivider />
            </Grid>
          )}

          {isRegistered && (
            <>
              <Grid item xs={12} paddingTop={0}>
                <UnregisterFromTournamentAction />
              </Grid>
            </>
          )}

          {!this.props.isRegistered && this.props.termsAndConditions && !this.props.isTeam && (
            <div className={classes.terms}>
              <Typography variant="body1">
                <Checkbox
                  id="acceptTerms"
                  name="acceptTerms"
                  color="primary"
                  required
                  checked={this.state.acceptTerms}
                  onChange={(e) => this.setState({ acceptTerms: e.currentTarget.checked })}
                />
                <FormattedMessage
                  id="strings.acceptCustomTermsAndConditions"
                  values={{
                    terms: (
                      <a
                        href={this.props.termsAndConditions}
                        target="_blank"
                        style={{ color: 'inherit' }}
                        rel="noreferrer"
                      >
                        <FormattedMessage id="strings.acceptTerms2" />
                      </a>
                    ),
                  }}
                />
              </Typography>
            </div>
          )}

          {(!this.props.isTeam ||
            (this.props.isTeam && this.props.isRegistered && this.props.customQuestionsEnabled)) && (
            <Grid item xs={12} style={{ paddingTop: 10 }}>
              <div className={classes.actionContainer}>
                <div className={classes.actionButtons}>
                  {tournamentSite.paymentEnabled && useReserveList ? (
                    <TourButton
                      loading={this.props.loading || false}
                      color={'primary'}
                      buttonProps={{
                        onClick: isRegistered ? this.props.handleEditRegistration : this.handleContinue,
                      }}
                      disabled={
                        this.props.disableSignUp ||
                        (!this.state.acceptTerms && !!this.props.termsAndConditions && !this.props.isRegistered) ||
                        !this._isValidHcp
                      }
                    >
                      {isRegistered ? (
                        <FormattedMessage id={'buttons.save'} />
                      ) : (
                        <FormattedMessage id={'buttons.continue'} />
                      )}
                    </TourButton>
                  ) : (
                    <TourButton
                      loading={this.props.loading || false}
                      color={'primary'}
                      buttonProps={{
                        onClick: isRegistered ? handleEditRegistration : handleJoinTournament,
                      }}
                      disabled={
                        (this.props.disableSignUp && !this.props.isRegistered) ||
                        (!this.state.acceptTerms && !!termsAndConditions && !isRegistered) ||
                        !this._isValidHcp
                      }
                    >
                      {isRegistered ? (
                        <FormattedMessage id={'buttons.save'} />
                      ) : (
                        <FormattedMessage id={'buttons.signUp'} />
                      )}
                    </TourButton>
                  )}
                </div>

                {this._maybeRenderPaymentContinue}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  get _isValidHcp() {
    const { dirtyHcp, hcp } = this.props.auth.user
    if (dirtyHcp !== undefined) {
      return validateHandicapValue(dirtyHcp).valid
    }
    return hcp !== null ? validateHandicapValue(formatHcpToDirtyHcp(hcp)).valid : false
  }

  get _user() {
    return this.props.auth.user
  }

  get _maybeRenderPaymentContinue() {
    const { tournamentSite, isRegistered } = this.props
    if (tournamentSite.paymentEnabled && isRegistered) {
      return this.renderPaymentContinue
    }
    return null
  }

  get renderPaymentContinue() {
    const { classes } = this.props
    return (
      <div className={classes.buttonsContainer}>
        <Button color="primary" variant="contained" onClick={this.handleContinue} className={classes.button}>
          <FormattedMessage id={'buttons.continue'} />
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (store) => ({
      auth: store.authenticationReducer,
      rounds: store.tournamentReducer.tournamentSite.tournament.rounds,
      registration: store.registrationsReducer.registration,
      termsAndConditions: store.tournamentReducer.tournamentSite.termsAndConditionsUrl,
      customQuestionsEnabled: store.tournamentReducer.tournamentSite.customQuestions.length > 0,
      teeboxSelectionEnabled: store.tournamentReducer.tournamentSite.teeboxSelectionEnabled,
      players: store.tournamentPlayersReducer.players,
      tournamentSite: store.tournamentReducer.tournamentSite,
      tournamentPlayers: store.tournamentPlayersReducer,
    }),
    {
      updateUserField,
    },
  )(IndividualTournamentEntryForm),
)
