import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormattedMessage } from 'react-intl'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import TourButton from './TourButton'
import { rem } from '../../theme/materialUITheme'
import Link from '@mui/material/Link'
import { getOfficeRoot } from '../../config'
import { login } from '../../store/authentication/actions'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    margin: `${18}px auto ${0}`,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      '& > h4': {
        textAlign: 'left',
      },
    },
  },
  input: {
    maxWidth: 380,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 0) scale(0.75)',
    },
  },
  forgotPassword: {
    color: theme.customPalette.dark,
    textDecorationColor: theme.customPalette.dark,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

interface StateProps {
  auth: AuthenticationState
  hcpRound?: boolean
}

interface DispatchProps {
  login(args: LoginPayload): void
}

type Props = StateProps & DispatchProps

function AccountLoginForm({ auth, login, hcpRound }: Props) {
  const classes = useStyles()
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)

  const doLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setInvalidCredentials(false)

    try {
      const { elements } = e.currentTarget
      const usernameField = elements.namedItem('email')
      const passwordField = elements.namedItem('password')
      const username = (usernameField as any).value
      const password = (passwordField as any).value

      if (username && password) {
        login({
          username,
          password,
          hcpRound,
          onComplete: (error) => {
            if (error) {
              setInvalidCredentials(true)
            }
          },
        })
      }
    } catch (e) {
      console.log(`Login ${auth.user.userId} requested - ${e}`)
    }
  }

  return (
    <div>
      <form name={'ggb-login'} className={classes.form} onSubmit={doLogin}>
        <Typography variant={'h4'}>
          <FormattedMessage id={'strings.loginWithGGB'} />
        </Typography>
        <FormControl className={classes.input} margin="normal" required={true} fullWidth={true}>
          <InputLabel htmlFor="email">
            <FormattedMessage id={'strings.accountName'} />
          </InputLabel>
          <Input id="email" name="email" autoComplete="email" autoFocus={true} />
        </FormControl>
        <FormControl className={classes.input} margin="dense" required={true} fullWidth={true}>
          <InputLabel htmlFor="password">
            <FormattedMessage id={'strings.password'} />
          </InputLabel>
          <Input name="password" type="password" id="password" autoComplete="current-password" />
        </FormControl>
        <div>
          <TourButton
            loading={auth.loading}
            buttonProps={{
              type: 'submit',
              style: {
                marginTop: rem(35),
                marginBottom: rem(22),
                minWidth: 175,
              },
            }}
          >
            <FormattedMessage id={'buttons.logIn'} />
          </TourButton>
        </div>
        {invalidCredentials && (
          <Typography variant={'body1'} color={'error'} style={{ marginBottom: 16 }}>
            <FormattedMessage id={'strings.invalidCredential'} />
          </Typography>
        )}
      </form>

      <Typography variant={'body1'}>
        <Link href={`${getOfficeRoot()}/forgot-password`} target={'_blank'} className={classes.forgotPassword}>
          <FormattedMessage id={'buttons.forgotPassword'} />
        </Link>
      </Typography>
    </div>
  )
}

export default connect<StateProps, DispatchProps, {}, StoreState>(
  (state) => ({
    auth: state.authenticationReducer,
    hcpRound: state.tournamentReducer.tournamentSite.tournament.hcpRound,
  }),
  {
    login,
  },
)(AccountLoginForm)
