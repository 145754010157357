import { takeEvery, put, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
} from './constants'
import { APIRoute } from '@app/config'
import { LoadTournamentContestLeaderboardAction } from './actions'

function* doTournamentContestLoadLeaderboard({ id, onSuccess }: LoadTournamentContestLeaderboardAction) {
  try {
    const res = yield axios.get(APIRoute.GET_CONTEST_LEADERBOARD(id))

    if (res && res.data.success === true && res.data.data) {
      yield put({
        type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
        data: res.data.data ? res.data.data : undefined,
      })
      if (onSuccess) {
        onSuccess()
      }
    } else {
      yield put({
        type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
        error: res.error,
      })
    }
  } catch (e) {
    console.log(`Tournament contest leaderboard ${id} requested - ${e}`)
    yield put({
      type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
      error: e,
    })
  }
}

export function* watchTournamentContestLeaderboard() {
  yield all([takeEvery(TOURNAMENT_LOAD_CONTEST_LEADERBOARD, doTournamentContestLoadLeaderboard)])
}
