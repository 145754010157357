import axios from 'axios'
import { APIRoute } from '../../../config'
import { JoinTournamentAction } from '../actions'

function* joinTournamentFlow({
  tournamentId,
  authToken,
  userId,
  clientUid,
  hcp,
  customQuestions,
  team,
  teamName,
  gender,
  teebox,
  divisionId,
  lang,
  onComplete,
}: JoinTournamentAction) {
  try {
    const res = yield axios.post(APIRoute.REGISTER(tournamentId), {
      authToken,
      userId,
      clientUid,
      hcp,
      customQuestions,
      team,
      teamName,
      gender,
      teebox,
      divisionId,
      lang,
    })

    onComplete(true, res.data.reserveList, res.data.error)
  } catch (error) {
    onComplete(false, false, error)
  }
}

export default joinTournamentFlow
