import { takeEvery, put, all } from 'redux-saga/effects'
import axios from 'axios'
import {
  TOURNAMENT_LOAD_STARTLISTS,
  TOURNAMENT_LOAD_STARTLISTS_SUCCESS,
  TOURNAMENT_LOAD_STARTLISTS_FAILURE,
} from './constants'
import { APIRoute } from '../../config'
import { LoadTournamentStartlistsAction } from './actions'

function* doTournamentLoadStartlists({ id }: LoadTournamentStartlistsAction) {
  try {
    const res = yield axios.get(APIRoute.GET_STARTLISTS(id))

    if (res && !res.error) {
      // Remove timeZone from startTime
      res.data.startLists.forEach((e) => e.forEach((e) => (e.startTime = e.startTime.substr(0, 19))))
      yield put({
        type: TOURNAMENT_LOAD_STARTLISTS_SUCCESS,
        startlists: res.data.startLists,
      })
    } else {
      yield put({
        type: TOURNAMENT_LOAD_STARTLISTS_FAILURE,
        error: res.error,
      })
    }
  } catch (e) {
    console.log(`Load start list ${id} requested - ${e}`)
    yield put({
      type: TOURNAMENT_LOAD_STARTLISTS_FAILURE,
      error: e,
    })
  }
}

export function* watchTournamentStartlists() {
  yield all([takeEvery(TOURNAMENT_LOAD_STARTLISTS, doTournamentLoadStartlists)])
}
