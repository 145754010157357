import SimpleBar from 'simplebar-react'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'
import 'simplebar-react/dist/simplebar.min.css'

interface Props {
  style?: Record<string, string | number>
  forceVisible?: boolean
  children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowX: 'hidden',
    width: 'inherit',
    marginRight: 5,
    '& .simplebar-scrollbar::before': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
      height: 50,
      width: 3,
    },
  },
}))

export const CustomScroll = ({ style, forceVisible = false, children }: Props) => {
  const classes = useStyles()
  return (
    <SimpleBar className={classes.container} forceVisible={forceVisible} style={style} autoHide={!forceVisible}>
      {children}
    </SimpleBar>
  )
}
