import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import TourButton from './TourButton'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { openRegisterModal, OpenRegisterModalAction } from '../../store/tournament/actions'
import { isRegistrationOpen, isCancelRegistrationOpen } from '../../store/tournament/selectors'

interface StateProps {
  registerModalOpen: boolean
  onlineEntriesEnabled: boolean
  style?: CSSProperties
  auth: AuthenticationState // todo: catch when the user is already registerd and hide btn
  isRegistrationOpen: boolean
  isCancelRegistrationOpen: boolean
}

interface DispatchProps {
  openRegisterModal(): OpenRegisterModalAction
}

type Props = StateProps & DispatchProps

const RegisterToTourButton = ({
  openRegisterModal,
  onlineEntriesEnabled,
  isRegistrationOpen,
  isCancelRegistrationOpen,
  style,
}: Props) => {
  // Don't show if entries are disabled
  // Don't show if entries are not open or entries are closed
  if (!onlineEntriesEnabled) return null
  if (!isRegistrationOpen && !isCancelRegistrationOpen) return null

  return (
    <TourButton buttonProps={{ onClick: openRegisterModal, disabled: false, style: style }} variant={'contained'}>
      <FormattedMessage id={'buttons.signUp'} />
    </TourButton>
  )
}

export default connect(
  (state: StoreState) => ({
    registerModalOpen: state.tournamentReducer.registerModalOpen,
    auth: state.authenticationReducer,
    onlineEntriesEnabled: get(state, 'tournamentReducer.tournamentSite.onlineEntriesEnabled', false),
    isRegistrationOpen: isRegistrationOpen(state),
    isCancelRegistrationOpen: isCancelRegistrationOpen(state),
  }),
  {
    openRegisterModal,
  },
)(RegisterToTourButton)
