import {
  COUNTRIES_FETCH,
  COUNTRIES_FETCH_SUCCESS,
  COUNTRY_STATES_FETCH,
  COUNTRY_STATES_FETCH_SUCCESS,
  COUNTRIES_FETCH_FAILURE,
  COUNTRY_STATES_FETCH_FAILURE,
  LOCAL_CLUBS_FETCH_SUCCESS,
  LOCAL_CLUBS_FETCH_FAILURE,
  UHS_CLUBS_FETCH_SUCCESS,
  UHS_CLUBS_FETCH_FAILURE,
} from './constants'
import {
  FetchCountriesAction,
  FetchCountriesFailureAction,
  FetchCountriesSuccessAction,
  FetchCountryStatesAction,
  FetchCountryStatesFailureAction,
  FetchCountryStatesSuccessAction,
  LocalFetchClubsAction,
  LocalFetchClubsSuccessAction,
  LocalFetchClubsFailureAction,
  UHSFetchClubsAction,
  UHSFetchClubsSuccessAction,
  UHSFetchClubsFailureAction,
} from './actions'

export const initialState: ClubsAndCountriesState = {
  countries: [],
  countryStates: [],
  clubs: [],
  clubsUHS: [],
  error: null,
  loading: false,
}

type ClubActions =
  | FetchCountriesAction
  | FetchCountriesSuccessAction
  | FetchCountryStatesAction
  | FetchCountryStatesSuccessAction
  | FetchCountriesFailureAction
  | FetchCountryStatesFailureAction
  | LocalFetchClubsAction
  | LocalFetchClubsSuccessAction
  | LocalFetchClubsFailureAction
  | UHSFetchClubsAction
  | UHSFetchClubsSuccessAction
  | UHSFetchClubsFailureAction

const clubsAndCountriesReducer = (
  state: ClubsAndCountriesState = initialState,
  action: ClubActions,
): ClubsAndCountriesState => {
  let partialState: Partial<ClubsAndCountriesState> = {}

  switch (action.type) {
    case COUNTRIES_FETCH:
      partialState = {
        countryStates: [],
      }
      return {
        ...state,
        ...partialState,
        loading: true,
      }
    case COUNTRY_STATES_FETCH:
      return {
        ...state,
        ...partialState,
        loading: true,
      }
    case LOCAL_CLUBS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clubs: action.clubs,
      }
    case UHS_CLUBS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clubsUHS: action.clubs,
      }
    case COUNTRIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.countries,
      }
    case COUNTRY_STATES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        countryStates: action.states,
      }
    case COUNTRY_STATES_FETCH_FAILURE:
    case COUNTRIES_FETCH_FAILURE:
    case LOCAL_CLUBS_FETCH_FAILURE:
    case UHS_CLUBS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    default:
      return state
  }
}

export { clubsAndCountriesReducer }
