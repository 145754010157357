import {
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
} from './constants'
import {
  LoadTournamentContestLeaderboardAction,
  LoadTournamentContestLeaderboardSuccessAction,
  LoadTournamentContestLeaderboardFailureAction,
} from './actions'

export const initialState: TournamentContestLeaderboardState = {
  data: undefined,
  error: undefined,
  loading: false,
}

type TournamentContestLeaderboardActions =
  | LoadTournamentContestLeaderboardAction
  | LoadTournamentContestLeaderboardSuccessAction
  | LoadTournamentContestLeaderboardFailureAction

const tournamentContestLeaderboardReducer = (
  state: TournamentContestLeaderboardState = initialState,
  action: TournamentContestLeaderboardActions,
) => {
  switch (action.type) {
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS:
      const { loading = false } = action
      return {
        ...state,
        loading,
        data: action.data,
      }
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    default:
      return state
  }
}

export { tournamentContestLeaderboardReducer }
