import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { rem } from '../../theme/materialUITheme'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      fontSize: rem(15),
      verticalAlign: 'middle',
      textAlign: 'left',
      padding: `9px 7px`,
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: '10px 7px 8px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: rem(10),
      },
    },
    tableHeader: {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: theme.typography.pxToRem(15),
      padding: `10px 7px 8px`,
      [theme.breakpoints.down('sm')]: {
        fontSize: rem(11),
      },
    },
    tableHeaderContests: {
      backgroundColor: theme.customPalette.tableLightBg,
    },
    tableData: {
      fontWeight: 200,
    },
    screen: {
      fontSize: rem(20),
      fontWeight: 600,
    },
  })

interface Props extends WithStyles<typeof styles> {
  style?: CSSProperties
  className?: string
  element?: 'th' | 'td'
  rowSpan?: number
  colSpan?: number
  screen?: boolean
  children?: React.ReactNode
}

class TournamentTableCell extends React.Component<Props> {
  render() {
    const { children, classes, element, style, rowSpan = 1, colSpan = 1, className, screen } = this.props

    const Component = element || 'td'

    const allClassNames = classNames([
      className,
      classes.tableCell,
      screen ? classes.screen : '',
      {
        [`${classes.tableData}`]: Component === 'td',
        [`${classes.tableHeader}`]: Component === 'th',
      },
    ])

    return (
      <Component colSpan={colSpan} rowSpan={rowSpan} style={style} className={allClassNames}>
        {children}
      </Component>
    )
  }
}

export default withStyles(styles)(TournamentTableCell)
