import {
  TOURNAMENT_LOAD_STARTLISTS,
  TOURNAMENT_LOAD_STARTLISTS_SUCCESS,
  TOURNAMENT_LOAD_STARTLISTS_FAILURE,
} from './constants'
import {
  LoadTournamentStartlistsAction,
  LoadTournamentStartlistsFailureAction,
  LoadTournamentStartlistsSuccessAction,
} from './actions'

export const initialState: TournamentStartlistsState = {
  rounds: [],
  error: undefined,
  loading: false,
}

type TournamentStartlistsActions =
  | LoadTournamentStartlistsAction
  | LoadTournamentStartlistsSuccessAction
  | LoadTournamentStartlistsFailureAction

const tournamentStartlistsReducer = (
  state: TournamentStartlistsState = initialState,
  action: TournamentStartlistsActions,
) => {
  switch (action.type) {
    case TOURNAMENT_LOAD_STARTLISTS:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENT_LOAD_STARTLISTS_SUCCESS:
      const { loading = false } = action
      return {
        ...state,
        loading,
        rounds: action.startlists,
      }
    case TOURNAMENT_LOAD_STARTLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    default:
      return state
  }
}

export { tournamentStartlistsReducer }
