import React from 'react'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { GGBLogoWideNavDark, GGBLogoWideNavDark2x } from '../../assets/images'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.customPalette.siteFooterBackground,
      paddingTop: 50,
      paddingBottom: 48,
      minHeight: 170,
      boxSizing: 'border-box',
      alignItems: 'center',
      display: 'flex',
    },
    wrapper: {
      color: theme.customPalette.siteFooterText,
      maxWidth: 620,
      margin: '0 auto',
      padding: '0 24px',
      textAlign: 'center',
    },
    linkList: {
      marginTop: 10,
      padding: 0,
      listStyle: 'none',
      '@global': {
        li: {
          display: 'inline-block',
          padding: 10,
          [theme.breakpoints.down('sm')]: {
            padding: 5,
          },
        },
        'li a': {
          color: 'inherit',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  classes: {
    root: string
    wrapper: string
    linkList: string
  }
}

export default withStyles(styles)(({ classes }: Props) => (
  <footer className={classes.root}>
    <div className={classes.wrapper}>
      <img width={'180px'} alt={'GGB'} src={GGBLogoWideNavDark} srcSet={`${GGBLogoWideNavDark2x} 2x`} />
      <ul className={classes.linkList}>
        <li>
          <a href="https://golfgamebook.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">
            Support
          </a>
        </li>
        <li>
          <a href={`https://golfgamebook.com/terms-of-use/`} target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
        </li>
        <li>
          <a href={`https://golfgamebook.com/cookies-policy/`} target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
        </li>
        <li>
          <a href={`https://www.golfgamebook.com/Office-DPA`} target="_blank" rel="noopener noreferrer">
            DPA
          </a>
        </li>
      </ul>
    </div>
  </footer>
))
