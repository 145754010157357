import { useAppState } from '@app/hooks'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    maxHeight: 200,
    maxWidth: '100%',
    margin: '30px auto 0',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      maxWidth: 160,
      height: 80,
      margin: '20px auto 0',
    },
  },
}))

export const TournamentLogo = () => {
  const classes = useStyles()
  const { tournamentSiteState } = useAppState()
  const logoUrl = tournamentSiteState?.images?.logoImage?.url

  if (!logoUrl) {
    return null
  }

  return <img src={logoUrl} alt={logoUrl} className={classes.img} />
}
