import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'

import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'

interface Props {
  style?: CSSProperties
}

export const LineDivider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: -24,
      marginRight: -24,
      position: 'relative',
      textAlign: 'center',
      padding: '8px 0 24px',
      '&:before': {
        position: 'absolute',
        content: '""',
        display: 'block',
        height: '1px',
        left: 0,
        right: 0,
        top: 'calc(50% - 1px)',
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
)(({ style, classes }: Props & WithStyles<any>) => <div className={classes.root} style={style}></div>)
