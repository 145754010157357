import { DivisionType } from './enums'

export const formatCustomQuestionAnswersForAPI = (answers?: QuestionAnswers): CustomQuestionResponse[] | undefined => {
  if (!answers) {
    return
  }

  return Object.keys(answers).map((id) => ({
    id: parseInt(id, 10),
    answer: answers[id].answer,
  }))
}

export const areRequiredAnswersValid = (
  questions: Question[],
  answers?: QuestionAnswers,
): { valid: boolean; errors: { [property: string]: boolean } } => {
  let valid = true
  const errors = {}

  for (const question of questions) {
    if (question.mustAnswer) {
      const userAnswer = answers[question.id]
      if (!userAnswer || !userAnswer.answer || userAnswer.answer.match(/(\S)+/) === null) {
        valid = false
        errors[`${question.id}`] = true
      } else {
        errors[`${question.id}`] = false
      }
    }
  }

  return {
    valid,
    errors,
  }
}

export const isMandatoryDivisionSelected = (tournamentSite: TournamentSite, selectedDivisionId?: number) => {
  const { manualDivisionSelectionEnabled, manualDivisionSelectionRequired, divisions } = tournamentSite
  const hasManualDivisions = divisions.some((division) => division.type === DivisionType.MANUAL)
  if (manualDivisionSelectionEnabled && manualDivisionSelectionRequired && hasManualDivisions) {
    return Boolean(selectedDivisionId)
  }
  return true
}

export const hasMustAnswerQuestions = (questions: RegistrationQuestion[] | Question[]) => {
  let mustAnswers = false

  for (const question of questions) {
    if (question.mustAnswer) {
      mustAnswers = true
      break
    }
  }

  return mustAnswers
}
