// Contest types below should be in the order in which they are displayed in leaderboard
export enum ContestResultType {
  CLOSEST_TO_PIN = 'closestToPin',
  LONGEST_DRIVE = 'longestDrive',
  STRAIGHTEST_DRIVE = 'straightestDrive',
}

export enum LeaderboardTabs {
  LEADERBOARD_TYPE_PLAYERS = 'players',
  LEADERBOARD_TYPE_RESULTS = 'results',
  LEADERBOARD_TYPE_LEADERBOARD = 'leaderboard',
  LEADERBOARD_TYPE_START_TIMES = 'startTimes',
  LEADERBOARD_TYPE_CONTESTS = 'contests',
}
