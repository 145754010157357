import {
  FETCH_TOURNAMENT_PRODUCTS,
  START_PAYMENT_PROCESS,
  VERIFY_NETS_PAYMENT,
  FETCH_BOUGHT_PRODUCTS,
  VERIFY_NETS_PAYMENT_FAILURE,
  FETCH_CURRENCIES,
} from './constants'

/**
 * FETCH PRODUCTS
 */

export interface FetchTournamentProductsSuccessAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS_SUCCESS'
  tournamentProducts: TournamentProducts[]
  productsFetched?: boolean
}

export interface FetchTournamentProductsFailureAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS_FAILURE'
  error: any
}

export interface FetchTournamentProductsAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS'
  payload: PaymentPayload
  onComplete(success: boolean): void
}

export const fetchTournamentProducts = (payload: PaymentPayload, onComplete): FetchTournamentProductsAction => ({
  type: FETCH_TOURNAMENT_PRODUCTS,
  payload,
  onComplete,
})

/**
 * START PAYMENT PROCESS
 */

export interface StartPaymentProcessAction extends Action {
  type: 'START_PAYMENT_PROCESS'
  payload: StartPaymentProcessPayload
  onSuccess: (terminalURL: string) => void
}

export interface StartPaymentProcessSuccessAction extends Action {
  type: 'START_PAYMENT_PROCESS_SUCCESS'
  terminalURL: string
}

export interface StartPaymentProcessFailureAction extends Action {
  type: 'START_PAYMENT_PROCESS_FAILURE'
  error: any
}

export const startPaymentProcess = (
  payload: StartPaymentProcessPayload,
  onSuccess: (terminalURL: string) => void,
): StartPaymentProcessAction => ({
  type: START_PAYMENT_PROCESS,
  payload,
  onSuccess,
})

/**
 * VERIFY NETS PAYMENT
 */

export interface VerifyNetsPaymentAction extends Action {
  type: 'VERIFY_NETS_PAYMENT'
  payload: VerifyNetsPaymentPayload
}

export interface VerifyNetsPaymentSuccessAction extends Action {
  type: 'VERIFY_NETS_PAYMENT_SUCCESS'
}

export interface VerifyNetsPaymentFailureAction extends Action {
  type: 'VERIFY_NETS_PAYMENT_FAILURE'
  error: any
}

export const setNetsPaymentAsFailed = (): VerifyNetsPaymentFailureAction => ({
  type: VERIFY_NETS_PAYMENT_FAILURE,
  error: 'Payment failed',
})

export const verifyNetsPayment = (payload: VerifyNetsPaymentPayload): VerifyNetsPaymentAction => ({
  type: VERIFY_NETS_PAYMENT,
  payload,
})

/**
 * BOUGHT PRODUCTS
 */

export interface FetchBoughtAction extends Action {
  type: 'FETCH_BOUGHT_PRODUCTS'
  payload: PaymentPayload
}

export interface FetchBoughtSuccessAction extends Action {
  type: 'FETCH_BOUGHT_PRODUCTS_SUCCESS'
  purchasedTournamentProductIds: BoughtProducts[]
}

export interface FetchBoughtFailureAction extends Action {
  type: 'FETCH_BOUGHT_PRODUCTS_FAILURE'
  error: any
}

export const fetchBoughtProducts = (payload: PaymentPayload): FetchBoughtAction => ({
  type: FETCH_BOUGHT_PRODUCTS,
  payload,
})

export interface FetchCurrenciesAction extends Action {
  type: 'FETCH_CURRENCIES'
}

export interface FetchCurrenciesSuccessAction extends Action {
  type: 'FETCH_CURRENCIES_SUCCESS'
  currencies: PaymentCurrency[]
}

export interface FetchCurrenciesFailureAction extends Action {
  type: 'FETCH_CURRENCIES_FAILURE'
  error: any
}

export const fetchCurrencies = (): FetchCurrenciesAction => ({
  type: FETCH_CURRENCIES,
})
