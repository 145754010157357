export enum AnswerTypes {
  YES_NO = 'yesNo',
  FREE_TEXT = 'freeText',
}

export enum ColorTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum GameFormats {
  UNDEFINED = 0,
  NO_SIDE = 1,

  STROKE_PLAY_INDIVIDUAL = 2,
  STROKE_PLAY_BETTER_BALL = 4,
  STROKE_PLAY_BEST_BALL = 5,
  STROKE_PLAY_SCRAMBLE = 8,
  STROKE_PLAY_SCRAMBLE_2_MAN = 11,
  STROKE_PLAY_ERADO = 6,
  STROKE_PLAY_SKINS = 13,
  STROKE_PLAY_LOW_SCRATCH_NET = 14,
  STROKE_PLAY_GREENSOME = 17,
  STROKE_PLAY_FOURSOME = 19,
  STROKE_PLAY_COLLEGE = 22,

  STABLEFORD_INDIVIDUAL = 3,
  STABLEFORD_INDIVIDUAL_DUPLICATE = 9,
  STABLEFORD_BETTER_BALL = 15,
  STABLEFORD_BEST_BALL = 7,
  STABLEFORD_SCRAMBLE_DUPLICATE = 12,
  STABLEFORD_GREENSOME = 16,
  STABLEFORD_FOURSOME = 18,

  MATCH_PLAY_INDIVIDUAL = 23,
  MATCH_PLAY_BETTER_BALL = 24,
  MATCH_PLAY_SCRAMBLE = 25,
  MATCH_PLAY_GREENSOME = 27,
  MATCH_PLAY_FOURSOME = 28,

  IRISH_RUMBLE_BEST_BALL = 20,
  TEAM_PAR_IS_YOUR_FRIEND = 21,
}

export enum TournamentTypes {
  singleRound = 'singleRound',
  multiRound = 'multiRound',
  redsVsBlues = 'redsVsBlues',
  weekly = 'weekly',
  matchplayBracket = 'matchplayBracket',
}

export enum PlayerStatus {
  OK = 'ok',
  DQ = 'dq',
  DNS = 'dns',
  RTD = 'rtd',
  CUT = 'cut',
}

export enum DivisionType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export enum EntryMethod {
  TEAM = 'team',
  PLAYER = 'player',
  INVIDUAL = 'individual',
}

export enum PaymentTypes {
  NOW = 'NOW',
  LATER = 'LATER',
}

export enum Measurements {
  METERS = 'm',
  YARDS = 'yards',
  FEET = 'ft',
  INCHES = 'in',
}

export enum TabsEnum {
  INFO = 'info',
  GAME = 'game',
  PLAYERS = 'players',
  STARLIST = 'startlist',
  LEADERBOARD = 'leaderboard',
  CONTESTS = 'contests',
  RESULTS = 'results',
  SUMMARY = 'summary',
  TOUR_INFO = 'tour-info',
  TOUR_EVENTS = 'tour-events',
  TOUR_RANKINGS = 'tour-rankings',
}
