import { get } from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'

export const getTournamentId = (state: StoreState) => state.tournamentReducer.tournamentSite.tournament.id

// lets see how memoization behaves with date now
const isCurrentDateBetweenSelections = (
  registerAndCancelTimeFramesEnabled: boolean,
  startsStr?: string,
  endsStr?: string,
) => {
  //TODO: Start and end times should be shown as is.
  //Disable time offset when comparing and displaying based on local settings
  // time frames disabled so we can just continue as usual
  if (!registerAndCancelTimeFramesEnabled) {
    return true
  }

  if (startsStr && endsStr) {
    const starts = moment(startsStr)
    const ends = moment(endsStr)
    return moment().isBetween(starts, ends, undefined, '[]')
  } else {
    return true
  }
}

export const areRegisterAndCancelTimeFramesEnabled = (state: StoreState) =>
  get(state, 'tournamentReducer.tournamentSite.registerAndCancelTimeFramesEnabled')

export const getRegistrationCancellingStartsTime = (state: StoreState) =>
  get(state, 'tournamentReducer.tournamentSite.registrationCancellingStartsTime')
export const getRegistrationCancellingEndsTime = (state: StoreState) =>
  get(state, 'tournamentReducer.tournamentSite.registrationCancellingEndsTime')

export const getRegistrationStartsTime = (state: StoreState) =>
  get(state, 'tournamentReducer.tournamentSite.registrationStartsTime')
export const getRegistrationEndsTime = (state: StoreState) =>
  get(state, 'tournamentReducer.tournamentSite.registrationEndsTime')

export const isRegistrationOpen = createSelector(
  areRegisterAndCancelTimeFramesEnabled,
  getRegistrationStartsTime,
  getRegistrationEndsTime,
  isCurrentDateBetweenSelections,
)

export const isCancelRegistrationOpen = createSelector(
  areRegisterAndCancelTimeFramesEnabled,
  getRegistrationCancellingStartsTime,
  getRegistrationCancellingEndsTime,
  isCurrentDateBetweenSelections,
)
