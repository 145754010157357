import { CircularProgress } from '@mui/material'

interface Props {
  size?: number
  className?: string
}

export default function LoadingSpinner({ size, className }: Props): JSX.Element {
  return <CircularProgress size={size || 24} className={className} />
}
