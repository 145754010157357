import axios from 'axios'
import { APIRoute } from '../../../config'
import { GetRankingAction } from '../actions'
import { put } from 'redux-saga/effects'
import { GET_RANKING_SUCCESS, GET_RANKING_FAILURE } from '../constants'

function* getRankingFlow({ rankingId }: GetRankingAction) {
  try {
    const res = yield axios.get(APIRoute.GET_RANKING(rankingId))

    yield put({
      type: GET_RANKING_SUCCESS,
      ranking: res.data,
    })
  } catch (e) {
    yield put({
      type: GET_RANKING_FAILURE,
      error: e,
    })
  }
}

export default getRankingFlow
