import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { FormattedMessage } from 'react-intl'

interface Props {
  open: boolean
  title: React.ReactNode
  text: React.ReactNode
  okText?: React.ReactNode
  cancelText?: React.ReactNode
  cancelAction?: () => void
  okAction?: () => void
  showOk?: boolean
  showCancel?: boolean
}

export default function Alert({
  open,
  title,
  text,
  okText,
  cancelText,
  okAction,
  cancelAction,
  showOk = true,
  showCancel = true,
}: Props) {
  const handleOk = () => {
    if (okAction) okAction()
  }

  const handleCancel = () => {
    if (cancelAction) cancelAction()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <Button onClick={handleCancel} color="primary">
              {cancelText ? cancelText : <FormattedMessage id="buttons.cancel" />}
            </Button>
          )}
          {showOk && (
            <Button onClick={handleOk} color="primary" autoFocus>
              {okText ? okText : <FormattedMessage id="buttons.ok" />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
