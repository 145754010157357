import React from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Theme } from '@mui/material'
import Markdown from '../layout/Markdown'
import { TabsEnum } from '@app/utils/enums'

const useStyles = makeStyles((theme: Theme) => ({
  leaderboardInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `20px 0`,
    padding: `30px 20px`,
    borderRadius: 14,
    border: '2px solid #004729',
    borderLeft: '10px solid #004729',
    borderRight: '10px solid #004729',
  },
  leaderboardInfo: {
    width: '60%',
    textAlign: 'center',
    fontFamily: 'Exo',
    fontSize: 16,
    fontWeight: 600,
    '& > p': {
      marginBottom: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 12,
    },
  },
}))

export const LeaderboardInfo: React.FC = () => {
  const classes = useStyles()
  const { tournamentSite } = useSelector((store: StoreState) => store.tournamentReducer)
  const currentPage: TabsEnum | null = useSelector(
    (store: StoreState) => store.pageReducer.currentPage || null,
  ) as TabsEnum
  const allowedPages = [TabsEnum.LEADERBOARD, TabsEnum.CONTESTS, TabsEnum.RESULTS]

  if (!tournamentSite.leaderboardInfo || !allowedPages.includes(currentPage)) {
    return
  }

  return (
    <div className={classes.leaderboardInfoWrapper} data-testid="leaderboard-info">
      <div className={classes.leaderboardInfo}>
        <Markdown>{tournamentSite.leaderboardInfo}</Markdown>
      </div>
    </div>
  )
}
