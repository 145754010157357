import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '../../theme/materialUITheme'

const styles = () =>
  createStyles({
    root: {
      margin: '0 auto',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  style?: CSSProperties
}

const ContentWrap = (props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
}

export default withStyles(styles)(ContentWrap)

const narrowStyles = () =>
  createStyles({
    root: {
      maxWidth: rem(1292),
      margin: '0 auto',
      boxSizing: 'border-box',
    },
  })

export const NarrowContentWrap = withStyles(narrowStyles)((props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
})

const innerWrapStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: `0 60px 30px`,

      [theme.breakpoints.down('md')]: {
        margin: `0 10px 10px`,
      },
      [theme.breakpoints.down('sm')]: {
        margin: `0 10px 10px`,
      },
    },
  })

export const InnerContentWrap = withStyles(innerWrapStyles)((props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
})

const contentPadStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(18)}`,
      [theme.breakpoints.up('md')]: {
        padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(62)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${theme.typography.pxToRem(17)} ${theme.typography.pxToRem(104)}`,
      },
    },
  })

export const ContentPad = withStyles(contentPadStyles)((props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
})
