import React, { useState } from 'react'
import { IconButton, Tooltip, Grid, Input, Theme, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { RemoveCircle, Edit } from '@mui/icons-material'
import Alert from '../dialogs/Alert'
import { FormattedMessage, useIntl } from 'react-intl'
import { editEntryTeamMember, updateUserField } from '@app/store/authentication/actions'
import { formatHandicap, trimHcpDecimals, validateHandicapValue } from '@app/utils/playerUtils'
import { useDispatch } from 'react-redux'
import EditPlayerDialog from '../dialogs/EditPlayerDialog'
import { getDefaultProfileImage } from '@app/config'

const useStyles = makeStyles((theme: Theme) => ({
  playerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.customPalette.mediumGray}`,
    borderRadius: 6,
    height: 90,
    padding: 15,
    marginBottom: 15,
    lineHeight: 1.25,

    '& h2': {
      fontSize: 14,
      fontWeight: 700,
      marginTop: 0,
      marginBottom: 0,
      color: theme.customPalette.siteNavigationText,
    },
    '& span': {
      display: 'block',
      fontSize: 14,
      fontWeight: 500,
      color: '#747474',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  playerCardActions: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 90,
    marginTop: -15,

    '& > button': {
      height: 32,
      width: 32,
    },
  },
  playerCardHcp: {
    alignSelf: 'center',
  },
  hcpLabel: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  hcpInput: {
    width: 38,

    '&.Mui-disabled:before': {
      display: 'none',
    },
  },
  editIcon: {
    fill: theme.palette.primary.main,
  },
  foundPlayer: {
    borderColor: theme.palette.primary.main,
  },
  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > img': {
      width: 48,
      height: 48,
      borderRadius: '50%',
    },
  },
  playerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 60px)',
    paddingRight: 10,
    overflowX: 'auto',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

interface Props {
  index: number
  teamMember: TeamMember
  loggedInUserIsTeamCreator?: boolean
  readonly?: boolean
  fullWidth?: boolean
  handleRemove?: () => void
  handleDelete?: () => void
}

export default function TeamMemberCard({
  index,
  teamMember,
  loggedInUserIsTeamCreator = false,
  readonly = false,
  fullWidth = false,
  handleRemove,
  handleDelete,
}: Props) {
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)
  const [originalPlayer, setOriginalPlayer] = useState<TeamMember | undefined>(undefined)
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const DEFAULT_HCP = '54'

  const isTempPlayer: boolean =
    (teamMember?.id === undefined && teamMember?.providerPlayerIdentifier === undefined) ||
    teamMember?.temporary === true

  const editStoreEntry = (fieldName: string, value: number | string | undefined) => {
    dispatch(editEntryTeamMember({ index, fieldName, value }))
  }

  const handleCancel = () => {
    setEditDialogOpen(false)
    editStoreEntry('firstName', originalPlayer?.firstName)
    editStoreEntry('lastName', originalPlayer?.lastName)
    editStoreEntry('hcp', originalPlayer?.hcp)
    editStoreEntry('dirtyHcp', originalPlayer?.dirtyHcp)
    editStoreEntry('gender', originalPlayer?.gender)
    editStoreEntry('email', originalPlayer?.email)
  }

  const editEntry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name
    let value = event.target.value
    const isNonEmptyHcpValue = fieldName === 'dirtyHcp' && value && value !== '+'

    const isValid = validateHandicapValue(value.toString()).valid
    if (isNonEmptyHcpValue && !isValid) {
      return
    }

    if (fieldName === 'dirtyHcp') {
      value = trimHcpDecimals(value.toString())

      const playerIsCreator = index === 0
      if (playerIsCreator) {
        dispatch(updateUserField({ fieldName: 'dirtyHcp', value }))
      }
    }

    editStoreEntry(fieldName, value)
  }

  const getHcpValue = () => {
    switch (true) {
      case teamMember.dirtyHcp === undefined && teamMember.hcp === undefined:
        return DEFAULT_HCP
      case teamMember.dirtyHcp !== undefined:
        return teamMember.dirtyHcp
      case teamMember.hcp !== undefined:
        return formatHandicap(teamMember.hcp.toString())
      default:
        return DEFAULT_HCP
    }
  }

  const openEditDialog = () => {
    setEditDialogOpen(true)
    setOriginalPlayer(teamMember)
  }

  const getClasses = () => {
    if (fullWidth) {
      return `${classes.playerCard} ${classes.foundPlayer}`
    }
    return classes.playerCard
  }

  const renderExistingPlayerActions = () => {
    return (
      <>
        <IconButton onClick={openEditDialog}>
          <Edit className={classes.editIcon} />
        </IconButton>
        <Tooltip title={<FormattedMessage id="strings.removePlayer" />}>
          <IconButton onClick={() => setDeleteConfirmation(true)}>
            <RemoveCircle />
          </IconButton>
        </Tooltip>
        <Alert
          open={deleteConfirmation}
          title={<FormattedMessage id="strings.deletePlayerConfirmationTitle" />}
          text={<FormattedMessage id="strings.deletePlayerConfirmationText" />}
          okText={<FormattedMessage id="buttons.yes" />}
          cancelText={<FormattedMessage id="buttons.no" />}
          okAction={() => {
            setDeleteConfirmation(false)
            handleDelete && handleDelete()
          }}
          cancelAction={() => setDeleteConfirmation(false)}
        />
      </>
    )
  }

  const maybeRenderActionIcons = () => {
    const playerIsCreator = index === 0
    if (playerIsCreator || !loggedInUserIsTeamCreator || (!handleRemove && !handleDelete)) {
      return <></>
    }
    if (handleDelete && !isTempPlayer) {
      return renderExistingPlayerActions()
    }
    return (
      <>
        <IconButton onClick={openEditDialog}>
          <Edit className={classes.editIcon} />
        </IconButton>
        <IconButton onClick={handleRemove}>
          <RemoveCircle />
        </IconButton>
      </>
    )
  }

  return (
    <>
      {editDialogOpen && (
        <EditPlayerDialog index={index} handleCancel={handleCancel} handleSave={() => setEditDialogOpen(false)} />
      )}
      <Grid item xs={fullWidth ? 12 : 11.5} sm={fullWidth ? 12 : 5.5} className={getClasses()}>
        <Grid container>
          <Grid item xs={10} className={classes.playerInfoContainer}>
            <div className={classes.imageContainer}>
              <img src={teamMember.profileImageUrl || getDefaultProfileImage()} />
            </div>
            <div className={classes.playerInfo}>
              <h2>
                {teamMember.firstName} {teamMember.lastName}
              </h2>
              <span className={classes.noWrap}>{teamMember.clubName}</span>
            </div>
          </Grid>
          <Grid item xs={2} className={classes.playerCardHcp}>
            <InputLabel className={classes.hcpLabel}>{intl.formatMessage({ id: 'strings.hcp' })}</InputLabel>
            <Input
              className={classes.hcpInput}
              name={'dirtyHcp'}
              value={getHcpValue()}
              onChange={editEntry}
              disabled={readonly || !loggedInUserIsTeamCreator}
            />
          </Grid>
        </Grid>
      </Grid>
      {!fullWidth && (
        <Grid item xs={0.5} className={classes.playerCardActions}>
          {maybeRenderActionIcons()}
        </Grid>
      )}
    </>
  )
}
